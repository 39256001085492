import { AuthApi } from '@property-folders/common/client-api/auth';
import { useContext, useEffect, useState } from 'react';
import { Alert, Col, Container, FormCheck, Row, Spinner } from 'react-bootstrap';
import { EntitySettingsContext } from '~/pages/settings/EntitySettingsContext';
import { AjaxPhp, GaGetFeatureFlagsResponseData } from '@property-folders/common/util/ajaxPhp';
import { GreatformsSigningDefaultsEditor } from '~/pages/settings/tabs/GreatformsSigningDefaultsEditor';

export function GlobalAdminOnly() {
  const isGa = Boolean(AuthApi.useGetAgentSessionInfo()?.data?.isGlobalAdmin);
  return isGa
    ? <GlobalAdminOnlyInner />
    : <></>;
}

function GlobalAdminOnlyInner() {
  const { entityPhpInfo, entityPhpInfoStatus, entityUuid } = useContext(EntitySettingsContext);
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState<undefined | GaGetFeatureFlagsResponseData>(undefined);

  useEffect(() => {
    if (!entityPhpInfo) return;
    if (entityPhpInfo.entityId < 1) return;

    const ac = new AbortController();
    AjaxPhp.gaGetFeatureFlags({
      entityId: entityPhpInfo.entityId,
      signal: ac.signal
    })
      .then(data => {
        if (ac.signal.aborted) return;
        if (!data) return;
        if (!data.success) return;
        setData(data.data);
      })
      .catch(err => {
        if (ac.signal.aborted) return;
        console.log(err);
      })
      .finally(() => {
        if (ac.signal.aborted) return;
        setLoading(false);
      });

    return () => {
      ac.abort();
    };
  }, [entityPhpInfo?.entityId]);

  return <Container>
    <Row>
      <Col>
        <h1>Feature Flags</h1>
        {loading || entityPhpInfoStatus === 'loading'
          ? <div className='d-flex flex-row justify-content-center align-items-center'>
            <Spinner animation='border' />
          </div>
          : !loading && data && entityPhpInfo?.entityId
            ? <div>
              <Alert variant='warning' className='me-3'>
                <p>Note: it can take a small delay (~10s) for the changes here to be reflected in the entity yDoc.</p>
                <p className='mb-0'>e.g. If you enable/disable Property Folders, it will take some time before the
                  navigation bar updates.</p>
              </Alert>
              <FlagRow
                entityId={entityPhpInfo.entityId}
                name={'UseNewPropertyTransactions'}
                label={'Property Folders Enabled'}
                defaultValue={data.UseNewPropertyTransactions}
              />
              <FlagRow
                entityId={entityPhpInfo.entityId}
                name={'SigningPortalEnabled'}
                label={'Signing Portal Enabled'}
                defaultValue={data.SigningPortalEnabled}
              />
              <FlagRow
                entityId={entityPhpInfo.entityId}
                name={'UseContractors'}
                label={'Use Contractors'}
                defaultValue={data.UseContractors}
              />
              <FlagRow
                entityId={entityPhpInfo.entityId}
                name={'IntegrationEnabled'}
                label={'Show CRM UI features'}
                defaultValue={data.IntegrationEnabled}
              />
              <FlagRow
                entityId={entityPhpInfo.entityId}
                name={'TeamManagementVisible'}
                label={'Team Management Visible (requires log out/in for agent session to change)'}
                defaultValue={data.TeamManagementVisible}
              />
              <FlagRow
                entityId={entityPhpInfo.entityId}
                name={'EveryonePropertyAccess'}
                label={'Everyone Can See the Agency\'s Properties (requires log out/in for agent session to change)'}
                defaultValue={data.EveryonePropertyAccess}
              />
              <FlagRow
                entityId={entityPhpInfo.entityId}
                name={'UseMyFiles'}
                label={'SignAnything Enabled'}
                defaultValue={data.UseMyFiles}
              />
              <FlagRow
                entityId={entityPhpInfo.entityId}
                name={'UseCustomNotifications'}
                label={'Custom Notification Templates Enabled (requires log out/in for agent session to change)'}
                defaultValue={data.UseCustomNotifications}
              />
              <FlagRow
                entityId={entityPhpInfo.entityId}
                name={'ShortSAA'}
                label={'Allow agents to use the Short SAA (Switching off will revert all draft SAAs to standard)'}
                defaultValue={data.ShortSAA}
              />
              <FlagRadio
                entityId={entityPhpInfo.entityId}
                name={'CampaignAgentVpa'}
                label={'Vendor Paid Advertising Provider'}
                defaultValue={data.CampaignAgentVpa}
                options={{
                  'false': 'None',
                  'true': 'Campaign Agent'
                }}
              />
              <FlagRow
                entityId={entityPhpInfo.entityId}
                name='PropertyFolderTemplatesList'
                label='Template List Page (March 2025)'
                defaultValue={data.PropertyFolderTemplatesList}
              />
              <FlagRow
                entityId={entityPhpInfo.entityId}
                name='PropertyFolderTemplates'
                label='Greatforms Templates'
                defaultValue={data.PropertyFolderTemplates}
              />
            </div>
            : <div>Failed to load</div>}
      </Col>
    </Row>

    <Row>
      <Col>
        <h1 className='mt-1'>Property Folder Signing Defaults</h1>
        {entityUuid && <GreatformsSigningDefaultsEditor entityUuid={entityUuid} />}
      </Col>
    </Row>
  </Container>;
}

function FlagRow({
  entityId,
  name,
  label,
  defaultValue
}: {
  entityId: number,
  name: string,
  label: string,
  defaultValue: boolean
}) {
  const { entityPhpInfo } = useContext(EntitySettingsContext);
  const [loading, setLoading] = useState(false);
  const [value, setValue] = useState(defaultValue);
  return <div className='d-flex flex-row align-items-center gap-3 ps-1'>
    <FormCheck
      id={name}
      name={name}
      label={label}
      checked={value}
      disabled={loading}
      onChange={() => {
        setLoading(true);
        AjaxPhp.gaSetFeatureFlag({ entityId, name, value: !value })
          .then(data => {
            if (!data?.success) return;
            setValue(data.value);
          })
          .finally(() => {
            setLoading(false);
            entityPhpInfo?.reload();
          });
      }}
    />
    {loading && <Spinner size='sm' animation='border' />}
  </div>;
}

function FlagRadio({ // A bodge because we're using a boolean data type under the hood, but we might have something else later
  entityId,
  name,
  label,
  defaultValue,
  options
}: {
  entityId: number,
  name: string,
  label: string,
  defaultValue: boolean,
  options: {'false': string, 'true': string}
}) {
  const { entityPhpInfo } = useContext(EntitySettingsContext);
  const [loading, setLoading] = useState(false);
  const [value, setValue] = useState(defaultValue);
  return <div className='align-items-center gap-3 ps-1'>
    <div className='lead'>{label}</div>
    {Object.entries(options).map(([k,label]) => {
      return <FormCheck
        type='radio'
        id={name+k}
        name={name}
        label={label}
        checked={`${value}`===k}
        disabled={loading}
        inline={true}
        onChange={() => {
          setLoading(true);
          AjaxPhp.gaSetFeatureFlag({ entityId, name, value: k === 'true' })
            .then(data => {
              if (!data?.success) return;
              setValue(data.value);
            })
            .finally(() => {
              setLoading(false);
              entityPhpInfo?.reload();
            });
        }}
      />;
    })}

    {loading && <Spinner size='sm' animation='border' />}
  </div>;
}
