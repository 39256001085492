import clsJn from '@property-folders/common/util/classNameJoin';
import { emailRecipientOptions } from '@property-folders/contract';
import { Avatar } from '@property-folders/components/dragged-components/Avatar';
import { Icon } from '@property-folders/components/dragged-components/Icon';
import CreatableSelect from 'react-select/creatable';
import React, { useContext, useMemo } from 'react';
import { canonicalisers } from '@property-folders/common/util/formatting';
import { EntitySettingsContext } from '~/pages/settings/EntitySettingsContext';
import { map } from 'lodash';
import { WorkflowActionPartyEnum, WorkflowActionPartyType } from '@property-folders/contract/yjs-schema/entity-settings';
import { useGetAdminUsersTeams } from '~/pages/settings/tabs/components/useGetAdminUsersTeams';

type Contact = WorkflowActionPartyType & { name: string };

export function RoleUserTeamEmailSelector({ value, onChange }: {
  value: WorkflowActionPartyType[],
  onChange: (options: WorkflowActionPartyType[]) => void
}) {
  const { entityPhpInfo } = useContext(EntitySettingsContext);
  const { users, teams }  = useGetAdminUsersTeams(entityPhpInfo?.entityId);

  const contacts = useMemo(()=> {
    const sp: Contact[] = map(emailRecipientOptions, (v,k) => ({ id: k, name: v, type: 'role' }));
    sp.push(...(users?.map(u => ({ id: u.id, name: u.name, type: 'user' as WorkflowActionPartyEnum }))||[]));
    sp.push(...(teams?.map(u => ({ id: u.id, name: u.name, type: 'team' as WorkflowActionPartyEnum }))||[]));
    sp.push(...(value?.filter(r => r.type === 'email')?.map(r => ({ ...r, name: r.id.toString() }))||[]));
    return sp;
  }, [value, users, teams]);

  return <CreatableSelect
    isMulti
    isClearable
    options={contacts || []}
    getOptionValue={item => `${item?.type}_${item.id?.toString()}`}
    value={contacts?.filter(s => value?.find(v => v.id === s.id && v.type === s.type))}
    onCreateOption={(name:string) => {
      if (canonicalisers.email(name).valid) {
        onChange([...value, { id: name, type: 'email' }]);
      }
    }}
    className={clsJn('user-team-selector flex-grow-1')}
    formatOptionLabel={data => {
      const isInternalGroup = Object.keys(emailRecipientOptions)?.includes(data.id);
      return data.__isNew__
        ? <div className={'fs-6'}>Add email: {data.value}</div>
        : <Avatar
          name={data.name}
          entityName={''}
          agentId={data.id||''}
          nameClassname={isInternalGroup ? 'fw-bold' : ''}
          customIcon={isInternalGroup ? <Icon pack={'material-symbols'} name={'atr'} variant={'outlined'} icoClass={'fs-6 pb-1'}/> : undefined}
        />;
    }}
    onChange={v => onChange(v?.map(c => ({ id: c.id, type: c.type })))}
    styles={{
      menu: base => ({
        ...base,
        zIndex: 10
      }),
      control: base => ({
        ...base,
        borderRadius: 0,
        backgroundColor: 'rgb(244, 245, 250)'
      })
    }}
    classNames={{
      option: () => 'option',
      multiValueLabel: () => 'label'
    }}
  />;
}
