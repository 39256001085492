import { useLightweightTransaction } from '@property-folders/components/hooks/useTransactionField';
import { EmailLinkType, EntityBrandEmailConfig, EntitySettingsEntity, Operator, operatorOptions, WorkflowCondition, WorkflowConditionDefinition, WorkflowConditionDefinitionLeafType, WorkflowConditionJoinType, WorkflowConditionLeafType, WorkflowConditionTriggerType, WorkflowConditionType as Type, WorkflowRule } from '@property-folders/contract/yjs-schema/entity-settings';
import React, { ChangeEvent, useContext, useEffect, useMemo, useState } from 'react';
import { Alert, Button, FloatingLabel, Form, FormControlProps, InputGroup } from 'react-bootstrap';
import { v4 } from 'uuid';
import { useYdocBinder } from '@property-folders/components/hooks/useYdocBinder';
import { Icon } from '@property-folders/components/dragged-components/Icon';
import { findIndex, get, map, mapValues, noop, pick, pickBy, set, sortBy, uniq } from 'lodash';
import { EntitySettingsContext } from '~/pages/settings/EntitySettingsContext';
import { produce } from 'immer';
import { conditionTokens, FormTypeMap, StandardCondition, StandardConditions, WorkflowTriggerTemplates, WorkflowTriggerTemplateType } from '../../../../../common/workflow-rules/WorkflowTriggerTemplates';
import { Typeahead } from 'react-bootstrap-typeahead';
import './EmailTemplates.scss';
import { ContentType, FormCode } from '@property-folders/contract';
import { SettingsImageUpload } from '~/pages/settings/SettingsImageUpload';
import { EntityFileType } from '@property-folders/common/offline/fileStorage';
import { EditorMode } from '@property-folders/components/dragged-components/RichTextEditor';
import {  HtmlTextEditor } from '@property-folders/components/dragged-components/HtmlTextEditor';
import { PlainTextEditor } from '@property-folders/components/dragged-components/PlainTextEditor';
import clsJn from '@property-folders/common/util/classNameJoin';
import { formatTimestamp, inputTransformers, urlRegex } from '@property-folders/common/util/formatting';
import { createPortal } from 'react-dom';
import { EmailPreview, useAgentSessionPreviewEmailContact } from '@property-folders/components/display/EmailPreview';
import { reaformsOrange } from '@property-folders/common/visual';
import { ToolbarPlugin } from '@property-folders/components/dragged-components/lexical/ToolbarPlugin';
import { ConditionTokenType, processTemplate } from '@property-folders/common/util/process-template';
import { DateFunctions } from '@property-folders/common/util/date';
import { AsyncButton } from '@property-folders/components/dragged-components/AsyncButton';
import { Avatar } from '@property-folders/components/dragged-components/Avatar';
import { useEntities } from '@property-folders/components/hooks/useEntity';
import ReactSelect from 'react-select';
import { RoleUserTeamEmailSelector } from '~/pages/settings/tabs/components/RoleUserTeamEmailSelector';
import { NavigationBlocker } from '@property-folders/components/NavigationBlocker';

function Select({ label, options, value, onChange, className, allowEmpty, error }: {label?: string, options: Record<string, string>, value?: string, onChange: React.ChangeEventHandler<HTMLSelectElement>, className?: string, allowEmpty?: boolean, error?: string}  ) {
  const inner = <Form.Select className={clsJn('cursor-pointer', className, error && 'is-invalid')} value={value} onChange={onChange} style={{ width: 'fit-content' }}>
    {allowEmpty && <option value={''}></option>}
    {map(options, ((v,k) => <option key={k} value={k}>{v}</option>))}
  </Form.Select>;
  return label ? <FloatingLabel className={clsJn('common-label', className)} label={label}>{inner}</FloatingLabel> : inner;
}

function Text({ label, path, data, update, error, className, transform, labelStyle, ...restProps }: { label?: string, path: string, data: any, update: UpdateFn, error?: string, className?: string, transform?: (v:string)=>string, labelStyle?: any } & FormControlProps) {
  const inner = <Form.Control
    id={path}
    name={path}
    value={!restProps.disabled ? get(data, path) || '' : ''}
    onBlur={() => transform && update([{ path, value: transform(get(data, path)) }])}
    className={clsJn(className, error && 'is-invalid')}
    onChange={e => update([{ path, value: e.target.value }])}
    {...restProps}
  />;
  return label ? <FloatingLabel style={labelStyle} className={clsJn('common-label', className)} label={label}>{inner}</FloatingLabel> : inner;
}

type UpdateFn = (updates: { path: string, value: any }[] | { path: string, value: any }) => void;
type RemoveFn = (path: string, id: string) => void;

export function EditEmailTemplate({ id, headerRef, onPreviewData }: { id: string, headerRef: React.MutableRefObject, onPreviewData?: (data?: EmailTemplatePreviewData) => void }) {
  const { value: template } = useLightweightTransaction<WorkflowRule>({ parentPath: `workflowRules.[${id}]` });
  const { updateDraft: updateTemplate } = useYdocBinder<WorkflowRule>({ path: `workflowRules.[${id}]` });
  const [rule, setRule] = useState(template);
  const [validation, setValidation] = useState<Record<string, any>>();
  const [isDirty, setIsDirty] = useState(false);
  const ruleTemplate = rule?.trigger ? WorkflowTriggerTemplates?.[rule.trigger] : undefined;
  const documentTypes = uniq(rule?.conditions?.[0]?.conditions?.filter((c: WorkflowConditionLeafType) => c.type === 'document' && c.operator === Operator.In)
    ?.flatMap((c: WorkflowConditionLeafType) => c.value?.map((v:any) => FormTypeMap[v])) || [])?.filter(Boolean);

  const filteredReplacementTokens = useMemo(()=> pickBy(ruleTemplate?.replacementTokens, t => !t.type || documentTypes.includes(t.type) || t.type === ruleTemplate?.type), [documentTypes, ruleTemplate]);
  const filteredConditionTokens = useMemo(()=> pickBy(ruleTemplate?.conditionTokens, t => !t.type || documentTypes.includes(t.type) || t.type === ruleTemplate?.type), [documentTypes, ruleTemplate]);

  //assuming there is only 1 action(email) for now
  const action = rule?.actions?.[0];

  const validate = () => {
    const val:Record<string, any> = {};
    if (!rule?.trigger) {
      val.trigger = 'Trigger is required';
    }

    if (rule?.enabled) {
      val.links = [];
      action?.links?.forEach(l => {
        val.links.push(l.type === 'external' && !urlRegex.test(l?.url||'') ? 'Link is not valid' : undefined);
      });
      if (!action?.subject) {
        val.subject = 'Subject is required';
      }
      if (!action?.to?.length) {
        val.to = 'At least 1 recipient is required';
      }
    }
    setValidation(val);
  };

  useEffect(() => {
    validate();
    setPreviewData();
  }, [rule]);

  useEffect(() => {
    validate();
    setPreviewData();
  }, []);

  const setPreviewData = () => {
    const email = rule?.actions?.[0];
    if (email) {
      onPreviewData?.({
        subject: email.subject || '',
        body: email.body || '',
        links: email.links?.map(l => ({ url: l?.type === 'external' ? l.url : undefined, urlText: l.text })),
        ...email.cta?.enabled && {
          buttonUrl: email.cta?.button1?.url,
          buttonText: email.cta?.button1?.text,
          buttonUrl2: email.cta?.button2?.url,
          buttonText2: email.cta?.button2?.text,
          ctaPreamble: email.cta?.preamble
        }
      });
    } else {
      onPreviewData?.(undefined);
    }
  };

  const update = (updates: { path: string, value: any }[] | { path: string, value: any }) => {
    updates = Array.isArray(updates) ? updates : [updates];
    const updated = produce(rule, draft => {
      if (!draft) return;
      updates.forEach(u => set(draft, u.path, u.value));
    });
    setRule(updated);
    setIsDirty(true);
    setPreviewData();
  };

  const remove = (path: string, id: string) => {
    const updated = produce(rule, draft => {
      const arr = get(draft, path);
      const removalIndex = findIndex(arr, c => c.id === id);
      if (removalIndex >= 0) {
        arr.splice(removalIndex, 1);
      }
    });
    setRule(updated);
    setIsDirty(true);
  };

  const handleTriggerChange = (e: ChangeEvent<HTMLSelectElement>) => {
    const newTrigger = e.target.value as WorkflowConditionTriggerType;
    update([
      { path: 'trigger', value: newTrigger },
      { path: 'conditions', value: getNewTriggerConditions(newTrigger) },
      { path: 'actions', value: rule?.actions
        ? [{
          ...rule.actions[0],
          cta: {
            ...WorkflowTriggerTemplates[newTrigger]?.actions?.[0]?.cta,
            preamble: rule.actions[0]?.cta?.preamble
          }
        }]
        : WorkflowTriggerTemplates[newTrigger]?.actions }
    ]);
  };

  const getNewTriggerConditions = (trigger: WorkflowConditionTriggerType): WorkflowConditionLeafType[] => {
    const addIds = (condition: WorkflowConditionDefinition): WorkflowCondition => {
      const { options, operators, ...newCondition } = condition; //remove the lookups
      return {
        id: v4(),
        ...newCondition,
        ...'conditions' in condition && condition.conditions && { conditions: condition.conditions?.map(c => addIds(c)) }
      };
    };
    return [{
      ...(addIds(WorkflowTriggerTemplates[trigger]?.defaultCondition) as WorkflowConditionLeafType)
    }];
  };

  const saveTemplate = async() => {
    const { links, ...rest } = validation;
    if (Object.keys(rest??{}).length || links?.some((l:string|undefined) => l)) return;

    updateTemplate?.(draft => {
      if (!rule) return;
      draft.enabled = rule.enabled;
      draft.trigger = rule.trigger;
      draft.conditions = rule.conditions;
      draft.actions = rule.actions;
      draft.name = rule.name;
      draft.modified = Date.now();
      draft.settings = rule.settings;
    });

    //only for the loading spinner on the save button
    await new Promise(res => setTimeout(res, 1000));
    setIsDirty(false);
  };

  const setFile = (idx: number, fileId: string, contentType: string, name?: string) => {
    update({ path: `actions[0].links[${idx}].file`, value: { id: fileId, contentType, name } });
  };

  const removeFile = (idx: number) => {
    update({ path: `actions[0].links[${idx}].file`, value: undefined });
  };

  const addLink = () => {
    update({ path: 'actions[0].links', value: [...action?.links??[], { id: v4(), type: 'external' }] });
  };

  const removeLink = (id?: string) => {
    id && remove('actions[0].links', id);
  };

  const [smsCharacterCount, setSmsCharacterCount] = useState<number>(0);
  const triggerOptions = useMemo(() => ({ ...mapValues(WorkflowTriggerTemplates, (v) => v.label) }), []);

  return rule?.id
    ? <div id="EmailTemplatesEdit" className="w-100 h-100">
      <NavigationBlocker key={'email-templates-edit'} when={isDirty}/>
      <div className={'mb-4 d-flex'}>
        <Text label={'Name'} path={'name'} className={'flex-grow-1 me-4'} data={rule} update={update}/>
        <Form.Check type="switch" id={'enabled'} label={'Enabled'} checked={rule.enabled} onChange={e => update({ path: 'enabled', value: e.target.checked })}/>
      </div>

      <div className={'mb-3 flex-wrap'}>
        <div className={'d-flex gap-3 flex-wrap'}>
          <Select label={'When'} allowEmpty={!rule?.trigger} className={'flex-shrink-0'} options={triggerOptions} value={rule.trigger} onChange={handleTriggerChange} error={validation?.trigger}/>
          <div style={{ flex: '1 1 200px' }}>{ruleTemplate?.description}</div>
        </div>
        {rule.trigger === WorkflowConditionTriggerType.DocumentDistributed && <div className={'mt-3'}>
          <Form.Check type="checkbox" id={'suppressForPaperSignInPast'} label={'Do not send if paper signed before date of uploading'} checked={rule.settings?.suppressForPaperSignInPast} onChange={e => update({ path: 'settings.suppressForPaperSignInPast', value: e.target.checked })}/>
        </div>}
      </div>

      {rule?.trigger && ruleTemplate && <div className={'mb-4'}>
        <div className={'fs-5'}>Conditions</div>
        {rule?.conditions?.length && <RuleCondition condition={rule.conditions[0]} path={'conditions.0'} update={update} remove={remove} onDelete={noop} ruleTemplate={ruleTemplate}/>}
      </div>}

      {rule?.trigger && <>
        <div>
          <div className={'fs-5 d-flex flex-wrap gap-2 align-items-baseline'}>Email<small className={'combo-field-subtext'} style={{ fontSize: 'small' }}>Select roles, users, teams, or type an email address</small></div>
          <div className={'mt-1'}>
            <InputGroup className="d-flex flex-no-wrap">
              <InputGroup.Text style={{ flex: '0 0 45px' }}>To</InputGroup.Text>
              <RoleUserTeamEmailSelector value={action?.to||[]} onChange={(newValue) => update([{ path: 'actions[0].to', value: newValue }])} />
            </InputGroup>
          </div>
          <div className={'mt-2'}>
            <InputGroup className="d-flex flex-no-wrap">
              <InputGroup.Text style={{ flex: '0 0 45px' }}>Bcc</InputGroup.Text>
              <RoleUserTeamEmailSelector value={action?.bcc||[]} onChange={(newValue) => update([{ path: 'actions[0].bcc', value: newValue }])} />
            </InputGroup>
            {!!action?.bcc?.length && <Alert className={'mt-1'} variant={'warning'}>Bcc recipients will receive a copy of every email sent to a recipient in the To list. </Alert> }
          </div>
        </div>

        <div className={'mt-3'}>
          <Text label={'Subject'} path={'actions[0].subject'} error={validation?.subject} className={'mt-3'} data={rule} update={update}/>

          {<div className={'mb-3 mt-3'}>
            <div className={'fs-5 d-flex gap-2 mb-2 align-items-center'}>Document Links <Form.Check type="switch" id={'enabled'} checked={!!action?.cta?.enabled} onChange={e => update({ path: 'actions[0].cta.enabled', value: e.target.checked })}/></div>

            {!!action?.cta?.enabled && <div className={'d-flex flex-column gap-2'}>
              <Text label={'Preamble'} path={'actions[0].cta.preamble'} data={rule} update={update}/>

              {rule.trigger === WorkflowConditionTriggerType.DocumentDistributed && <div className={'d-flex'}>
                <Text label={'Button Text'} path={'actions[0].cta.button1.text'} data={rule} update={update}/>
              </div>}

              {rule.trigger === WorkflowConditionTriggerType.CoolingOffBegun && <div className={'d-flex flex-row gap-2 align-items-center flex-wrap'}>
                <div className={'d-flex gap-2'}>
                  <InputGroup className="d-flex">
                    {<Text label={'Download Contract Button'} disabled={!action?.cta?.button1} path={'actions[0].cta.button1.text'} data={rule} update={update} style={{ minWidth: '190px' }}/>}
                    <InputGroup.Text>
                      <Form.Check type="switch" id={'button1-enabled'} checked={!!action?.cta?.button1} onChange={e => update({ path: 'actions[0].cta.button1', value: e.target.checked ? { text: 'Download Contract', type: EmailLinkType.Document, formFamily: FormCode.RSC_ContractOfSale } : undefined })}/>
                    </InputGroup.Text>
                  </InputGroup>
                </div>
                <div className={'d-flex gap-2'}>
                  <InputGroup className="d-flex">
                    {<Text label={'Download Form 1 Button'} disabled={!action?.cta?.button2} path={'actions[0].cta.button2.text'} data={rule} update={update} style={{ minWidth: '190px' }}/>}
                    <InputGroup.Text>
                      <Form.Check type="switch" id={'button2-enabled'} checked={!!action?.cta?.button2} onChange={e => update({ path: 'actions[0].cta.button2', value: e.target.checked ? { text: 'Download Form 1', type: EmailLinkType.Document, formFamily: FormCode.Form1 } : undefined })}/>
                    </InputGroup.Text>
                  </InputGroup>
                </div>
              </div>}
            </div>}
          </div>}

          <div className="d-flex flex-column mt-2">
            <div className={'fs-5 mb-2'}>Body</div>
            <HtmlTextEditor
              namespace="email-form-dialog"
              outputMode={EditorMode.HTML}
              onUpdate={value => {
                //strip placeholders
                const replaced = value?.replace(/\s*class="[^"]*(replacement|condition)-token[^"]*"/gm, '');
                update({ path: 'actions[0].body', value: replaced });
              }}
              value={action?.body}
              toolbar={() => <ToolbarPlugin useStandardListPlugin={true} replacementTokens={filteredReplacementTokens} conditionTokens={filteredConditionTokens}/>}
              replacementTokens={ruleTemplate?.replacementTokens}
              conditionTokens={conditionTokens}
            />
          </div>

          <div className="d-flex flex-column mt-3">
            <div className={'fs-5'}>Links</div>

            {action?.links?.map((link, idx) => <div className={'mt-2'} key={link.id}>
              <div className={'d-flex flex-wrap row-gap-1 flex-grow-1'}>
                <Select
                  options={{ external: 'External Link', file: 'File' }}
                  value={link.type}
                  onChange={e => update([{ path: `actions[0].links[${idx}]`, value: { ...link, type: e.target.value } }])}
                  label={'Type'}
                />

                {link?.type === EmailLinkType.External &&
                <div className={'d-flex flex-wrap row-gap-1'} style={{ flex: '1 1 auto' }}>
                  <Text label={'Link'} path={`actions[0].links[${idx}].url`} error={validation?.links?.[idx]} transform={inputTransformers.url} labelStyle={{ flex: '1 1 200px' }} data={rule} update={update} autoFocus />
                  <Text label={'Display Text'} path={`actions[0].links[${idx}].text`} labelStyle={{ flex: '1 1 200px' }} data={rule} update={update}/>
                </div>
                }

                {link?.type === EmailLinkType.File &&
                <div className={'d-flex gap-2 flex-wrap flex-grow-1'}>
                  <div>
                    <SettingsImageUpload
                      label={''}
                      labelClass={'fs-5'}
                      showPreview={false}
                      onUpload={(fileId, contentType, name)=> setFile(idx, fileId, contentType, name)}
                      onRemove={()=>removeFile(idx)}
                      fileType={EntityFileType.EmailTemplateFile}
                      parentId={rule.id}
                      contentTypes={[ContentType.Pdf]}
                      hasFile={!!link?.file}
                    />
                    {link?.file && <div>{link?.file?.name}</div>}
                  </div>
                  <Text label={'Display Text'} path={`actions[0].links[${idx}].text`} className={'flex-grow-1'} data={rule} update={update}/>
                </div>
                }

                <Button variant={'light'} className={'btn-list-action coll-del-button form-floating'} onClick={()=>removeLink(link.id)} title="Remove link">
                  <Icon pack={'material-symbols'} name={'delete'} variant={'outlined'}></Icon>
                </Button>
              </div>
            </div>)}
            <div><Icon onClick={addLink} name='add' variant='outlined' icoClass='add-condition mt-2 mb-1'></Icon></div>
          </div>

          {action?.to?.some(r => r.type === 'role') && <div className="d-flex flex-column mt-3">
            <div className={'fs-5 d-flex gap-2 align-items-center mb-2'}>
              <span>Additional SMS Notification <Icon name={'info'} variant={'outlined'} style={{ fontSize: '14px', marginTop: '-10px', cursor: 'default', color: 'rgb(26, 110, 246)' }} title={'If selected, an SMS will be sent to a recipient in addition to an email, if there is a mobile number included within Greatforms for that recipient. The SMS will come from "reaforms".'}/></span>
              <Form.Check type="switch" id={'sms-enabled'} checked={!!action?.smsNotification?.enabled} onChange={e => update({ path: 'actions[0].smsNotification.enabled', value: e.target.checked })}/>
              {action?.smsNotification?.enabled && <span className={'fs-6'}>{280 - smsCharacterCount} characters remaining</span>}
            </div>
            {action?.smsNotification?.enabled && <PlainTextEditor
              namespace="email-form-dialog"
              onUpdate={value => {
                const replaced = value?.replace(/\s*class="[^"]*replacement-token[^"]*"/gm, '');
                update({ path: 'actions[0].smsNotification.message', value: replaced });
              }}
              value={action?.smsNotification?.message}
              toolbar={() => <ToolbarPlugin plainTextOnly={true} replacementTokens={ruleTemplate?.replacementTokens}/>}
              tokens={ruleTemplate?.replacementTokens}
              onCharacterCountChange={(count)=>setSmsCharacterCount(count)}
            />}
          </div>}

        </div>
      </>}

      {headerRef?.current && createPortal(<AsyncButton variant={'primary'} className={'ms-auto'} onClick={saveTemplate}>Save</AsyncButton>, headerRef.current)}
    </div>
    : <></>;
}

function RuleCondition({ condition, path, update, remove, onDelete, remainingConditions, ruleTemplate }:
  { condition: WorkflowCondition,
    path: string,
    update: UpdateFn,
    remove: RemoveFn,
    onDelete: (id: string) => void,
    remainingConditions?: Record<StandardCondition, WorkflowConditionDefinitionLeafType>,
    ruleTemplate: WorkflowTriggerTemplateType
  }) {

  if ([Type.And, Type.Or].includes(condition.type)) {
    const joinCondition = condition as WorkflowConditionJoinType;
    const remainingConditionsChildren = pickBy(StandardConditions, (v, k: StandardCondition) => {
      return !joinCondition.conditions?.filter(jc => ![Type.And, Type.Or].includes(jc.type))?.map(c => 'name' in c ? c.name : '')?.includes(k)
          && (!ruleTemplate?.allowedConditions || ruleTemplate?.allowedConditions?.includes(k));
    }
    );
    const showAddButton = !!Object.keys(remainingConditionsChildren)?.length;

    const addCondition = () => {
      const firstCondition = remainingConditionsChildren[Object.keys(remainingConditionsChildren??{})?.[0]];
      remainingConditionsChildren && update({ path: `${path}.conditions`, value: [...joinCondition.conditions, { id: v4(), ...firstCondition }] });
    };

    return <div key={path} className="w-100">
      {(joinCondition).conditions?.map(((c, i) =>
        <div key={`${path}.conditions.${i}`}>
          <RuleCondition condition={c} path={`${path}.conditions.${i}`} update={update} remove={remove} onDelete={(id)=> remove(`${path}.conditions`, id)} remainingConditions={remainingConditionsChildren} ruleTemplate={ruleTemplate}/>
          {i < (condition as WorkflowConditionJoinType).conditions?.length-1 && <div className={'mt-2'}>{condition.type === Type.And ? 'AND' : 'OR'}</div>}
          {i === (condition as WorkflowConditionJoinType).conditions?.length-1 && showAddButton && <div><Icon onClick={addCondition} name='add' variant='outlined' icoClass='add-condition mt-2 mb-1'></Icon></div>}
        </div>))
      }

      {[Type.And, Type.Or].includes(condition.type) && !(condition as WorkflowConditionJoinType).conditions?.length &&
        <div><Icon onClick={addCondition} name="add" variant="outlined" icoClass="add-condition mt-2 mb-1"></Icon></div>
      }
    </div>;
  } else {
    const leafCondition = condition as WorkflowConditionLeafType;
    const conditionTemplate = StandardConditions[leafCondition?.name];
    const options = useMemo(() => sortBy(map(conditionTemplate?.options, (v: any, k: any) => ({ name: k, label: v })), 'label'), [leafCondition.name]);
    const selectedOptions = useMemo(() => leafCondition.value?.map((v: string | number) => ({ name: v, label: conditionTemplate?.options?.[v]||'' })), [leafCondition.value]);
    const { entityPhpInfo } = useContext(EntitySettingsContext);
    const localEntities = useEntities();
    const localEntity = entityPhpInfo?.entityId ? localEntities?.[entityPhpInfo.entityId] : undefined;
    const salespeople = localEntity?.salespeople?.filter(s => s.isSalesperson);

    return <div key={path} className="w-100">

      {[Type.Field, Type.MappedField, Type.Document].includes(condition.type) && (condition = condition as WorkflowConditionLeafType) && <div className={'d-flex mt-2 align-items-center gap-2 flex-wrap'}>
        <div>
          <Select
            options={mapValues({ ...remainingConditions, [condition.name]: conditionTemplate }, v => v?.label)}
            value={(condition as WorkflowConditionLeafType).name}
            onChange={e => update([{ path: path, value: { ...condition, ...StandardConditions[e.target.value as StandardCondition], name: e.target.value, value: [] } }])}
          />
        </div>
        <div>
          <Select options={pick(operatorOptions, conditionTemplate?.operators||[])} value={condition.operator} onChange={e => update({ path: `${path}.operator`, value: e.target.value })}/>
        </div>
        <div className={'flex-grow-1'}>
          {conditionTemplate?.options === 'salespeople' ?
            <ReactSelect
              isMulti
              isClearable
              placeholder={'All'}
              options={salespeople || []}
              getOptionValue={item => item.id?.toString()}
              value={salespeople?.filter(s => leafCondition.value?.includes(s.id))}
              className="user-team-selector"
              formatOptionLabel={data => {
                return <Avatar
                  name={data.name}
                  entityName={''}
                  agentId={data.id}
                />;
              }}
              onChange={newValue => {
                update([{ path: `${path}.value`, value: newValue?.map(s => s.id) }]);
              }}
              styles={{
                menu: base => ({
                  ...base,
                  zIndex: 10
                }),
                control: base => ({
                  ...base,
                  borderRadius: 0,
                  backgroundColor: 'rgb(244, 245, 250)'
                })
              }}
              classNames={{
                option: () => 'option',
                multiValueLabel: () => 'label'
              }}
            />
            :
            <Typeahead
              id={`${path}.value`}
              labelKey={'label'}
              align={'left'}
              options={options}
              selected={selectedOptions || []}
              onChange={(selected) => {
                update([{ path: `${path}.value`, value: selected?.map(s => s.name) }]);
              }}
              placeholder={`Choose a ${conditionTemplate?.label}`}
              multiple
            />}
        </div>
        <div>
          <Button
            variant={'light'}
            className={'btn-list-action coll-del-button form-floating'}
            onClick={() => onDelete(condition.id)}
            title="Remove this condition"
          >
            <Icon pack={'material-symbols'} name={'delete'} variant={'outlined'}></Icon>
          </Button>
        </div>
      </div>}
    </div>;
  }
}

export type EmailTemplatePreviewData = {
  subject: string,
  body: string,
  ctaPreamble?: string
  buttonUrl?: string,
  buttonText?: string,
  buttonUrl2?: string,
  buttonText2?: string,
  links?: {
    url?: string,
    urlText?: string
  }[]
};
export function EmailTemplatePreview({ previewKey, onPreviewClose, previewData }: { previewKey: string, onPreviewClose?: () => void, previewData: EmailTemplatePreviewData }) {
  switch (previewKey) {
    case EmailTemplatePreviewKey.Email:
    default:
      return <EmailTemplatePreviewInner onPreviewClose={onPreviewClose} previewData={previewData}/>;
  }
}

export enum EmailTemplatePreviewKey {
  Email = 'email'
}

function EmailTemplatePreviewInner({ onPreviewClose, previewData }: { onPreviewClose?: () => void, previewData: EmailTemplatePreviewData }) {
  const { entityPhpInfo } = useContext(EntitySettingsContext);
  const { value: brand } = useLightweightTransaction<EntityBrandEmailConfig>({ parentPath: 'brand.email' });
  const { value: trustAccount } = useLightweightTransaction<EntitySettingsEntity['trustAccount']>({ myPath: 'trustAccount' });
  const contact = useAgentSessionPreviewEmailContact();

  useEffect(() => {
    const doc = new DOMParser().parseFromString(previewData?.body, 'text/html');
    setPreviewConditions(prev => {
      const previewConditions: Record<string, boolean> = { };
      doc.querySelectorAll('div[data-lexical-condition]').forEach(node => {
        const cond = node.getAttribute('data-lexical-condition');
        const inverse = node.getAttribute('data-lexical-condition-inverse') === 'true';
        if (cond) {
          previewConditions[cond] = prev?.[cond] !== undefined ? prev?.[cond] : !inverse;
        }
      });
      return previewConditions;
    });
  }, [previewData?.body, conditionTokens]);

  const [previewConditions, setPreviewConditions] = useState<Record<ConditionTokenType, boolean>>();

  const replacements = {
    '@@PropertyAddress': '180 Flinders Street, Adelaide SA 5000',
    '@@RecipientFirstName': 'John',
    '@@RecipientFullLegalName': 'John Doe',
    '@@SalespersonName': contact?.name || 'Sally Salesperson',
    '@@SalespersonEmail': `<a href='mailto:${contact?.email || 'sally@example.com'}'>${contact?.email || 'Sally@example.com'}</a>`,
    '@@SalespersonPhone': '0400 000 000',
    '@@CoolingOffBeginsDate': formatTimestamp(Date.now()),
    '@@CoolingOffEndsDate': formatTimestamp(DateFunctions.CalculateCoolingOffEndDate(Date.now())),
    '@@DocumentName': 'Contract of Sale',
    '@@AgencyTradingName': contact.entity?.customerFacingName,
    '@@AgencyTrustAccountName': trustAccount?.name,
    '@@AgencyTrustAccountBSB': trustAccount?.bsb,
    '@@AgencyTrustAccountNumber': trustAccount?.number,
    '@@DepositAmount': '$50,000',
    '@@DepositDueDate': formatTimestamp(Date.now(), undefined, false),
    '@@SubjectToFinanceDueDate': formatTimestamp(Date.now(), undefined, false),
    '@@SubjectToFinanceAmount': '$500,000',
    '@@SubjectToSaleContractDueDate': formatTimestamp(Date.now(), undefined, false),
    '@@SubjectToSaleUnconditionalDueDate': formatTimestamp(Date.now(), undefined, false),
    '@@SubjectToSaleSettlementDueDate': formatTimestamp(Date.now(), undefined, false),
    '@@SettlementDate': formatTimestamp(Date.now(), undefined, false)
  };

  const conditionValues = useMemo(() => {
    return mapValues(conditionTokens, (v,k) => ({ ...v, isTrue: ()=> previewConditions?.[k as ConditionTokenType] }));
  }, [previewConditions]);
  const document = new DOMParser().parseFromString(previewData?.body||'', 'text/html');
  const replacedBody = processTemplate(document, {}, replacements, conditionValues);

  return previewData && <div>
    {!!Object.keys(previewConditions||{}).length && <div className={'mb-2 p-3 bg-white card-header'}>
      {map(previewConditions, (v,k)=> <div key={k}>
        <Form.Check
          id={`previewConditions-${k}`}
          type="switch"
          label={conditionTokens[k as ConditionTokenType]?.label||k}
          className={'me-3'}
          checked={v}
          onChange={(e) => setPreviewConditions(prev => ({ ...prev, [k]: e.target.checked }))}
        />
      </div>)}
    </div>}
    <EmailPreview
      brand={{
        logoUrl: entityPhpInfo?.urlEmailLogo || '',
        logoDims: undefined,
        button: brand?.button ?? {
          foregroundColour: reaformsOrange,
          backgroundColour: '#ffffff'
        },
        content: brand?.content ?? {
          foregroundColour: '#ffffff',
          backgroundColour: reaformsOrange
        }
      }}
      brandedContent={{
        text: previewData?.ctaPreamble,
        button: previewData?.buttonText,
        buttonUrl: previewData?.buttonUrl,
        button2: previewData?.buttonText2,
        buttonUrl2: previewData?.buttonUrl2
      }}
      subject={previewData?.subject}
      messageHtml={replacedBody}
      links={previewData?.links}
    />
  </div>;
}
