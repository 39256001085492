import {
  FormCode,
  FormCodeUnion,
  LookupPropertiesResult,
  LookupPropertiesResultItem,
  YDocContentType
} from '@property-folders/contract';
import { Alert, Button, Col, Container, Form, Row } from 'react-bootstrap';
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { AsyncTypeahead } from 'react-bootstrap-typeahead';
import { v4 } from 'uuid';
import { Properties } from '@property-folders/common/client-api/properties';
import { YManager } from '@property-folders/common/offline/yManager';
import { handleNewForm } from '@property-folders/common/util/handleNewForm';
import { FileSync } from '@property-folders/common/offline/fileSync';
import { useNavigate } from 'react-router-dom';
import { YManagerContext } from '@property-folders/components/context/YManagerContext';
import { FileSyncContext } from '@property-folders/components/context/fileSyncContext';
import { LinkBuilder } from '@property-folders/common/util/LinkBuilder';
import { castOptions } from '~/components/create-form/CreateSubscriptionFormPane';
import { SpinnerButton } from '@property-folders/components/dragged-components/AsyncButton';
import { FormTypes } from '@property-folders/common/yjs-schema/property/form';
import { FormUtil } from '@property-folders/common/util/form';
import {
  generateHeadlineFromMaterialisedData,
  materialisePropertyData,
  materialisePropertyMetadata
} from '@property-folders/common/yjs-schema/property';
import { useOnline } from '@property-folders/components/hooks/useOnline';
import { OfflineProperties } from '@property-folders/common/offline/offlineProperties';
import { ShortId } from '@property-folders/common/util/url';
import { buildContractDocumentAndForm } from '@property-folders/components/form-gen-util/buildSubDocument';

const searchResultsLimit = 5;

type Props = {
  formCode: FormCodeUnion,
  propertyId?: string,
  onClose: ()=>void,
  skipConfirmation?:boolean,
  onCreate?: (propertyId: string)=>void,
  createLabel?: string,
  label?: string,
  placeholder?: string,
  allowReplace?: boolean,
  defaultSearch?: string,
  allowNew?: boolean,
  templateMode?: boolean
};

export function CreateInPropertyFolderPane({
  formCode,
  propertyId: propertyIdRaw,
  onClose,
  skipConfirmation,
  onCreate,
  createLabel,
  label,
  placeholder,
  allowReplace,
  defaultSearch,
  allowNew = true,
  templateMode = false
}: Props) {
  const navigate = useNavigate();
  const [idPrefix] = useState(v4());
  const { instance: yManager } = useContext(YManagerContext);
  const { instance: fileSync } = useContext(FileSyncContext);
  const online = useOnline();

  const [propertyQuery, setPropertyQuery] = useState(defaultSearch||'');
  const [propertyLoading, setPropertyLoading] = useState(false);
  const [selectedProperty, setSelectedProperty] = useState<LookupPropertiesResultItem[]>([]);
  const [properties, setProperties] = useState<LookupPropertiesResult>({ items: [] });
  const propertyId =  useMemo(() => propertyIdRaw ? ShortId.toUuid(propertyIdRaw) : undefined, [propertyIdRaw]);

  useEffect(() => {
    if (!online) {
      setPropertyLoading(true);
      const ac = new AbortController();
      OfflineProperties.search(propertyQuery)
        .then(data => {
          if (ac.signal.aborted) return;

          setProperties({
            items: data.slice(0,searchResultsLimit).map(op => {
              return {
                propertyId: op.id,
                headline: op.headline,
                saleAddresses: [op.address],
                vendors: op.vendors || [],
                created: op.created ?? Date.now(),
                addRestriction: FormUtil.getFormRestriction(op.meta?.formStates || {}, formCode, formCode === FormCode.RSC_ContractOfSale)
              };
            })
          });
          setSelectedProperty(prev =>
            prev.filter(prevItem => data.find(x => x.id === prevItem.propertyId)));
        })
        .catch(console.error)
        .finally(() => setPropertyLoading(false));
      return () => {
        ac.abort();
      };
    }

    const { ac, results } = Properties.lookup({
      searchTerm: propertyQuery,
      formCode,
      sublineageIntent: formCode === FormCode.RSC_ContractOfSale,
      limit: searchResultsLimit
    });

    results
      .then(data => {
        if (!data) return;
        if (ac.signal.aborted) return;
        setProperties(data);
      })
      .catch(console.error)
      .finally(() => setPropertyLoading(false));

    return () => {
      setPropertyLoading(false);
      ac.abort();
    };
  }, [propertyQuery, online, defaultSearch]);

  useEffect(() => {
    if (!propertyId) return;
    if (!yManager) return;

    const { doc } = yManager.get(propertyId, YDocContentType.Property, {});

    const data = materialisePropertyData(doc);
    const meta = materialisePropertyMetadata(doc);

    setSelectedProperty([{
      propertyId,
      addRestriction: FormUtil.getFormRestriction(meta.formStates || {}, formCode, formCode === FormCode.RSC_ContractOfSale),
      headline: generateHeadlineFromMaterialisedData(data),
      // vendors/addresses are used in the typeahead item display, so irrelevant in this case when there is no typeahead
      vendors: [],
      saleAddresses: [],
      created: new Date(meta.createdUtc ?? Date.now()).getTime()
    }]);
  }, [propertyId, yManager]);

  useEffect(() => {
    selectedProperty?.[0]?.propertyId && skipConfirmation && createHandler();
  }, [selectedProperty]);

  const searchFunc = useCallback((query: string) => {
    setPropertyQuery(query);
  }, []);

  const [errorMessage, setErrorMessage] = useState('');
  const [processing, setProcessing] = useState(false);
  const viewHandler = async () => {
    const property = selectedProperty.at(0);
    if (!property?.addRestriction) return;
    if (property.addRestriction.type === 'invalid') return;

    const propertySlug = LinkBuilder.seoFriendlySlug(property.propertyId, property.headline);
    const typeSlug = FormTypes[property.addRestriction.formCode].subscription ? 'subscription' : 'document';
    const documentSlug = LinkBuilder.seoFriendlySlug(property.addRestriction.formId);
    navigate(`/properties/${propertySlug}/${typeSlug}/${documentSlug}`);
    onClose();
    return;
  };
  const createHandler = async () => {
    setProcessing(true);
    const property = selectedProperty.at(0);

    if (onCreate && property) return onCreate(property.propertyId);
    if (!fileSync) return;
    if (!yManager) return;
    // in practice this shouldn't happen because we'd be calling viewHandler not createHandler
    if (property?.addRestriction) return;

    try {
      setErrorMessage('');
      if (!property) {
        if (templateMode) {
          navigate(`/templates/new?createForm=${formCode}`);
          onClose();
          return;
        }

        navigate(`/properties/new?createForm=${formCode}`);
        onClose();
        return;
      }

      const createdFormId = await addToExistingPropertyFolder({
        propertyId: property.propertyId,
        fileSync,
        yManager,
        formCode
      });

      if (!createdFormId) {
        setErrorMessage('Failed to create form.');
        return;
      }

      const propertySlug = LinkBuilder.seoFriendlySlug(property.propertyId, property.headline);
      const typeSlug = FormTypes[formCode].subscription ? 'subscription' : 'document';
      const documentSlug = LinkBuilder.seoFriendlySlug(createdFormId);
      navigate(`/properties/${propertySlug}/${typeSlug}/${documentSlug}`);
      onClose();
    } catch (err: unknown) {
      console.error(err);
      setErrorMessage('Failed to create form.');
    } finally {
      setProcessing(false);
    }
  };
  const formLabel = FormTypes[formCode]?.label;
  const firstSelectedProperty = selectedProperty.at(0);
  const addRestriction = templateMode
    ? false
    : (firstSelectedProperty
      ? firstSelectedProperty.addRestriction
      : FormUtil.getFormRestriction({}, formCode, formCode === FormCode.RSC_ContractOfSale));

  return <Container>
    {errorMessage && <Row>
      <Col>
        <Alert variant='danger'>{errorMessage}</Alert>
      </Col>
    </Row>}
    {!propertyId && <Row className='mt-3'>
      <Col>
        <AsyncTypeahead
          id={`${idPrefix}-select-property-folder`}
          labelKey='headline'
          defaultInputValue={defaultSearch}
          filterBy={() => {
            return true;
          }}
          placeholder={placeholder || 'Create new Property Folder'}
          options={properties.items}
          onChange={x => setSelectedProperty(castOptions<LookupPropertiesResultItem>(x))}
          selected={selectedProperty}
          isLoading={propertyLoading}
          onSearch={searchFunc}
          minLength={0}
          maxResults={searchResultsLimit}
          defaultOpen={!!defaultSearch}
          emptyLabel='No matching Property Folder found.'
          renderInput={({ inputRef, referenceElementRef, value, ...inputProps }) => (
            <>
              <Form.Label htmlFor={`${idPrefix}-select-property-folder`} className='fs-5'>
                {label || (templateMode ? 'What do you want to call this template document?' : 'Where should this document be created?')}
              </Form.Label>
              {templateMode
                ? <></>
                : <Form.Control
                  value={value as string | string[] | number | undefined}
                  {...inputProps}
                  ref={(node: any) => {
                    inputRef(node);
                    referenceElementRef(node);
                  }}
                  tabIndex={1}
                  size='lg'
                />}
            </>
          )}
          renderMenuItemChildren={(option) => {
            const asResult = option as LookupPropertiesResultItem;
            const addresses = asResult.saleAddresses.join(', ');
            const vendors = asResult.vendors.join(', ');
            return <div>
              <div className='fw-bold'>{asResult.headline}</div>
              <div className='text-truncate' title={addresses}><small>{addresses}</small></div>
              <div className='text-truncate' title={vendors}><small>{vendors}</small></div>
            </div>;
          }}
        />
      </Col>
    </Row>}
    <Row className='mt-3'>
      <Col>
        {(() => {
          switch (addRestriction?.type) {
            case 'invalid':
              return firstSelectedProperty
                ? <Alert variant='danger'>{formLabel} cannot be created in the selected property folder.</Alert>
                : <Alert variant='danger'>{formLabel} cannot be created in a new property folder.</Alert>;
            case 'unsigned':
              return formCode === addRestriction.formCode
                ? <Alert variant='warning'>{formLabel} cannot be created as the current {FormTypes[addRestriction.formCode].label} has not been signed.</Alert>
                : <Alert variant='warning'>{formLabel} cannot be created as the {FormTypes[addRestriction.formCode].label} has not been signed.</Alert>;
            case 'existing':
              return <Alert variant='success'>An existing {formLabel} has been found for this property.</Alert>;
            default:
              // future: template selector will go here
              return <></>;
          }
        })()}
      </Col>
    </Row>
    <Row className='mt-3'>
      <Col className='d-flex flex-row justify-content-end gap-2'>
        <Button
          tabIndex={5}
          variant='outline-secondary'
          disabled={processing}
          onClick={onClose}
        >Cancel</Button>
        {(() => {
          switch (addRestriction?.type) {
            case 'invalid':
              return <></>;
            case 'existing':
              return <>
                <Button
                  tabIndex={4}
                  variant={allowReplace ? 'outline-secondary' : ''}
                  onClick={viewHandler}
                >View</Button>
                {allowReplace && <SpinnerButton
                  tabIndex={5}
                  processing={processing}
                  onClick={createHandler}
                >Replace</SpinnerButton>}
              </>;
            case 'unsigned':
              return <SpinnerButton
                tabIndex={4}
                processing={processing}
                onClick={viewHandler}
              >View</SpinnerButton>;
            default:
              return <SpinnerButton
                tabIndex={4}
                disabled={!allowNew && !selectedProperty?.length}
                processing={processing}
                onClick={createHandler}
              >{createLabel || 'Create'}</SpinnerButton>;
          }
        })()}
      </Col>
    </Row>
  </Container>;
}

async function addToExistingPropertyFolder({
  propertyId,
  yManager,
  formCode,
  fileSync
}: {
  propertyId: string,
  yManager: YManager,
  formCode: FormCodeUnion,
  fileSync: FileSync
}) {
  const { doc, localProvider }  = yManager.get(propertyId, YDocContentType.Property, {});

  await localProvider.whenSynced;
  await yManager.waitForSync(doc);

  if (formCode === FormCode.RSC_ContractOfSale) {
    return (await buildContractDocumentAndForm(doc, fileSync))?.formId;
  }

  return (await handleNewForm(doc, formCode, fileSync))?.formId;
}
