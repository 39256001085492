import { Alert, Modal, Spinner, Tab, Tabs } from 'react-bootstrap';
import { FormCodeUnion } from '@property-folders/contract';
import { useEffect, useState } from 'react';
import { FormTypes } from '@property-folders/common/yjs-schema/property/form';
import { CreateSubscriptionFormPane } from '~/components/create-form/CreateSubscriptionFormPane';
import { CreateInPropertyFolderPane } from '~/components/create-form/CreateInPropertyFolderPane';
import { Lookups } from '@property-folders/common/client-api/lookups';
import { AuthApi } from '@property-folders/common/client-api/auth';
import { useOnline } from '@property-folders/components/hooks/useOnline';
import { formCodeToFileNameMap, fileNameToFormCodeMap } from '@property-folders/common/subscription-forms/form-mapping';
import type { FolderDetails } from '@property-folders/contract/rest/folders';

interface CreateFormModalState {
  formId?: number,
  propertyFolderId?: string,
  formCode?: FormCodeUnion,
  tabCount: number,
  formLabel: string
}

export type FormRef =
  | { formId: number }
  | { formCode: FormCodeUnion, propertyFolderId?: string };

export interface CreateFormModalProps {
  form: FormRef;
  folderDetails?: FolderDetails['results'];
  skipConfirmation?: boolean;
  onClose: () => void;
  templateMode?: boolean;
  returnToCurrentPage: boolean;
}

/**
 * If the user selects a form that can only be created as a subscription document, hide the tabs.
 * If the user selects a form that can only be created as a property folder document, hide the tabs.
 * If property folders are disabled, hide the tabs.
 * If a property folder is not selected, Create navigates to the New Property Folder wizard, and after the property folder is created the new document should be created and in view.
 * If a property folder is selected, then Create should create the new document and navigate to it. If the property folder does not support this action at this time, then an error should be shown by default. Cases to consider:
 *   Create Agency Agreement - draft agency agreement already exists - just navigate to it.
 *   Create Variation - bit different on the new stack - would be good to cover this.
 * Auto-complete on the property folder search.
 *
 * If opened within the context of a property folder: only show options relevant to the property folder
 * - formId not supported as that is a standalone context
 * - no need to go online to determine label/form id, just load that info from the selected property folder
 *
 * Offline mode
 * - search through offline list of properties only
 * - call getFormRestriction directly for each match
 */
export function CreateFormModal({ onClose, form, folderDetails, skipConfirmation, templateMode, returnToCurrentPage }: CreateFormModalProps) {
  const { data: sessionInfo } = AuthApi.useGetAgentSessionInfo();
  const [errorMessage, setErrorMessage] = useState('');
  const [state, setState] = useState<CreateFormModalState | undefined>(undefined);
  const online = useOnline();

  useEffect(() => {
    if (!sessionInfo) return;
    const propertyFoldersFeatureEnabled = !!sessionInfo.featureFlags?.newPropertyFolders;
    const propertyFolderTemplatesFeatureEnabled = !!sessionInfo.featureFlags?.propertyFolderTemplates;

    const propertyFoldersEnabled = templateMode
      ? propertyFolderTemplatesFeatureEnabled && propertyFoldersFeatureEnabled
      : propertyFoldersFeatureEnabled;

    if ('formCode' in form && FormTypes[form.formCode].label) {
      if (!propertyFoldersEnabled) {
        // very strange state to get into.
        // shouldn't even be able to open this dialog with property folder details if the feature is off.
        setErrorMessage('Property Folders are not enabled.');
        return;
      }

      const formLabel = FormTypes[form.formCode].label;
      if (form.propertyFolderId) {
        setState({
          formCode: form.formCode,
          tabCount: 1,
          formLabel,
          propertyFolderId: form.propertyFolderId
        });
        return;
      }

      const formFileName = online
        ? formCodeToFileNameMap.get(form.formCode)
        : undefined;
      if (!formFileName) {
        if (!propertyFoldersEnabled) {
          setErrorMessage('Could not load form info');
          return;
        }
        setState({
          formCode: form.formCode,
          tabCount: 1,
          formLabel
        });
        return;
      }
      const { ac, results } = Lookups.lookupClassicForms({
        formFileName
      });
      results
        .then(data => {
          const formId = data?.items?.at(0)?.formId;
          const tabCount = (formId ? 1 : 0) + (propertyFoldersEnabled ? 1 : 0);
          if (!tabCount) {
            setErrorMessage('Could not load form info');
            return;
          }
          setState({
            formCode: propertyFoldersEnabled ? form.formCode : undefined,
            tabCount,
            formLabel,
            formId
          });
        })
        .catch(err => { console.error(err); setErrorMessage('Could not load form info');});

      return () => {
        ac.abort();
      };
    }

    if (!online) {
      setErrorMessage('Subscription forms cannot be created whilst offline');
      setState(undefined);
      return;
    }
    // lookup by form id, then map to property folders form code
    const { ac, results } = Lookups.lookupClassicForms({
      formId: form.formId
    });
    results
      .then(data => {
        if (!data?.items?.length) {
          setErrorMessage('Could not load form info');
          return;
        }

        const formItem = data.items[0];
        const maybeFormCode = propertyFoldersEnabled
          ? fileNameToFormCodeMap.get(formItem.formFileName)
          : undefined;
        const formCode = (maybeFormCode && FormTypes[maybeFormCode]?.label) ? maybeFormCode : undefined;
        setState({
          formId: formItem.formId,
          formCode,
          formLabel: formItem.name,
          tabCount: formCode ? 2 : 1
        });
      })
      .catch(err => { console.error(err); setErrorMessage('Could not load form info');});
    return () => {
      ac.abort();
    };
  }, [
    'formId' in form ? form.formId : form.formCode,
    !!sessionInfo,
    'propertyFolderId' in form ? form.propertyFolderId : undefined,
    online
  ]);

  return <Modal size='lg' show={true} onHide={onClose} className='create-form-modal'>
    {state?.formLabel && <Modal.Header closeButton><h4>Create {state.formLabel}</h4></Modal.Header>}
    <Modal.Body>
      {state?.formId && folderDetails && <p>This document will be added to <strong>{folderDetails.folderName}</strong>.</p>}
      {state?.tabCount
        ? state.tabCount === 1
          ? <>
            {state.formCode && <CreateInPropertyFolderPane formCode={state.formCode} propertyId={state.propertyFolderId} onClose={onClose} skipConfirmation={skipConfirmation} templateMode={templateMode} />}
            {state.formId && <CreateSubscriptionFormPane formId={state.formId} folderId={folderDetails?.folderId} entityId={folderDetails?.entityId} onClose={onClose} templateMode={templateMode} returnToCurrentPage={returnToCurrentPage} />}
          </>
          : <Tabs defaultActiveKey='pf' className='mb-3'>
            {state.formCode && <Tab eventKey='pf' title={<h5>Property Folder</h5>}>
              <CreateInPropertyFolderPane formCode={state.formCode} propertyId={state.propertyFolderId} onClose={onClose} templateMode={templateMode} />
            </Tab>}
            {state.formId && <Tab eventKey='legacy' title={<h5>Subscription Form</h5>}>
              <CreateSubscriptionFormPane formId={state.formId} folderId={folderDetails?.folderId} onClose={onClose} templateMode={templateMode} returnToCurrentPage={returnToCurrentPage} />
            </Tab>}
          </Tabs>
        : errorMessage
          ? <Alert variant='danger'>{errorMessage}</Alert>
          : <div className='w-100 h-100 d-flex flex-row justify-content-center align-items-center py-5'><Spinner
            animation='border'
            style={{
              width: '6rem',
              height: '6rem',
              marginInline: 'auto'
            }}/></div>}
    </Modal.Body>
  </Modal>;
}
