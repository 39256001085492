import { useReactRouterData } from '@property-folders/components/hooks/useReactRouterHooks';
import { AuthApi } from '@property-folders/common/client-api/auth';
import { Navigate, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { useImmerYjs } from '@property-folders/components/hooks/useImmerYjs';
import {
  FormOrderType,
  FormSigningState,
  MaterialisedPropertyData,
  TransactionMetaData
} from '@property-folders/contract';
import { FolderType, FormCode, PropertyRootKey } from '@property-folders/contract/yjs-schema/property';
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { ShortId } from '@property-folders/common/util/url';
import { FormTypes, PropertyFormYjsDal } from '@property-folders/common/yjs-schema/property/form';
import { useStore } from 'react-redux';
import { FormContextType } from '@property-folders/common/types/FormContextType';
import { FormContext, FormContextDefaultValue } from '@property-folders/components/context/FormContext';
import { propertyFolder } from '@property-folders/contract/yjs-schema/model/field';
import { addMissingSigningParties, FormUtil, generateInitiator } from '@property-folders/common/util/form';
import { ContentTitler } from '@property-folders/components/dragged-components/ContentTitler';
import * as Y from 'yjs';
import { IndexeddbPersistence } from 'y-indexeddb';
import { Awareness } from 'y-protocols/awareness';
import { ConfigureSubscriptionFolderSigning } from '~/pages/ConfigureSubscriptionFolderSigning';
import { useSigningNavProps } from '@property-folders/components/hooks/useSigningNavProps';
import { SubscriptionFormOutForSigning } from '~/components/SubscriptionFormOutForSigning';
import { cancelSigning } from '@property-folders/components/subscription-forms/cancelSigning';
import { AjaxPhp } from '@property-folders/common/util/ajaxPhp';
import {
  ExpandSigningPlacementStrategy,
  FillContiguousSpaceSigningPlacementStrategy,
  generateHeadlineFromMaterialisedData
} from '@property-folders/common/yjs-schema/property';
import { AsyncButton } from '@property-folders/components/dragged-components/AsyncButton';
import { useContentPreviewAllotmentState } from '@property-folders/components/hooks/useContentPreviewAllotmentState';
import { Button } from 'react-bootstrap';
import {
  prepareForCustomisingSubscriptionForm,
  prepareForSigningSubscriptionForm
} from '@property-folders/components/dragged-components/Wizard/prepareForSigningHandlers';
import { useEntities } from '@property-folders/components/hooks/useEntity';
import { YManagerContext } from '@property-folders/components/context/YManagerContext';
import { FileSyncContext } from '@property-folders/components/context/fileSyncContext';
import { LinkBuilder } from '@property-folders/common/util/LinkBuilder';
import { UserPreferencesRootKey } from '@property-folders/contract/yjs-schema/user-preferences';
import { applyMigrationsV2_1 } from '@property-folders/common/yjs-schema';
import { canonicalisers, companyTradingAs } from '@property-folders/common/util/formatting';
import { v4 } from 'uuid';
import { useLightweightTransaction } from '@property-folders/components/hooks/useTransactionField';
import { getOtherContactsForSubscriptionForm } from '~/pages/GenerateSubscriptionFolderSigningSession';

interface RouterData {
  transId: string,
  ydoc: Y.Doc,
  localProvider: IndexeddbPersistence,
  ydocStats: Y.Doc,
  localProviderStats: IndexeddbPersistence,
  awareness: Awareness,
}

export function SubscriptionFormDocument() {
  const { transId, ydoc } = useReactRouterData<RouterData>();
  const propertyId = ShortId.toUuid(transId);
  const { data: sessionInfo } = AuthApi.useGetAgentSessionInfo();
  const { documentIdRaw } = useParams();
  const { binder: metaBinder } = useImmerYjs<TransactionMetaData>(ydoc, PropertyRootKey.Meta);
  const { binder: dataBinder } = useImmerYjs<MaterialisedPropertyData>(ydoc, PropertyRootKey.Data);
  const [formCode, setFormCode] = useState('');
  const [pendingCancellation, setPendingCancellation] = useState(false);
  const { value: meta } = useLightweightTransaction({ myPath: '', bindToMetaKey: true });
  const { value: data } = useLightweightTransaction({ myPath: '' });
  const headline = generateHeadlineFromMaterialisedData(data);
  const [ searchParams ] = useSearchParams();

  // control allotment pane in child signing session. Hoisted because in another location,
  // the preview button was unusable
  const { allotmentPaneMode, setAllotmentPaneMode, onVisibleChange } = useContentPreviewAllotmentState();

  const formId = ShortId.toUuid(documentIdRaw);
  const formInstance = formCode
    ? PropertyFormYjsDal.getFormInstanceFromState(formCode, formId, meta || {})
    : PropertyFormYjsDal.searchFormInstanceByIdFromState(formId, meta || {});
  const store = useStore();
  const { form, signing, signingState } = (() => {
    const form = FormUtil.getFormState(formCode, formId, meta);
    return {
      form,
      signingState: form?.signing?.state || FormSigningState.None,
      signing: form?.signing,
      order: form?.order
    };
  })();
  const navigate = useNavigate();
  const memberEntities = useEntities();
  const localEntity = memberEntities && meta?.entity?.id ? memberEntities?.[meta.entity.id] : null;
  const { instance: fileSync } = useContext(FileSyncContext);
  const { instance: yManagerInstance } = useContext(YManagerContext);
  const getCurrentUserPrefs = useCallback(() => yManagerInstance?.getUserPrefs()?.doc.getMap(UserPreferencesRootKey.Main).toJSON(), [yManagerInstance]);

  const returnPath = searchParams.get('returnPath');
  const returnPathQuery = returnPath ? `&ReturnPath=${encodeURIComponent(returnPath)}` : '';

  const {
    showConfiguration,
    showSigningSession
  } = useSigningNavProps({ signing, formCode });

  const formContext = useMemo<FormContextType>(() => {
    return {
      ...FormContextDefaultValue,
      formName: formInstance?.formCode || '',
      transactionRules: propertyFolder,
      wizardTitle: headline,
      formId,
      authRepMode: formInstance?.order?.type === FormOrderType.Filler
    };
  }, [formInstance, formId]);

  useEffect(() => {
    if (signingState !== FormSigningState.None || !sessionInfo || !localEntity || pendingCancellation || !formInstance || !formCode) {
      return;
    }

    const ac = new AbortController();

    const documentId = (meta as any).documentId;
    if (!documentId) {
      return;
    }

    AjaxPhp.getDocumentMeta({ documentId, signal: ac.signal }).then(documentMeta => {
      const { entityId, signatures, name: documentName, signatureMetadata } = documentMeta;

      applyMigrationsV2_1<MaterialisedPropertyData>({
        doc: ydoc,
        docKey: PropertyRootKey.Data.toString(),
        typeName: 'Property',
        migrations: [
          {
            name: 'update contacts',
            fn: (state: any) => {
              const currentSalesperson = localEntity?.salespeople?.find(s => s.id === sessionInfo.agentId);
              const agent = {
                id: sessionInfo.agentUuid,
                phone: (canonicalisers.phone(currentSalesperson?.phone || '').canonical as string) || '',
                email: (canonicalisers.email(sessionInfo.email || '').canonical as string) || '',
                name: sessionInfo.name,
                linkedSalespersonId: sessionInfo.agentId
              };

              (state as MaterialisedPropertyData).id = transId;
              (state as MaterialisedPropertyData).headline = documentName;
              (state as MaterialisedPropertyData).agent = [{
                company: companyTradingAs(localEntity.name, localEntity.tradeName),
                address: localEntity.address1,
                phone: localEntity.phone,
                email: localEntity.email,
                abn: (canonicalisers.abnacn(localEntity.abn ?? '').canonical as string) ?? '',
                rla: localEntity.rla ?? '',
                linkedEntityId: entityId,
                profileName: localEntity.profileName,
                salesp: [agent],
                id: v4()
              }];
              const originalOtherContacts = [...(state as MaterialisedPropertyData).otherContacts ?? []];
              (state as MaterialisedPropertyData).otherContacts = getOtherContactsForSubscriptionForm({
                signatures,
                signatureMetadata,
                originalOtherContacts,
                agent
              });
            }
          }
        ]
      });

      const dal = new PropertyFormYjsDal(ydoc, PropertyRootKey.Data, PropertyRootKey.Meta);
      FormUtil.transitionSigningState({
        formCode,
        formId,
        metaBinder: dal.metaBinder,
        dataBinder: dal.dataBinder,
        sessionInfo,
        store,
        entitySigningOpts: localEntity.signingOptions
      }, {
        to: FormSigningState.Configuring
      });

      const data = dal.dataBinder.get();
      dal.metaBinder?.update(draft => {
        const instance = draft.formStates?.[formCode].instances?.find(i => i.id === formId);
        if (!instance) {
          return;
        }

        const otherContacts = FormUtil.getPartySigningSources(data, 'otherContacts', { type: 'other' }, true);
        addMissingSigningParties(
          instance,
          (otherContacts ?? []).filter(s => s.originalType !== s._optimisation?.name)
        );
      });
    });

    return () => {
      ac.abort();
    };
  }, [signingState, meta, formInstance, formCode]);

  const cancelSigningHandler = useCallback(() => {
    setPendingCancellation(true);
    const documentId = formInstance?.subscription?.documentId as number;
    return AjaxPhp.updateSubscriptionFolderStatus({
      documentId,
      status: FormSigningState.None
    }).then(() => {
      cancelSigning({
        formId,
        formCode,
        sessionInfo,
        metaBinder,
        dataBinder,
        store
      });

      // should automatically navigate, if we were to manually navigate ydoc changes might not be transmitted
    });
  }, [formCode, formId, sessionInfo, metaBinder, dataBinder, store]);

  const backToGeneralScreenHandler = () => {
    metaBinder?.update(draft => {
      const signing = FormUtil.getSigning(formCode, formId, draft);
      if (signing?.state !== FormSigningState.Configuring) return;

      delete signing.customiseScreen;
    });
  };

  const prepareForSigningHandler = async (customised?: boolean) => {
    const {
      placementSettings,
      placementStrat,
      initialPlacementStrat
    } = await getDefaultPlacementStrategies(customised ? undefined : formInstance?.subscription?.documentId);

    return prepareForSigningSubscriptionForm({
      // No sublineages on subscription forms
      dal: new PropertyFormYjsDal(ydoc, PropertyRootKey.Data, PropertyRootKey.Meta),
      ydoc,
      formId,
      formCode,
      sessionInfo,
      initiator: generateInitiator(meta, sessionInfo, localEntity),
      store,
      fileSync,
      getUserPrefsData: getCurrentUserPrefs,
      memberEntities,
      overrideFormDefinition: {
        ...FormTypes[formInstance?.formCode ?? FormCode.UploadedDocument],
        subscription: {
          signing: {
            useGroups: false,
            ignoreBasePlacement: true,
            placementStrategies: {
              default: placementStrat,
              default_initials: initialPlacementStrat
            }
          }
        },
        debug: placementSettings?.debug
      },
      customised
    }).then(async () => {
      await AjaxPhp.updateSubscriptionFolderStatus({
        documentId: formInstance?.subscription?.documentId as number,
        status: FormSigningState.OutForSigning
      });

      navigate(LinkBuilder.documentPath(
        { id: propertyId, nicetext: '' },
        { id: formId, nicetext: '' },
        { isSubscriptionForm: true, folderType: FolderType.Document }
      ));
    });
  };

  const prepareForCustomisingHandler = async () => {
    const {
      placementSettings,
      placementStrat,
      initialPlacementStrat
    } = await getDefaultPlacementStrategies(formInstance?.subscription?.documentId);

    await prepareForCustomisingSubscriptionForm({
      // No sublineages on subscription forms
      dal: new PropertyFormYjsDal(ydoc, PropertyRootKey.Data, PropertyRootKey.Meta),
      ydoc,
      formId,
      formCode,
      sessionInfo,
      initiator: generateInitiator(meta, sessionInfo, localEntity),
      store,
      fileSync,
      getUserPrefsData: getCurrentUserPrefs,
      memberEntities,
      overrideFormDefinition: {
        ...FormTypes[formInstance?.formCode ?? FormCode.UploadedDocument],
        subscription: {
          signing: {
            useGroups: false,
            ignoreBasePlacement: true,
            placementStrategies: {
              default: placementStrat,
              default_initials: initialPlacementStrat
            }
          }
        },
        debug: placementSettings?.debug
      }
    });
  };

  useEffect(() => {
    if (!formInstance?.formCode) return;

    setFormCode(formInstance.formCode);
  }, [formInstance?.formCode]);

  if (!formCode || !form) {
    return <></>;
  }

  const afterTitle = <>
    {showConfiguration &&
      <AsyncButton variant='outline-secondary' onClick={cancelSigningHandler}>Cancel Signing</AsyncButton>}
    {showConfiguration && signingState === FormSigningState.Configuring && signing?.customiseScreen === 'fields' &&
      <Button
        variant='outline-secondary'
        onClick={() => backToGeneralScreenHandler()}
      >Previous</Button>}
    {showConfiguration && signingState === FormSigningState.Configuring && signing?.customiseScreen === 'fields' &&
      <AsyncButton
        onClick={() => prepareForSigningHandler(true)}
        processingLabel={'Preparing document'}
      >Submit for Signing</AsyncButton>}
  </>;

  return <FormContext.Provider value={formContext}>
    <ContentTitler
      breadcrumbs={[]}
      title={formContext.wizardTitle}
      titleBadge={<></>}
      afterBreadcrumbs={<></>}
      afterTitle={afterTitle}
      flex={true}
    >
      {showConfiguration && <ConfigureSubscriptionFolderSigning
        prepareForSigningHandler={prepareForSigningHandler}
        prepareForCustomisingHandler={prepareForCustomisingHandler}
      />}
      {showSigningSession && <SubscriptionFormOutForSigning
        yDoc={ydoc}
        formInstance={form}
        onCancel={cancelSigningHandler}
        allotmentPaneMode={allotmentPaneMode}
        setAllotmentPaneMode={setAllotmentPaneMode}
        onVisibleChange={onVisibleChange}
      />}
      {!showConfiguration && !showSigningSession && pendingCancellation &&
        <Navigate to={`/forms.php?DocumentID=${formInstance?.subscription?.documentId}${returnPathQuery}`} />}
    </ContentTitler>
  </FormContext.Provider>;
}

async function getDefaultPlacementStrategies(documentId?: number) {
  let placementSettings: any = { placement: {}, initialPlacement: {} };

  if (documentId) {
    const documentMeta = await AjaxPhp.getDocumentMeta({ documentId });
    if (documentMeta.signingPortal.settings) {
      placementSettings = documentMeta.signingPortal.settings;
    }
  }

  const placementStrat: ExpandSigningPlacementStrategy | FillContiguousSpaceSigningPlacementStrategy = {
    type: placementSettings.placement?.type || 'fill-contiguous-space',
    expandDown: placementSettings.placement?.expandDown || 10,
    marginRight: placementSettings.placement?.marginRight || 43,
    marginLeft: placementSettings.placement?.marginLeft || 0,
    expandUp: placementSettings.placement?.expandUp || 0,
    signedText: placementSettings.placement?.signedText
      ? {
        size: placementSettings.placement?.signedText?.size || 10,
        width: placementSettings.placement?.signedText?.width || 40,
        offsetX: placementSettings.placement?.signedText?.offsetX || 0,
        offsetY: placementSettings.placement?.signedText?.offsetY || 0
      }
      : undefined,
    expandLeft: placementSettings.placement?.expandLeft || 0,
    expandRight: placementSettings.placement?.expandRight || 0,
    timestampText: placementSettings.placement?.timestampText
      ? {
        x: placementSettings.placement?.timestampText?.x || 0,
        y: placementSettings.placement?.timestampText?.y || 0
      }
      : undefined
  };

  const initialPlacementStrat: ExpandSigningPlacementStrategy | FillContiguousSpaceSigningPlacementStrategy = {
    type: placementSettings.initialPlacement?.type || 'expand',
    expandDown: placementSettings.initialPlacement?.expandDown || 0,
    expandLeft: placementSettings.initialPlacement?.expandLeft || 0,
    expandRight: placementSettings.initialPlacement?.expandRight || 0,
    expandUp: placementSettings.initialPlacement?.expandUp || 0,
    marginRight: placementSettings.placement?.marginRight || 43,
    marginLeft: placementSettings.placement?.marginLeft || 0
  };

  return { placementSettings, placementStrat, initialPlacementStrat };
}
