import { ContentTitler } from '@property-folders/components/dragged-components/ContentTitler';
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { TransactionFormProps } from '@property-folders/common/types/TransactionFormProps';
import { FormContextSetup, FormContextSigningOverride } from '@property-folders/components/form-gen-util/yjsStore';
import { FormContextType } from '@property-folders/common/types/FormContextType';
import {
  ExtraFormCode, FormCode,
  FormCodeUnion,
  FormInstance,
  FormInstanceSigning,
  FormSigningState,
  MaterialisedPropertyData, SignedStates,
  TransactionMetaData
} from '@property-folders/contract';
import { FormTypes, PropertyFormYjsDal } from '@property-folders/common/yjs-schema/property/form';
import { useLightweightTransaction, useTransactionField } from '@property-folders/components/hooks/useTransactionField';
import { FormUtil, generateInitiator } from '@property-folders/common/util/form';
import { YjsDocContext } from '@property-folders/components/context/YjsDocContext';
import { FormContext } from '@property-folders/components/context/FormContext';
import { useSigningNavProps } from '@property-folders/components/hooks/useSigningNavProps';
import {
  SigningFieldsConfiguration
} from '@property-folders/components/dragged-components/signing/fieldConfiguration/SigningFieldsConfiguration';
import { SigningConfiguration } from '@property-folders/components/dragged-components/signing/SigningConfiguration';
import clsJn from '@property-folders/common/util/classNameJoin';
import { Allotment, LayoutPriority } from 'allotment';
import { Alert, Badge, Button, Modal } from 'react-bootstrap';
import { SigningSession } from '@property-folders/components/dragged-components/signing/SigningSession';
import { PropertyRootKey } from '@property-folders/contract/yjs-schema/property';
import {
  AllotmentPaneMode,
  useContentPreviewAllotmentState
} from '@property-folders/components/hooks/useContentPreviewAllotmentState';
import { useImmerYjs } from '@property-folders/components/hooks/useImmerYjs';
import {
  MissingPurchaserFieldsError,
  prepareForSigningCustomPdf
} from '@property-folders/components/dragged-components/Wizard/prepareForSigningHandlers';
import { AuthApi } from '@property-folders/common/client-api/auth';
import { useStore } from 'react-redux';
import { FileSyncContext } from '@property-folders/components/context/fileSyncContext';
import { usePdfPreviewUrl } from '@property-folders/components/dragged-components/subscriptionForms/hooks';
import { SetupPdfLoadStateContext } from '@property-folders/components/context/pdfLoadStateContext';
import { PDFViewer } from '@property-folders/components/dragged-components/PDFViewer/PDFViewer';
import { Icon } from '@property-folders/components/dragged-components/Icon';
import { WizardStepPage } from '@property-folders/components/dragged-components/Wizard/WizardStepPage';
import { WrField } from '@property-folders/components/dragged-components/form/CommonComponentWrappers';
import { boolYesNoOpts } from '@property-folders/common/util/pdfgen/constants';
import { FormDescriptorParty } from '@property-folders/common/yjs-schema/property';
import { useNavigate } from 'react-router-dom';
import { LinkBuilder } from '@property-folders/common/util/LinkBuilder';
import { YManagerContext } from '@property-folders/components/context/YManagerContext';
import { UserPreferencesRootKey } from '@property-folders/contract/yjs-schema/user-preferences';
import { useBrandConfig, useEntities } from '@property-folders/components/hooks/useEntity';
import { ErrorBoundary } from '@property-folders/components/telemetry/ErrorBoundary';
import { FallbackModal } from '@property-folders/components/display/errors/modals';
import { Form1UnarchiveModal } from '~/pages/forms/Form1UnarchiveModal';
import { AsyncButton } from '@property-folders/components/dragged-components/AsyncButton';
import { highlightTemplateForm1Fill } from '@property-folders/common/subscription-forms/highlight-template-form1-fill';
import { applyPreviewWatermark } from '@property-folders/common/subscription-forms/apply-preview-watermark';
import { useYdocBinder } from '@property-folders/components/hooks/useYdocBinder';

const FORM_CODE = ExtraFormCode.Form1Upload;
const FORM_LABEL = FormTypes[FORM_CODE].label;
const printTitle = FormTypes[FORM_CODE].printTitle ?? FormTypes[FORM_CODE].label;
const FORM_CONTEXT: Omit<FormContextType, 'formId'> = {
  formName: FORM_CODE,
  transactionRules: { _type: 'Map' },
  metaRules: { _type: 'Map' },
  printTitle,
  wizardTitle: FormTypes[FORM_CODE].wizardTitle ?? printTitle
};

export function UploadedForm1Page(props: TransactionFormProps) {
  const formId = props.formId;
  const updatedBreadcrumb = useMemo(() => [...(props.breadcrumbs ?? []), {
    label: 'All Documents',
    href: `${props.breadcrumbs[props.breadcrumbs.length - 1]?.href}/documents`
  }], [props.breadcrumbs]);
  return <FormContextSetup formId={formId} base={FORM_CONTEXT}>
    <NavigateOnSigningStateTransition/>
    <UploadedForm1Inner
      breadcrumbs={updatedBreadcrumb}
      formId={formId}
    />
  </FormContextSetup>;
}

export const UploadedForm1Inner = ({ breadcrumbs, entityLogoLoadedUri }: TransactionFormProps): JSX.Element => {
  const { ydoc, transactionRootKey, transactionMetaRootKey, docName: propertyId } = useContext(YjsDocContext);
  const { formId, formName: formCode, wizardTitle } = useContext(FormContext);
  const { instance: fileSync } = useContext(FileSyncContext);

  const { data: sessionInfo } = AuthApi.useGetAgentSessionInfo();
  const store = useStore();

  const { value: form, fullPath: formPath } = useTransactionField<FormInstance>({
    parentPath: FormUtil.getFormPath(formCode as FormCodeUnion, formId),
    bindToMetaKey: true
  });
  const { value: signing } = useLightweightTransaction<FormInstanceSigning>({
    parentPath: FormUtil.getFormPath(formCode, formId),
    myPath: 'signing',
    bindToMetaKey: true
  });

  const { binder: dataBinder } = useImmerYjs<MaterialisedPropertyData>(ydoc, transactionRootKey);
  const {
    bindState: metaBindState,
    binder: metaBinder
  } = useImmerYjs<TransactionMetaData>(ydoc, transactionMetaRootKey);
  const { data: meta } = metaBindState<TransactionMetaData>(m => m);
  const formConfigs = useBrandConfig();
  const brand = formConfigs.getFormConfig(meta?.entity?.id);
  const { pdfLoadErrorMessage, pdfPreviewUrl: previewUrls, regenerateForDownloadPrintUsage } = usePdfPreviewUrl({
    form,
    formCode,
    formId,
    yDoc: ydoc,
    brand,
    previewPdfTransforms: FormTypes[formCode]?.formFamily === FormCode.Form1 && SignedStates.has(signing?.state) ? [applyPreviewWatermark, highlightTemplateForm1Fill]: []
  });

  const memberEntities = useEntities();
  const localEntity = memberEntities && meta?.entity?.id ? memberEntities?.[meta.entity.id] : null;
  const [showUnArchiveModal, setShowUnArchiveModal] = useState(false);
  const [showMissingPurchaserFieldsModal, setShowMissingPurchaserFieldsModal] = useState(false);
  const [prepareForSigningError, setPrepareForSigningError] = useState('');

  const {
    signingSessionWizardPropsForSidebar,
    showConfiguration,
    showSigningSession,
    partyGroups,
    serveToPurchaserProps,
    signingMainProps
  } = useSigningNavProps({ signing, formCode });

  const {
    onVisibleChange,
    showContentPane,
    showPreviewPane,
    allotmentPaneMode,
    setAllotmentPaneMode,
    splitEnabled
  } = useContentPreviewAllotmentState();

  const setCustomisationScreenHandler = (toScreen: 'general' | 'fields') => {
    metaBinder?.update(draft => {
      const signing = FormUtil.getSigning(formCode, formId, draft);
      if (signing?.state !== FormSigningState.Configuring) return;

      switch (toScreen) {
        case 'fields':
          signing.customiseScreen = 'fields';
          FormUtil.clearUnnecessaryCustomFields(signing);
          break;
        case 'general':
          delete signing.customiseScreen;
          break;
      }
    });
  };

  const { instance: yManagerInstance } = useContext(YManagerContext);
  const getCurrentUserPrefs = useCallback(()=>yManagerInstance?.getUserPrefs()?.doc.getMap(UserPreferencesRootKey.Main).toJSON(), [yManagerInstance]);

  const prepareForSigningHandler = async () => {
    if (!ydoc) return;
    if (!meta) return;
    if (!memberEntities) return;

    setPrepareForSigningError('');
    try {
      const { error } = await prepareForSigningCustomPdf({
        formId,
        formCode: formCode as FormCodeUnion,
        sessionInfo,
        store,
        fileSync,
        dal: new PropertyFormYjsDal(ydoc, transactionRootKey, transactionMetaRootKey),
        initiator: generateInitiator(meta, sessionInfo, localEntity),
        getUserPrefsData: getCurrentUserPrefs,
        memberEntities
      });

      if (error) {
        switch (error.constructor) {
          case MissingPurchaserFieldsError:
            setShowMissingPurchaserFieldsModal(true);
            break;
          default:
            setPrepareForSigningError(error.message);
            break;
        }
      }
    } catch (err: unknown) {
      console.error(err);
      setPrepareForSigningError('Unexpected error');
    }
  };

  const cancelSigningHandler = () => {
    FormUtil.transitionSigningState({
      store,
      formCode: FORM_CODE,
      formId: formId,
      metaBinder,
      dataBinder,
      sessionInfo
    }, {
      to: FormSigningState.None
    });
  };

  const startSigningConfigHandler = () => {
    const forceParties: FormDescriptorParty[] = [];
    if (form.upload?.unsigned?.vendor === true) forceParties.push({ type: 'vendor', mode: 'primary-only' });
    if (form.upload?.unsigned?.vendor === 'all') forceParties.push({ type: 'vendor' });
    if (form.upload?.unsigned?.agent) forceParties.push({ type: 'agent' });
    FormUtil.transitionSigningState({
      store,
      formCode: FORM_CODE,
      formId: formId,
      metaBinder,
      dataBinder,
      sessionInfo,
      entitySigningOpts: localEntity.signingOptions
    }, {
      to: FormSigningState.Configuring,
      configuringData: {
        skipToCustomFields: !forceParties.length,
        forceParties
      }
    });
  };

  const unarchiveHandler = () => {
    setShowUnArchiveModal(true);
  };

  const signingState = signing?.state || FormSigningState.None;
  const customiseScreen = signing?.customiseScreen;

  const afterTitle = <>
    {form?.archived &&
      <Button  variant='outline-secondary' className='d-none d-md-block' onClick={unarchiveHandler}>Unarchive</Button>}
    {signingState === FormSigningState.None && !form?.archived &&
      <Button variant='primary' className='d-none d-md-block' onClick={startSigningConfigHandler}>Prepare</Button>}
    {signingState === FormSigningState.Configuring &&
      <Button variant='outline-secondary' onClick={cancelSigningHandler}>Cancel Preparation</Button>}
    {signingState === FormSigningState.Configuring && customiseScreen === 'fields' &&
      <AsyncButton onClick={() => prepareForSigningHandler()} processingLabel={'Preparing document'}>Submit</AsyncButton>}
  </>;

  if (!propertyId) {
    return <></>;
  }

  const titleBadge = form.archived ? <Badge bg={'secondary'} style={{ fontSize: 12 }}>archived</Badge> : undefined;

  if (showConfiguration) {
    return <ContentTitler
      breadcrumbs={breadcrumbs}
      title={wizardTitle}
      titleBadge={titleBadge}
      afterBreadcrumbs={<>{prepareForSigningError && <span className='text-danger'>{prepareForSigningError}</span>}</>}
      afterTitle={afterTitle}
      flex={true}
    >
      {showMissingPurchaserFieldsModal && <Modal show={true}>
        <Modal.Header>
          <Modal.Title>Form 1 template is missing fields</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>You have not assigned all of the template fields to your Form 1 template.</p>
          <p>Please drag the Purchaser Name, Purchaser Address and Contract Date fields onto your form so that Greatforms can fill them with the purchaser details when serving this document.</p>
          <Alert variant='info'>
            If the purchaser details are already in this document, please re-upload without them.
          </Alert>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={() => setShowMissingPurchaserFieldsModal(false)}>Dismiss</Button>
        </Modal.Footer>
      </Modal>}
      <FormContextSigningOverride>
        {signing?.customiseScreen === 'fields'
          ? <SigningFieldsConfiguration
            propertyId={propertyId}
            formCode={formCode}
            formId={formId}
            entityLogoLoadedUri={entityLogoLoadedUri}
            ydoc={ydoc}
            yRootKey={transactionRootKey}
            yMetaRootKey={transactionMetaRootKey}
            prepareForSigning={prepareForSigningHandler}
            prepareForCustomising={() => setCustomisationScreenHandler('fields')}
          />
          : <SigningConfiguration
            propertyId={propertyId}
            formCode={formCode}
            formId={formId}
            entityLogoLoadedUri={entityLogoLoadedUri}
            ydoc={ydoc}
            yRootKey={transactionRootKey}
            yMetaRootKey={transactionMetaRootKey}
            prepareForSigning={prepareForSigningHandler}
            prepareForCustomising={() => setCustomisationScreenHandler('fields')}
          />}
      </FormContextSigningOverride>
    </ContentTitler>;
  }

  return (
    <div className={clsJn('alot-container position-relative h-100 w-100 d-flex')}>
      {showUnArchiveModal && <ErrorBoundary fallbackRender={fallback=><FallbackModal {...fallback} show={showUnArchiveModal} onClose={() => setShowUnArchiveModal(false)} />}>
        <Form1UnarchiveModal onClose={() => setShowUnArchiveModal(false)} />
      </ErrorBoundary>}
      <Allotment snap onVisibleChange={onVisibleChange}>
        <Allotment.Pane minSize={300} preferredSize={760} priority={LayoutPriority.High} visible={showContentPane}>
          <ContentTitler
            title={wizardTitle}
            titleBadge={titleBadge}
            flex={true}
            scroll={true}
            afterTitle={afterTitle}
            breadcrumbs={breadcrumbs}
            afterBreadcrumbs={allotmentPaneMode !== AllotmentPaneMode.Both
              ? <Button
                variant='link'
                title='Preview'
                size='sm'
                className='px-0 py-0 border-0 bg-transparent link-secondary'
                onClick={() => splitEnabled
                  ? setAllotmentPaneMode(AllotmentPaneMode.Both)
                  : setAllotmentPaneMode(AllotmentPaneMode.Preview)}
              >
                Preview
              </Button>
              : <></>}
          >
            {showSigningSession
              ? <SigningSession
                hideCompleteIfNoParties={true}
                formCode={formCode}
                formId={formId}
                ydoc={ydoc}
                onVoid={cancelSigningHandler}
                wizardSectionProps={signingSessionWizardPropsForSidebar}
                dataRootKey={PropertyRootKey.Data}
                metaRootKey={PropertyRootKey.Meta}
                partyGroups={partyGroups}
                serveToPurchaserProps={serveToPurchaserProps}
                signingMainProps={signingMainProps}
              />
              : !form?.archived && <WizardStepPage name='WhoStillNeedsToSign' label='Who Still Needs to Sign?' icon=''>
                <WhoStillNeedsToSignTheForm1Input />
              </WizardStepPage>}
          </ContentTitler>
        </Allotment.Pane>
        <Allotment.Pane visible={showPreviewPane}>
          {<SetupPdfLoadStateContext>
            <PDFViewer
              pdfUrl={previewUrls}
              allowPrint={true}
              filename={form?.upload?.name}
              renderTextLayer={signingState === FormSigningState.None}
              contextDependentLoadingMessage={pdfLoadErrorMessage}
              onDocumentRegenerate={regenerateForDownloadPrintUsage}
              bookmark={''}
              pageWrapElement={({ children, pageIndex, dimensions }) => <div key={`pw_${pageIndex}`}
                className='mt-3 position-relative' style={{
                  width: 'min-content',
                  marginInline: 'auto'
                }}>
                {children}
              </div>}
              toolbarRight={<>
                {allotmentPaneMode !== AllotmentPaneMode.Content
                  ? <Button
                    variant='secondary'
                    onClick={() => {
                      setAllotmentPaneMode(AllotmentPaneMode.Content);
                    }}
                  >
                    <Icon name='close'/>
                  </Button>
                  : undefined}
              </>}
            />
          </SetupPdfLoadStateContext>}
        </Allotment.Pane>
      </Allotment>
    </div>
  );
};

function WhoStillNeedsToSignTheForm1Input() {
  const { formId, formName: formCode } = useContext(FormContext);
  const { value: unsigned, fullPath: unsignedPath, loading, handleUpdate: setUnsigned } = useTransactionField<{
    vendor?: boolean | 'all',
    agent?: boolean
  }>({
    parentPath: FormUtil.getFormPath(formCode as FormCodeUnion, formId),
    myPath: 'upload.unsigned',
    bindToMetaKey: true
  });
  const { updateDraft: updateData } = useYdocBinder<MaterialisedPropertyData>({ path: '' });

  useEffect(() => {
    if (unsigned?.vendor != undefined) {
      updateData?.(state => {
        state.allVendorsSignForm1 = unsigned.vendor === 'all';
      });
    }
  }, [unsigned]);

  useEffect(() => {
    if (loading) return;
    if (unsigned) return;
    setUnsigned({ vendor: false, agent: false }, true);
  }, [Boolean(unsigned), loading]);

  return <>
    <div className='mt-2'>
      <p>This document needs to be prepared for serving to purchasers.</p>
      <p>Preparation involves dragging purchaser fields onto the document so they may be populated with purchaser
        information during the serving process.</p>
      <p>If this document still needs to be signed by some parties, you may specify which ones below.</p>
    </div>
    <div className='mt-2'>
      <WrField.CheckRadio
        radioType='checkbox'
        label='Does the vendor still need to sign this document?'
        name='vendor'
        myPath='vendor'
        parentPath={unsignedPath}
        options={{
          true: 'Yes - primary vendor only',
          'all': 'Yes - all vendors',
          false: 'No'
        }}
        bindToMetaKey={true}
        inline={true}
      />
    </div>
    <div className='mt-2'>
      <WrField.CheckRadio
        radioType='checkbox'
        label='Does the agent or their authorised representative still need to sign this document?'
        name='agent'
        myPath='agent'
        parentPath={unsignedPath}
        valueType='boolean'
        options={boolYesNoOpts}
        bindToMetaKey={true}
        inline={true}
      />
    </div>
  </>;
}

export function NavigateOnSigningStateTransition() {
  const { docName: propertyId } = useContext(YjsDocContext);
  const { formId, formName: formCode } = useContext(FormContext);
  const { value: formInstance } = useLightweightTransaction<FormInstance>({
    parentPath: FormUtil.getFormPath(formCode, formId),
    bindToMetaKey: true
  });
  const [signingState, setSigningState] = useState(formInstance?.signing?.state || FormSigningState.None);
  const navigate = useNavigate();

  useEffect(() => {
    if (!propertyId) return;
    if (!formInstance?.onSignedNavigation) return;
    const newState = formInstance?.signing?.state || FormSigningState.None;
    if (newState === signingState) return;

    setSigningState(newState);
    if (newState === FormSigningState.Signed) {
      navigate(LinkBuilder.documentPath(
        { id: propertyId },
        { id: formInstance.onSignedNavigation.formId },
        false
      ));
    }
  }, [Boolean(formInstance?.onSignedNavigation), formInstance?.signing?.state, propertyId]);

  return <></>;
}
