import { useNavigate } from 'react-router-dom';
import React, { ChangeEvent, useCallback, useContext, useEffect, useLayoutEffect, useRef, useState } from 'react';
import { Button, Card, InputGroup, Modal } from 'react-bootstrap';
import { CrumbDefn } from '@property-folders/common/types/BreadCrumbTypes';
import { ContentTitler } from '@property-folders/components/dragged-components/ContentTitler';
import { Icon } from '@property-folders/components/dragged-components/Icon';
import { useLightweightTransaction } from '@property-folders/components/hooks/useTransactionField';
import { handleNewForm } from '@property-folders/common/util/handleNewForm';
import { FormCode, FormCodeUnion, FormFamilyState, FormInstance, FormStates, MaterialisedPropertyData, SaleAddress, TransactionMetaData } from '@property-folders/contract/property';
import { TransactionNewInner } from './TransactionNew';
import './TransactionEditor.scss';
import clsJn from '@property-folders/common/util/classNameJoin';
import { LinkBuilder, SeoFriendlySlugOptions } from '@property-folders/common/util/LinkBuilder';
import { useImmerYjs } from '@property-folders/components/hooks/useImmerYjs';
import { propertyFolder, propertyMeta } from '@property-folders/contract/yjs-schema/model/field';
import { FormTypeOrderMap, FormTypes, PropertyFormYjsDal } from '@property-folders/common/yjs-schema/property/form';
import { determineFormState, FormDescriptorRecord, FormState, generateHeadlineFromMaterialisedData, unlinkIntegration } from '@property-folders/common/yjs-schema/property';
import { buildSigningTimelines } from '@property-folders/common/util/dataExtract';
import { staticHistoryBuilder, useVariation } from '@property-folders/components/hooks/useVariation';
import { formCompleted } from '@property-folders/common/util/form/formCompleted';
import { byMapperFn } from '@property-folders/common/util/sortComparison';
import { FormInstanceCard } from '@property-folders/components/dragged-components/FormInstanceCard';
import { ExternalPropertyUpdatesCard } from '@property-folders/components/dragged-components/external-review/ExternalPropertyUpdatesCard';
import { FormOrderState } from '@property-folders/contract';
import { applyMigrationsV2 } from '@property-folders/common/yjs-schema';
import { PropertyRootKey, OfferMeta } from '@property-folders/contract/yjs-schema/property';
import { ServeToNewPurchaserModal } from '@property-folders/components/dragged-components/signing/ServeToNewPurchaserModal';
import { EnvelopeApiHooks } from '@property-folders/components/hooks/api/EnvelopeApiHooks';
import { SubscriptionFormCode } from '@property-folders/common/subscription-forms';
import { FormContext } from '@property-folders/components/context/FormContext';
import { YjsDocContext } from '@property-folders/components/context/YjsDocContext';
import { FileSyncContext } from '@property-folders/components/context/fileSyncContext';
import { UpdatesPendingBadge } from '@property-folders/components/display/properties/UpdatesPendingBadge';
import { PropertyBackgroundJobsContext } from '@property-folders/components/context/PropertyBackgroundJobs';
import { buildContractDocumentAndForm } from '@property-folders/components/form-gen-util/buildSubDocument';
import { hideStatesFilter, useAlternateRootKeyContracts } from '@property-folders/components/hooks/useAlternateRootKeyContracts';
import { NOT_SET_STR } from '@property-folders/common/data-and-text/constants';
import { SidebarMetrics } from '@property-folders/components/dragged-components/property/sidebar-metrics';
import { SidebarParties } from '@property-folders/components/display/properties/sidebar-parties';
import { SidebarParticulars } from '@property-folders/components/display/properties/sidebar-particulars';
import { handleNewFormOrder } from '~/pages/TransactionHomePage';
import { ErrorBoundary } from '@property-folders/components/telemetry/ErrorBoundary';
import { GenericCardFallback } from '@property-folders/components/display/errors/card';
import { FormCodeCard } from '@property-folders/components/display/properties/FormCodeCard';
import { useDropzone } from 'react-dropzone';
import { AllDocumentsCard } from '@property-folders/components/dragged-components/AllDocumentsCard';
import { Form1Card } from '@property-folders/components/dragged-components/Form1Card';
import {
  UploadDocumentUtil, UploadFileInfo,
  useUploadedDocumentUploadV2
} from '@property-folders/components/hooks/useUploadedDocumentUpload';
import { useStore } from 'react-redux';
import { AuthApi } from '@property-folders/common/client-api/auth';
import { WithinModal } from '@property-folders/components/context/WithinModal';
import useDetectSticky from '@property-folders/components/hooks/useDetectSticky';
import { useCurrentEntity } from '@property-folders/components/hooks/useEntity';
import { FallbackModal } from '@property-folders/components/display/errors/modals';
import { WithinPropertyUploadFilesModal } from '~/components/WithinPropertyUploadFilesModal';
import { GenericConfirmDialog } from '@property-folders/components/dragged-components/Wizard/GenericConfirmDialog';

type TransactionEditorProps = {
  breadcrumbs: CrumbDefn[]
};

const formName = 'editTrans';
const formRules = {};
const transactionRules = propertyFolder;
const metaRules = propertyMeta;

const EXISTING_AS_FORM_CONTEXT = { formName, formRules, metaRules, transactionRules };

/**
 * Compare form instances for ordering.
 * If they have creation dates, use those.
 * Otherwise, prefer drafts before signeds
 */
export function compareFormInstances(a: FormInstance, b: FormInstance) {
  const aMod = a.modified || a.created;
  const bMod = b.modified || b.created;
  if (aMod && bMod) {
    return bMod - aMod;
  }

  const aState = determineFormState(a);
  const bState = determineFormState(b);

  if (aState === bState) {
    return 0;
  }
  return aState === 'SIGNED'
    ? 1
    : -1;
}

const HIDE_SIDEBAR_KEY = 'individual-property-view-hidden-sidebar';

export const TransactionEditor = ({ breadcrumbs }: TransactionEditorProps) => {
  const navigate = useNavigate();
  const store = useStore();
  const { data: sessionInfo } = AuthApi.useGetAgentSessionInfo();
  const backgroundJobsContext = useContext(PropertyBackgroundJobsContext);
  const { value: transRoot } = useLightweightTransaction<MaterialisedPropertyData>({ myPath: '' });
  const localEntity = useCurrentEntity();

  const variationDataForAgency = useVariation(true, { formCode: FormCode.RSAA_SalesAgencyAgreement, transactionRules: {} });

  const addresses = transRoot?.saleAddrs;
  const titles = transRoot?.saleTitles;
  const vendorList = transRoot?.vendors;
  const purchaserList = transRoot?.purchasers;
  const salesAgents = transRoot?.agent;

  const headline = generateHeadlineFromMaterialisedData(transRoot);
  const { ydoc, docName: propertyId } = useContext(YjsDocContext);
  const { instance: fileSync } = useContext(FileSyncContext);
  const {
    bindState: metaBindState
  } = useImmerYjs<TransactionMetaData>(ydoc, PropertyRootKey.Meta); // The Metadata here refers to the root, this is not in the context of a form and so should not be a sublineage
  const {
    data: formStates
  } = metaBindState<FormStates>(s => s?.formStates || {});
  const {
    data: offers
  } = metaBindState<OfferMeta>(s => s?.offerManagement);

  const [activeModal, setActiveModal] = useState<'all' | 'addresses' | 'vendors' | 'salesAgents' | null>(null);
  const [formFamilyContext, setFormFamilyContext] = useState<string | null>(null);
  const [currentScroll, setCurrentScroll] = useState<number | null>(null);
  const [overflowAmount, setOverflowAmount] = useState<number | null>(null);
  const [hideSidebar, setHideSidebarInner] = useState(!!localStorage.getItem(HIDE_SIDEBAR_KEY));
  const [forceFocusId, setForceFocusId] = useState<string|null>(null);
  const [unlinkIntegrationId, setUnlinkIntegrationId] = useState<string | undefined>('');

  function setHideSidebar(hide: boolean) {
    setHideSidebarInner(hide);
    if (hide) localStorage.setItem(HIDE_SIDEBAR_KEY, 'hide');
    else localStorage.removeItem(HIDE_SIDEBAR_KEY);
  }

  const horizontalScrollRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const elem = horizontalScrollRef.current;
    if (elem && formFamilyContext) {

      const handleScroll = () => {

        setCurrentScroll(elem.scrollLeft);
        setOverflowAmount(elem.scrollWidth - elem.offsetWidth);
      };
      elem.addEventListener('scroll', handleScroll);
      window.addEventListener('resize', handleScroll);

      setCurrentScroll(elem.scrollLeft);
      setOverflowAmount(elem.scrollWidth - elem.offsetWidth);

      return () => {
        elem.removeEventListener('scroll', handleScroll);
        window.removeEventListener('resize', handleScroll);
      };
    } else {
      setCurrentScroll(null);
      setOverflowAmount(null);
    }
  }, [horizontalScrollRef.current, formFamilyContext]);
  EnvelopeApiHooks.useExternalUpdateWatcher(transRoot?.id);

  const formFamiliesAffectedByExpiry = Object.keys(formStates ?? {}).filter(fCode => {
    const primaryForm = FormTypes[fCode];
    return !!primaryForm?.documentExpiryPaths?.duration;
  });

  const expiryAffectedInstances = formFamiliesAffectedByExpiry.map(fCode => formStates?.[fCode].instances ?? []).flat().filter(formCompleted);

  const [expiriesMap, setExpiriesMap] = useState<{ [Property in keyof typeof FormTypes]: Date }>({});

  useEffect(() => {
    Promise.all(formFamiliesAffectedByExpiry.map(async fCode => {
      return { // wrt isSublineage, per comment above, this is scoped to the transaction root, ie not in a sublineage
        [fCode]: buildSigningTimelines(await staticHistoryBuilder(formStates ?? {}, fCode, { isSublineage: false })).latestExpiry
      };
    })).then(fam => {
      setExpiriesMap(Object.assign({}, ...fam));
    });
  }, [expiryAffectedInstances.length]);

  const formTypeEntries = Object.entries(FormTypes);
  type FormTypeEntry = typeof formTypeEntries[number];

  function excludeExpiredFamilyVariations([fCode]: FormTypeEntry) {
    if (!FormTypes[fCode].isVariation) {
      return true;
    }
    const formFamily = FormTypes[fCode].formFamily;
    if (!expiriesMap[formFamily]) {
      return true;
    }
    // Not guard here, we don't want to accidentally filter out stuff unintentionally
    if (expiriesMap[formFamily] < new Date()) {
      return false;
    }
    return true;
  }

  let subSuggestions: string[] | undefined;

  if (formFamilyContext && typeof formStates === 'object') {
    const thisFamily = Object.entries(FormTypes).filter(([_, t]) => !t.primary && t.formFamily === formFamilyContext);
    const incompleteForms = formStates[formFamilyContext]?.instances?.filter(i => {
      const state = determineFormState(i);
      return state === FormState.DRAFT || state === FormState.AWAITING_SIGN || state === FormState.CONFIGURING;
    }).map(i => i.formCode);
    subSuggestions = thisFamily.filter(([fCode]) => !incompleteForms?.includes(fCode)).filter(excludeExpiredFamilyVariations).map(([fcode]) => fcode);
  }

  const getFirstOutOfView = (mode: 'back' | 'forward') => {
    const elem1 = horizontalScrollRef.current?.querySelectorAll('.card');
    const container = horizontalScrollRef.current?.getBoundingClientRect();
    let latestNotInView: Element | null = null;
    if (elem1 && container) {
      let listing = [...elem1];
      if (mode === 'forward') {
        listing = listing.reverse();
      }
      for (const elem of listing) {
        const elemRect = elem.getBoundingClientRect();
        if (mode === 'back') {
          if (elemRect.x - container.x < 0) {
            latestNotInView = elem;
          } else {
            break;
          }
        } else {
          if (elemRect.x + elemRect.width > container.x + container.width) {
            latestNotInView = elem;
          } else {
            break;
          }
        }

      }
    }
    return latestNotInView;
  };

  const navigateToForm = (instanceId: string, formCode: string) => {
    if (!propertyId) return;

    const formType = FormTypes[formCode];
    navigate(LinkBuilder.documentPath(
      {
        id: propertyId,
        nicetext: headline
      },
      {
        id: instanceId,
        nicetext: formType.label
      },
      !!formType.subscription));
  };

  const debugDeleteForm = (instanceId: string, formCode: string) => {
    if (!ydoc) return;
    applyMigrationsV2<TransactionMetaData>({
      typeName: 'Property',
      doc: ydoc,
      docKey: PropertyRootKey.Meta, // Debug function shall not support sublineages
      migrations: [{
        name: 'delete doc instance',
        fn: draft => {
          const family = FormTypes[formCode].formFamily;
          const instances = draft.formStates?.[family]?.instances;
          if (!instances?.length) return;

          const index = instances.findIndex(fi => fi.id === instanceId);
          if (index < 0) return;
          instances.splice(index, 1);
        }
      }]
    });
  };

  const debugReleaseForm = (instanceId: string, formCode: string) => {
    if (!ydoc) return;
    applyMigrationsV2<TransactionMetaData>({
      typeName: 'Property',
      doc: ydoc,
      docKey: PropertyRootKey.Meta, // Debug function shall not support sublineages
      migrations: [{
        name: 'delete doc instance',
        fn: draft => {
          if (!draft) return;
          const instance = PropertyFormYjsDal.getFormInstanceFromState(formCode, instanceId, draft);
          if (!instance) return;

          if (!instance.subscription) {
            instance.subscription = {
              documentId: 600149,
              fileName: SubscriptionFormCode.SAF001V2_Form1
            };
          }
          instance.order = {
            state: FormOrderState.ReturnedToClient,
            job: {
              id: 402,
              preparedAtMs: Date.now(),
              requestedAtMs: Date.now() - 3600000,
              preparedByAgentId: 4300,
              fillerManagedSigning: false
            },
            filler: {
              entityId: 1001055,
              system: 'EPF'
            }
          };
        }
      }]
    });
  };

  const createAndGoToFormOrder = async (formCode: FormCodeUnion) => {
    const newId = await handleNewFormOrder(ydoc, formCode);
    if (!newId) {
      return;
    }
    navigateToForm(newId, formCode);
  };

  // Contract is excluded from this list, create it from contract management. So we shouldn't need
  // the context here
  const createAndGoToForm = async (formCode: FormCodeUnion, opts?: {verbosity?: string}) => {
    const newForm = await handleNewForm(ydoc, formCode as FormCodeUnion, fileSync, undefined, undefined, undefined, opts);
    if (!newForm) {
      return;
    }
    navigateToForm(newForm.formId, formCode);
  };

  const formFamilyContextItems = formFamilyContext && formStates && formStates[formFamilyContext]
    ? [...(formStates[formFamilyContext ?? '']?.instances ?? [])].sort(compareFormInstances)
    : [];

  // This is an ugly method to only trigger rescroll on overflow changes after the list has been
  // rendered. This occurs because the overflow is changed by the addition of the buttons, and what
  // happens is the apparent position will jump back, so that it will not be all the way to the
  // right. This is a double rising edge trigger, so that we don't jump to the right every time we resize
  // the page or rotate from portrait to landscape.
  const [itemChangeOverflowChange, setItemChangeOverflowChange] = useState(false);
  const [rescrollToOverflowCounter, setRescrollToOverflowCounter] = useState(0);
  useEffect(() => {
    setItemChangeOverflowChange(true);
  }, [!!formFamilyContextItems?.length]);

  useEffect(() => {
    if (itemChangeOverflowChange) {
      setRescrollToOverflowCounter(n => n + 1);
      setItemChangeOverflowChange(false);
    }
  }, [overflowAmount]);

  useLayoutEffect(() => {
    // Scroll to most recent card on open
    /* This could be employed instead to target specific cards, rather than just going straight to the right
    const cardList = horizontalScrollRef.current?.querySelectorAll('.card:not(.form-adder)');
    const lastCard = cardList && cardList.length > 0 ? cardList[cardList?.length-1] : undefined;
    lastCard?.scrollIntoView({ behavior: 'auto' });
    */
    horizontalScrollRef.current?.scrollTo({ left: horizontalScrollRef.current?.scrollWidth });
  }, [rescrollToOverflowCounter]);

  const [showServeToNewPurchaserParams, setShowServeToNewPurchaserParams] = useState<{ formCode: string, formId: string } | undefined>(undefined);

  // End rising edge detector for 2 variables

  const addressBuilder = (address: SaleAddress) => {
    return <div key={address.id}>
      <div className='fw-bold'>{address.streetAddr}</div>
      <div>{address.subStateAndPost}</div>
      {localEntity?.integrationEnabled && <div className={'d-flex'}>
        Integration: <div>{address?.integrationId ?
          <>
            <a className={'ms-1'} target="_blank" href={address.integrationUrl}>{address.integrationName}<Icon name={'open_in_new'} style={{ fontSize: '14px', marginLeft: '2px' }}/></a>
            <Icon name='link_off' icoClass={'cursor-pointer'} title={'Disconnect Integration'} style={{ fontSize: '18px', marginLeft: '10px' }} onClick={()=>setUnlinkIntegrationId(address.integrationId)} />
          </>
          : <div className={'ms-1'}>None</div>
        }</div>
      </div>}
    </div>;
  };

  const unboundTitles = Array.isArray(titles) && titles.length > 0 && titles.filter(t => !(t.linkedAddresses && Array.isArray(t.linkedAddresses) && t.linkedAddresses.length > 0));

  const propertySeoOptions: SeoFriendlySlugOptions = {
    id: propertyId ?? '',
    nicetext: headline
  };

  const afterBreadcrumbs = <InputGroup size={'sm'}>
    <Button variant={'link'} title='View history' size={'sm'}
      className='px-0 py-0 border-0 bg-transparent link-secondary' onClick={() => navigate('audit')}><Icon
        name='history' /></Button>

    <Button variant={'link'} title='Show Sidebar' size={'sm'}
      style={{ display: 'none' }}
      className={clsJn({ 'px-0 py-0 border-0 bg-transparent link-secondary ms-4': true, 'd-xl-block': hideSidebar })}
      onClick={() => setHideSidebar(false)}><Icon name='view_sidebar' /></Button>
  </InputGroup>;

  const afterTitle = <UpdatesPendingBadge propertyId={propertyId} headline={headline}
    show={backgroundJobsContext.envelopeChangesAvailable} />;

  const families = formTypeEntries
    .filter(([code, desc]) => desc.formFamily === code)
    .filter(([code]) => ![
      FormCode.LicenceToOccupy,
      SubscriptionFormCode.SACS015_LicenceToOccupy,
      FormCode.OfferToPurchase,
      FormCode.StorySheet,
      FormCode.VendorQuestionnaire,
      FormCode.UploadedDocument
    ].includes(code));
  let vqIsBeingPrepared = false;
  if (formStates && (formStates[FormCode.Form1]?.instances?.length ?? 0) > 0) {
    vqIsBeingPrepared = formStates[FormCode.Form1].instances?.[0]?.order?.info?.whoCompletesVq === 'EckermannPropertyForms' || false;
  }

  const contracts = useAlternateRootKeyContracts({ ydoc }).filter(hideStatesFilter);

  const handleContractCreate = useCallback(()=>{
    if (!ydoc) return;
    buildContractDocumentAndForm(ydoc, fileSync).then(res=>{
      const { formId, formCode } = res ?? {};
      if (!formId || !ydoc || !propertyId) return;
      const headline = generateHeadlineFromMaterialisedData(ydoc.getMap(PropertyRootKey.Data).toJSON()); // Intended from parent document
      navigate(LinkBuilder.documentPath({ id: propertyId, nicetext: headline }, { id: formId, nicetext: FormTypes[formCode].label }, false));
    });
  }, [!!ydoc]);

  const familiesMerged: [FormCodeUnion, FormDescriptorRecord, FormFamilyState | undefined][] = families.map(([formCode, formDefinition]) => {
    // basically try to replace this form definition with a suitable alternative if it exists
    // i.e. we want to use legacy forms in place of their 'coming soon' smart form implementations.
    const origFormCode = formCode;
    if (formDefinition.renderOpts?.notYetImplemented) {
      const alternatives = formTypeEntries
        .filter(([candidateFormCode, candidateFormDefinition]) => formCode !== candidateFormCode
          && candidateFormDefinition.formFamily === formDefinition.formFamily
          && !candidateFormDefinition.renderOpts?.notYetImplemented);

      if (alternatives.length === 1) {
        formCode = alternatives[0][0];
        formDefinition = alternatives[0][1];
      }
    }

    return [formCode as FormCodeUnion, formDefinition, formStates?.[origFormCode]];
  });

  const onForceFocus = useCallback((propertyId: string, forceMe: boolean) => {
    setForceFocusId(ffi => forceMe
      ? propertyId
      : ffi === propertyId
        ? null
        : ffi);
  }, []);

  const uploadInput = useRef<HTMLInputElement | null>(null);
  const [showPropertyUploadModal, setShowPropertyUploadModal] = useState<boolean>(false);
  const [uploadFileInfos, setUploadFileInfos] = useState<UploadFileInfo[]>([]);
  const onQueueProcessed = useCallback((files: File[]) => {
    // future: probably include errors in params so user gets feedback
    if (!files.length) return;
    if (!sessionInfo) return;
    if (!fileSync) return;
    if (!ydoc) return;
    if (!propertyId) return;
    const fileInfos = files.map<UploadFileInfo>(f => ({ name: f.name, file: f }));
    if (fileInfos.length === 1) {
      // just do it and navigate!
      UploadDocumentUtil.addToPropertyFolder({
        propertyId,
        doc: ydoc,
        fileSync,
        sessionInfo,
        singleEnvelope: true,
        fileInfos
      })
        .then(added => {
          navigate(`/properties/${LinkBuilder.seoFriendlySlug(propertyId, headline)}/document/${LinkBuilder.seoFriendlySlug(added[0].formId, added[0].name)}`);
        })
        .catch(console.error)
        .finally(() => console.log('finally'));
    } else {
      setUploadFileInfos(fileInfos);
      setShowPropertyUploadModal(true);
    }
  }, [propertyId, ydoc, !!sessionInfo, fileSync]);
  const { handleDrop, hasFiles } = useUploadedDocumentUploadV2({ onFilesPreProcessed: onQueueProcessed });

  const handleUpload = (event: ChangeEvent<HTMLInputElement>) => {
    event.target.files && handleDrop([...event.target.files]);
  };

  const { getRootProps, getInputProps, isDragAccept } = useDropzone({
    onDrop: (files) => handleDrop(files),
    noClick: true,
    accept: { 'application/pdf': [] }
  });

  const [footerIsStuck, footerRef] = useDetectSticky(undefined, undefined, true);

  return <div className={clsJn('d-flex w-100 h-100 special-modal-container-outer', isDragAccept && 'drop-target drop-accept', hasFiles && 'is-uploaded')} {...getRootProps()} >
    {isDragAccept && <div className={'upload-overlay'}>Drop files to add to Property Folder</div>}
    <input {...getInputProps()} ref={uploadInput} className={'d-none'} accept={'.pdf'} onChange={handleUpload}/>
    {showServeToNewPurchaserParams && <ServeToNewPurchaserModal
      formId={showServeToNewPurchaserParams.formId}
      formCode={showServeToNewPurchaserParams.formCode}
      onClose={() => setShowServeToNewPurchaserParams(undefined)}
    />}
    {showPropertyUploadModal && <ErrorBoundary fallbackRender={fallback =>
      <FallbackModal
        {...fallback}
        onClose={() => setShowPropertyUploadModal(false)}
        show={showPropertyUploadModal}
      />
    }>
      <WithinPropertyUploadFilesModal
        onClose={() => setShowPropertyUploadModal(false)}
        fileInfos={uploadFileInfos}
      />
    </ErrorBoundary>}
    <ContentTitler
      breadcrumbs={breadcrumbs}
      title={headline}
      afterTitle={afterTitle}
      className="overflow-auto"
      afterBreadcrumbs={afterBreadcrumbs}
      flex={true}
      scroll={true}
      narrowWholePageScroll={true}
    >
      <div className='card-container mb-3'>
        {familiesMerged
          .sort(([aFormCode], [bFormCode]) => {
            return byMapperFn<FormCodeUnion>((fc) => FormTypeOrderMap.get(fc))(aFormCode, bFormCode);
          })
          .map(([formCode, formDefinition, formFamilyState]) => {
            if (formCode === FormCode.RSC_ContractOfSale) {
              // Handled by Contract Management screen
              return <React.Fragment key={formCode} />;
            }

            if (formCode === FormCode.AllDocuments) {
              return <AllDocumentsCard
                key={formCode}
                renderOpts={formDefinition.renderOpts}
                onForceFocus={(forceMe)=>onForceFocus(formCode, forceMe)}
                forceFocus={forceFocusId ? forceFocusId === formCode : undefined}
                onUpload={()=>uploadInput?.current?.click()}
                headline={headline}
              />;
            }

            if (formCode === FormCode.Form1) {
              return <Form1Card
                key={formCode}
                fileSync={fileSync}
                headline={headline}
                propertyId={propertyId}
                sessionInfo={sessionInfo}
                store={store}
                ydoc={ydoc}
                setShowServeToNewPurchaserParams={setShowServeToNewPurchaserParams}
              />;
            }

            const preventForm1Create = formDefinition.formFamily === FormCode.Form1 &&
              transRoot?.form1AndSearches?.whoSearches === 'eckermanns' &&
              formDefinition.orderable &&
              !formDefinition.navigateTo;
            return <ErrorBoundary
              key={formCode}
              fallbackRender={props => <GenericCardFallback {...props} cardTitle={FormTypes[formCode]?.label} key={formCode}/>}>
              <FormCodeCard
                contracts={contracts}
                createAndGoToForm={createAndGoToForm}
                createAndGoToFormOrder={createAndGoToFormOrder}
                debugDeleteForm={debugDeleteForm}
                debugReleaseForm={debugReleaseForm}
                excludeExpiredFamilyVariations={excludeExpiredFamilyVariations}
                forceFocusId={forceFocusId}
                formCode={formCode}
                formDefinition={formDefinition}
                formFamilyState={formFamilyState}
                formStates={formStates}
                handleContractCreate={handleContractCreate}
                headline={headline}
                navigateToForm={navigateToForm}
                onForceFocus={onForceFocus}
                propertyId={propertyId}
                setFormFamilyContext={setFormFamilyContext}
                setShowServeToNewPurchaserParams={setShowServeToNewPurchaserParams}
                vqIsBeingPrepared={vqIsBeingPrepared}
                preventForm1Create={preventForm1Create}
                ydoc={ydoc}
                store={store}
                sessionInfo={sessionInfo}
                fileSync={fileSync}
              />
            </ErrorBoundary>;
          })
        }
      </div>
    </ContentTitler>
    <div className={clsJn({ 'h-100 editor-side': true, 'd-none': hideSidebar })}>
      <ExternalPropertyUpdatesCard
        show={backgroundJobsContext.envelopeChangesAvailable}
        onReviewClick={() => navigate(LinkBuilder.externalReviewPath(propertySeoOptions))}
        className='p-3 m-2 d-none d-sm-block'
      />

      <Card className="p-3 m-2">
        <div className='fw-bold'>PROPERTY
          <div className='float-end'>
            <Button title='Edit' className='px-0 py-0'
              onClick={() => setActiveModal('all')} variant='link'>
              <Icon name='edit' icoClass={'fs-5'} />
            </Button>
            <Button title='Hide sidebar' className='px-0 py-0'
              onClick={() => setHideSidebar(true)} variant='link'>
              <Icon name='close' />
            </Button>
          </div>
        </div>
        <div className='summary-card-body'>
          {(Array.isArray(addresses) && addresses.length > 0 && addresses.map((a, aIdx) => addressBuilder(a))) || NOT_SET_STR}
          {unboundTitles && unboundTitles.length > 0 && <div>
            <div className='fw-bold'>Unassociated Titles</div>
            <ul className='my-0'>
              {unboundTitles.map(t => <li key={t.id}>{t.title}</li>)}
            </ul>
          </div>}
        </div>

      </Card>

      <SidebarParties hasIntegration={localEntity?.integrationEnabled} agent={salesAgents} purchasers={purchaserList} vendors={vendorList} primaryVendorId={transRoot?.primaryVendor} propertyAddress={transRoot?.saleAddrs?.[0]}/>
      <SidebarParticulars transRoot={transRoot} saaVariationSnapshot={variationDataForAgency} />
      <SidebarMetrics offerData={offers} headline={headline} propertyId={propertyId}/>

      {unlinkIntegrationId && ydoc && <GenericConfirmDialog
        onContinue={()=> {
          unlinkIntegration(ydoc, unlinkIntegrationId);
          setUnlinkIntegrationId('');
        }}
        onCancel={()=>setUnlinkIntegrationId('')}
        title={`Disconnect the ${transRoot?.saleAddrs?.find(a => a.integrationId === unlinkIntegrationId)?.integrationName} Listing`}
        message='Greatforms will no longer be able to suggest Contacts related to this Listing'
        okLabel='Disconnect'
      />}

    </div>
    <FormContext.Provider value={EXISTING_AS_FORM_CONTEXT}>
      <Modal show={!!activeModal} onHide={() => setActiveModal(null)} dialogClassName='subwizard-container'>
        <WithinModal>
          <Modal.Header closeButton>
            <Modal.Title>Edit Property</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {activeModal && <TransactionNewInner inModal={true} />}
          </Modal.Body>
          <Modal.Footer ref={footerRef} style={{ zIndex: footerIsStuck ? '10' : undefined }} className={clsJn('border-0 sticky bg-white', footerIsStuck && 'stuck')} data-stick='bot'>
            <Button onClick={() => setActiveModal(null)} variant='outline-secondary'>Done</Button>
          </Modal.Footer>
        </WithinModal>
      </Modal>
    </FormContext.Provider>
    <div className={clsJn('pseudo-modal-mask', !formFamilyContextItems.length && 'd-none')}
      onClick={() => setFormFamilyContext(null)}>
      <div className='pseudo-modal bg-dark p-0 d-flex' onClick={(e) => {
        e.stopPropagation();
      }}>
        {typeof overflowAmount === 'number' && overflowAmount > 0
          ? <Button className='side-scroll-btn left' disabled={currentScroll === 0} onClick={() => {
            const elem = getFirstOutOfView('back');
            if (elem) {
              elem.scrollIntoView({ behavior: 'smooth', inline: 'start' });
            } else {
              horizontalScrollRef.current && (horizontalScrollRef.current.scrollLeft = 0);
            }
          }}><Icon name='chevron_left' /></Button>
          : <div style={{ width: '0.75rem' }}></div>
        }
        <div ref={horizontalScrollRef} className='card-container line-container'>
          <div className='card-line'>

            {/* Be mindful that if we do a filter before the index, we will lose our index for the update function*/}
            {formFamilyContextItems.map((instance, idx) => {
              return <FormInstanceCard
                key={`${idx}:${instance.id}`}
                fCode={instance.formCode}
                instance={instance}
                mini={true}
                onOpen={() => navigateToForm(instance.id, instance.formCode)}
              />;
            })}
            {subSuggestions?.map((fCode, idx) => <FormInstanceCard key={`${idx}:${fCode}`} onOpen={() => createAndGoToForm(fCode)} fCode={fCode}
              cardClass='form-adder'
              renderOpts={FormTypes[fCode].renderOpts} mini={true} />)}
          </div>
        </div>
        {typeof overflowAmount === 'number' && overflowAmount > 0
          ? <Button className='side-scroll-btn right' disabled={currentScroll >= overflowAmount} onClick={() => {
            const elem = getFirstOutOfView('forward');
            if (elem) {
              elem.scrollIntoView({ behavior: 'smooth', inline: 'end' });
            } else {
              horizontalScrollRef.current && (horizontalScrollRef.current.scrollLeft = overflowAmount);
            }
          }}> <Icon name='chevron_right' /></Button>
          : <div style={{ width: '0.75rem' }}></div>
        }
      </div>
    </div>
  </div>
  ;
};
