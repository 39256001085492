import ReactDOM from 'react-dom/client';
import { Buffer as BufferPolyfill } from 'buffer';
import App from './App';
import './index.css';

import '@fontsource/courier-prime';
import '@fontsource/dancing-script/latin-400.css';
import '@fontsource/dejavu-sans';
import '@fontsource/ephesis/latin-400.css';
import '@fontsource/open-sans';
import '@fontsource/playfair-display';
import '@fontsource/roboto';
import '@fontsource/sacramento/latin-400.css';
import '@fontsource/source-serif-pro';
import '@fontsource/square-peg/latin-400.css';
import '@fontsource/sue-ellen-francisco/latin-400.css';

import 'material-icons/iconfont/material-icons.css';
import 'material-symbols/outlined.css';
import { WholePageWithLogoApology } from '@property-folders/components/display/errors/major-crash';
import { ErrorBoundary } from '@property-folders/components/telemetry/ErrorBoundary';

globalThis.Buffer = BufferPolyfill;

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
  //commenting for now - this causes every render function to execute twice :(
  // <React.StrictMode>
  <ErrorBoundary FallbackComponent={WholePageWithLogoApology}>
    <App />
  </ErrorBoundary>

  // </React.StrictMode>
);
