import { useContext, useEffect } from 'react';
import * as Y from 'yjs';
import { EntitySettingsEntity, EntitySettingsRootKey } from '@property-folders/contract/yjs-schema/entity-settings';
import { dispatchEntityData, entityObserve } from '~/redux/triggers/yjsEntity';
import { AuthApi } from '@property-folders/common/client-api/auth';
import { useDispatch } from 'react-redux';
import { YManagerContext } from '@property-folders/components/context/YManagerContext';
import { AgentSessionInfoResult, YDocContentType } from '@property-folders/contract';
import { removeOtherEntityMetaEntries } from '@property-folders/common/redux-reducers/entityMeta';

export function EntityYDocObserver({ operateUnauthenticated, agentId, enableWebSockets }: {
  operateUnauthenticated: boolean,
  agentId: number,
  enableWebSockets: boolean
}) {
  const { instance: yManager } = useContext(YManagerContext);
  const { data: sessionInfoRaw } = AuthApi.useGetAgentSessionInfo();
  const sessionInfo = operateUnauthenticated ? undefined : sessionInfoRaw;
  const dispatch = useDispatch();

  useEffect(() => {
    const entities = sessionInfo?.entities || [];
    // For some reason without the operateUnauthenticated, this worked for Remote Signing but not
    // remote completion.
    if (operateUnauthenticated) return;
    if (!yManager) return;
    if (!agentId) return;
    if (!Array.isArray(entities)) return;
    if (!entities.length) return;

    const loadYdoc = async(entity: AgentSessionInfoResult['entities'][0]) => {
      const entry = yManager.get(entity.entityUuid, YDocContentType.EntitySettings, { noCreate: true, readOnly: !(sessionInfo?.isGlobalAdmin || entity.roles.includes('Manager')) });
      await entry.localProvider.whenSynced;
      const entityDoc = entry.doc;
      const entityYMap = entityDoc.getMap<EntitySettingsEntity | Record<string, never>>(EntitySettingsRootKey.Main);
      entityYMap.observeDeep(entityObserve);
      const currentYdocData = entityYMap.toJSON() as EntitySettingsEntity | Record<string, never>;
      // entityObserve only dispatches when a change is received, which is not immediately.
      dispatchEntityData({ ...currentYdocData, entityUuid: entity.entityUuid }, entity);
      return { entityDoc, entityId: entity.entityId };
    };

    const yDocPromises: Promise<{ entityDoc: Y.Doc, entityId: number }>[] = [];
    for (const entity of entities.filter(e => sessionInfo?.isGlobalAdmin || enableWebSockets || e.roles.includes('Manager'))) {
      yDocPromises.push(loadYdoc(entity));
    }

    Promise.all(yDocPromises).then(entityDocs => {
      dispatch(removeOtherEntityMetaEntries({
        keepIds: entityDocs?.map(e => e.entityId)
      }));
    });

    return () => {
      Promise.all(yDocPromises).then(entityDocs => {
        entityDocs.map(e => e.entityDoc.getMap(EntitySettingsRootKey.Main).unobserveDeep(entityObserve));
      });
    };
  }, [agentId, sessionInfo, operateUnauthenticated, yManager]);

  return <></>;
}
