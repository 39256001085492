import { Button, Col, Container, FormControl, Row } from 'react-bootstrap';
import Select, { SingleValue } from 'react-select';
import * as Y from 'yjs';
import { JsonEditor } from '~/components/JSONEditor';
import { MaterialisedPropertyData, META_APPEND, PropertyRootKey, TransactionMetaData } from '@property-folders/contract/yjs-schema/property';
import React, { useEffect, useMemo, useState } from 'react';
import { Properties } from '@property-folders/common/client-api/properties';
import { useReactRouterData } from '@property-folders/components/hooks/useReactRouterHooks';
import { formatTimestamp } from '@property-folders/common/util/formatting';
import { BreadCrumbs } from '@property-folders/components/dragged-components/BreadCrumbs';
import { LinkBuilder } from '@property-folders/common/util/LinkBuilder';
import { useLightweightTransaction } from '@property-folders/components/hooks/useTransactionField';
import { generateHeadlineFromMaterialisedData } from '@property-folders/common/yjs-schema/property';
import { ObjectDiff } from '@property-folders/common/util/object';
import { ChangesetTable } from '~/components/ChangesetTable';
import { Predicate } from '@property-folders/common/predicate';

export function ExploreYdoc() {
  const { transId } = useReactRouterData();
  const [yDoc, setYDoc] = useState<Y.Doc | null>(null);
  const [prevYDoc, setPrevYDoc] = useState<Y.Doc | null>(null);
  const [rootKey, setRootKey] = useState<SingleValue<{ value: PropertyRootKey, label: string }>>({
    value: PropertyRootKey.Data,
    label: 'Data'
  });
  const [updates, setUpdates] = useState<{ value: Uint8Array, date: Date, author: any, clock: number }[]>([]);
  const [ydocClock, setYdocClock] = useState<number>(-1);
  const [selectedUpdate, setSelectedUpdate] = useState<SingleValue<{ value: any; label: string }>>();

  const { value: transRoot } = useLightweightTransaction<MaterialisedPropertyData>({});
  const headlineVal = generateHeadlineFromMaterialisedData(transRoot);
  const rootLabel = 'Properties';
  const updatedBreadcrumb = useMemo(() => [
    ...([{ label: rootLabel, href: '/properties/' }]),
    {
      label: headlineVal || 'Property Overview',
      href: `/properties/${LinkBuilder.seoFriendlySlug(transId, headlineVal)}`
    },
    { label: 'History' }
  ], [transId, headlineVal, rootLabel]);

  useEffect(() => {
    Properties.getCompleteYdocHistory(transId).then(response => {
      if (!response) {
        return;
      }

      setUpdates(response.map(u => ({
        value: u.value,
        date: u.ts,
        author: u.author,
        clock: u.clock
      })));
    });
  }, [transId]);

  useEffect(() => {
    const doc = new Y.Doc();
    doc.transact(() => {
      for (const update of updates) {
        if (update.clock > ydocClock) {
          return;
        }

        Y.applyUpdate(doc, update.value);
      }
    });

    setYDoc(doc);

    const currentClockUpdateIndex = updates.findIndex(u=>u.clock === ydocClock);
    const prevUpdate = updates[currentClockUpdateIndex-1];
    const prevClock = prevUpdate?.clock;
    if (prevClock) {
      const prevDoc = new Y.Doc();

      prevDoc.transact(() => {
        for (const update of updates) {
          if (update.clock > prevClock) {
            return;
          }

          Y.applyUpdate(prevDoc, update.value);
        }
      });

      setPrevYDoc(prevDoc);
    } else {
      setPrevYDoc(null);
    }

  }, [ydocClock]);

  const json = useMemo(()=>yDoc?.getMap(rootKey?.value).toJSON() ?? {},[yDoc, rootKey?.value]);

  const sublineages = useMemo(()=>{
    const doc = new Y.Doc();
    doc.transact(() => {
      for (const update of updates) {
        Y.applyUpdate(doc, update.value);
      }
    });
    const rootMeta = doc?.getMap(PropertyRootKey.Meta).toJSON() as TransactionMetaData;
    const sublineageKeys = rootMeta?.sublineageRoots??[];
    // Will only result in rendering the sublineages visible in the current meta
    return [

      { value: PropertyRootKey.Meta, label: 'Meta' },
      { value: PropertyRootKey.Data, label: 'Data' },
      { value: PropertyRootKey.FileTrack, label: 'File Track' },
      ...sublineageKeys.flatMap(key => {
        return [
          { value: key, label: `sublineage Data for ${key}` },
          { value: key+META_APPEND, label: `sublineage Meta for ${key}` }
        ];
      })
    ];
  }, [updates.length]);

  const allTheComparisons = useMemo(() => {
    const comparisons = sublineages.map(({ value, label })=>{
      const cur = yDoc?.getMap(value).toJSON();
      const prev = prevYDoc?.getMap(value).toJSON();
      const changes = ObjectDiff.getChangeset(prev??{}, cur??{}).filter(s => s.type !== 'UNCHANGED');
      return changes.length?{ [label]: changes }:null;
    }).filter(Predicate.isTruthy);
    return Object.assign({}, ...comparisons);

  }, [yDoc, prevYDoc, sublineages]);

  const handleClockChange = (increment: number) => () => {
    const currentClockUpdateIndex = updates.findIndex(u=>u.clock === ydocClock);
    const incrementedUpdate = updates[currentClockUpdateIndex+increment];
    if (!incrementedUpdate?.clock) return;

    setSelectedUpdate({
      label: `${formatTimestamp(incrementedUpdate.date.getTime())} - ${getAuthorName(incrementedUpdate.author)}`,
      value: incrementedUpdate
    });
    setYdocClock(incrementedUpdate.clock);

  };

  return <div className={'w-100 h-100 px-3 overflow-auto'}>
    <div className={'w-100'}>
      <h1 className="display-6 py-1">{headlineVal} - Explore Mode</h1>
      <div className='d-flex flex-row align-items-center justify-content-between w-100 mb-3'>
        <div><BreadCrumbs segments={updatedBreadcrumb} /></div>
      </div>
    </div>
    <hr className={'m-0 mb-2'} />
    <Container>
      <Row>

        <Col className='d-flex align-items-center'>
          <div className='flex-grow-0 me-1'><Button variant={'outline-secondary'} onClick={handleClockChange(-1)}>{'<'}</Button></div>
          <div className='d-flex-1'>
            <Select
              value={selectedUpdate}
              onChange={newValue => {
                if (!newValue) {
                  return;
                }

                console.log(newValue);
                setSelectedUpdate(newValue);
                setYdocClock(newValue.value.clock);
              }}
              options={updates.map(u => ({
                label: `${formatTimestamp(u.date.getTime())} - ${getAuthorName(u.author)}`,
                value: u
              }))}
            ></Select>
          </div>
          <div className='flex-grow-0 ms-1'><Button variant={'outline-secondary'}onClick={handleClockChange(1)}>{'>'}</Button></div>
        </Col>

        <Col>
          <Select
            value={rootKey}
            onChange={newValue => {
              setRootKey(newValue);
            }}
            options={sublineages}
          ></Select>
        </Col>
        <Col>
          <FormControl
            value={rootKey?.value??''}
            onChange={changeEvent => setRootKey({ value: changeEvent?.target.value, label: changeEvent?.target.value })}
          />
        </Col>
      </Row>

      <Row className='mt-1'>
        <Col>
          <JsonEditor
            jsonData={json}
            mode={window.ultraRawDogMode ? 'code' : 'tree'}
          />
        </Col>
      </Row>
      {allTheComparisons && !!Object.keys(allTheComparisons).length && <div>
        {Object.entries(allTheComparisons).map(([key, changeset]) => <>
          <h4>{key}</h4>
          <ChangesetTable changeSet={changeset} />
        </>)}
      </div>}
    </Container>
  </div>;
}

function getAuthorName(author: any) {
  if ('name' in author) {
    return author.name;
  }

  switch (author.type) {
    case 2: // System
      return 'reaforms system';
    case 0: // Unknown
    default:
      return 'Unknown';
  }
}
