import { useQuery } from '@tanstack/react-query';
import { ApiUserItem } from '~/pages/settings/tabs/UserManagement';
import { BaseAjaxResponse, LegacyApi } from '@property-folders/common/client-api/legacyApi';
import { TeamListItem } from '~/pages/settings/tabs/TeamManagement';

export function useGetAdminUsersTeams(entityId?: number) {
  const { data: users } = useQuery({
    queryKey: ['settings-user-list-admin', entityId || -1],
    queryFn: async context => {
      if (!entityId) return [];
      const response = await fetch(`/legacy/Datatables/Ajax/Admin_UserList.php?EntityID=${entityId}`, {
        signal: context.signal
      });
      const parsed = await response.json() as {
        data?: { DT_RowId: string, entity: { ManagementID: number }, users_agents: ApiUserItem }[]
      };
      return (parsed?.data || []).map(item => ({
        id: item.users_agents.AgentID,
        name: item.users_agents.AgentName,
        email: item.users_agents.AgentEmail
      }));
    }
  });

  const { data: teams } = useQuery({
    queryKey: ['settings-team-list-admin', entityId || -1],
    queryFn: async context => {
      if (!entityId) return [];
      const result = await LegacyApi.ajax<{ items: TeamListItem[] } & BaseAjaxResponse>('team_get_list', {
        'EntityID': entityId.toString()
      }, context.signal);
      return result.items.map(item => ({
        id: item.TeamID,
        name: item.Name
      }));
    }
  });

  return { users, teams };
}
