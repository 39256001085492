import { Button, Row } from 'react-bootstrap';
import { SearchBar } from '@property-folders/components/dragged-components/SearchBar';
import { FormCategorySelect } from '@property-folders/components/dragged-components/FormCategorySelect';
import { ReactElement, useContext, useEffect, useMemo, useState } from 'react';
import { TableList, TableListColumns } from '@property-folders/components/display/TableList';
import { FormsApi } from '@property-folders/common/client-api/formsApi';
import { ListCategoriesForAgent, ListFormsForAgent, ModuleWithChain } from '@property-folders/contract/rest/forms';
import { useBreakpointValue } from '@property-folders/components/hooks/useBreakpointValue';
import { useLocalStorage } from 'react-use';
import { AuthApi } from '@property-folders/common/client-api/auth';
import { Tag, TagColor } from '@property-folders/components/display/Tag';
import { CreateFormModal, FormRef } from '~/components/create-form/CreateFormModal';
import clsJn from '@property-folders/common/util/classNameJoin';
import { FormTypes, rsaaExecuted } from '@property-folders/common/yjs-schema/property/form';
import { useReactRouterData } from '@property-folders/components/hooks/useReactRouterHooks';
import { RouterData } from '~/App';
import {
  ExtraFormCode,
  FormCode,
  FormCodeUnion,
  FormInstance,
  FormSigningState,
  Maybe,
  SignedStates
} from '@property-folders/contract';
import { ErrorBoundary } from '@property-folders/components/telemetry/ErrorBoundary';
import { FallbackModal } from '@property-folders/components/display/errors/modals';
import { Chevroned } from '@property-folders/components/dragged-components/Chevroned';
import { sortBy } from 'lodash';
import { generateHeadlineFromMaterialisedData, materialiseProperty } from '@property-folders/common/yjs-schema/property';
import { clickNoBubble } from '@property-folders/common/util/clickNoBubble';
import { LinkBuilder } from '@property-folders/common/util/LinkBuilder';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { handleNewForm } from '@property-folders/common/util/handleNewForm';
import { FileSyncContext } from '@property-folders/components/context/fileSyncContext';
import { compareFormInstances } from '@property-folders/common/util/compareFormInstances';
import { FormUtil } from '@property-folders/common/util/form';
import { ContentTitler } from '@property-folders/components/dragged-components/ContentTitler';
import { FormWarningDialog, ShowWarnParams } from '@property-folders/components/dragged-components/Wizard/FormWarningDialog';
import { isSubscriptionForm } from '@property-folders/common/subscription-forms/isSubscriptionForm';
import { Predicate } from '@property-folders/common/predicate';
import { FoldersApi } from '@property-folders/common/client-api/foldersApi';
import type { CrumbDefn } from '@property-folders/common/types/BreadCrumbTypes';
import type { FolderDetails } from '@property-folders/contract/rest/folders';

type Row = ListFormsForAgent['results'][0];

function generateBadges(row: Row) {
  const badges: ReactElement[] = [];
  const { features } = row;

  if (!features) {
    return null;
  }

  if (!features.editable) {
    badges.push(<Tag outline={true} background={TagColor.ADOBE_RED}>PDF</Tag>);
  }

  if (features.remoteCompletion) {
    badges.push(<Tag outline={true} background={TagColor.CERULEAN}>Remote Completion</Tag>);
  }

  if (features.translation) {
    badges.push(<Tag outline={true} background={TagColor.TIFFANY_BLUE}>Translation</Tag>);
  }

  if (badges.length === 0) {
    return null;
  }

  return <div className='d-flex gap-1'>{badges}</div>;
}

interface Props {
  propertyFolder?: boolean;
  templateMode?: boolean;
}

export const PropertyFolderModule: ModuleWithChain = {
  moduleId: 0,
  moduleName: 'Property Folders',
  stateName: 'SA',
  fullProductName: 'Property Folders',
  hasForms: true,
  moduleParentId: 0,
  productId: 0,
  productName: 'Property Folders',
  chain: [{
    moduleId: 0,
    moduleName: 'Property Folders',
    stateName: 'SA',
    fullProductName: 'Property Folders',
    hasForms: true,
    moduleParentId: 0,
    productId: 0,
    productName: 'Property Folders'
  }]
};

export function CreateFormPage(props: Props) {
  const { propertyFolder, templateMode } = props;
  const loaderData = useReactRouterData<RouterData>();
  const [urlSearchParams] = useSearchParams();
  const property = materialiseProperty(loaderData.ydoc);
  const headline = generateHeadlineFromMaterialisedData(property?.data);
  const { data: session } = AuthApi.useGetAgentSessionInfo();
  const agentId = session?.agentId ?? 0;
  const [searchTerm, setSearchTerm] = useState<string | undefined>();
  const [formCategory, setFormCategory] = useState<string>('All');
  const [forms, setForms] = useLocalStorage<Row[]>(`userForms_${agentId}`, []);
  const [filteredForms, setFilteredForms] = useState<Row[]>(forms ?? []);
  const [modules, setModules] = useLocalStorage<ListCategoriesForAgent['results']>(`userModules_${agentId}${propertyFolder ? '_propertyFolder' : ''}`, []);
  const [hasMultipleProducts, setHasMultipleProducts] = useState<boolean>(false);
  const featuresWidth = useBreakpointValue({ sm: '200px', md: '330px' }, '200px');
  const [selectedForm, setSelectedForm] = useState<FormRef>();
  const navigate = useNavigate();
  const { instance: fileSync } = useContext(FileSyncContext);
  const [showWarn, setShowWarn] = useState<Maybe<ShowWarnParams>>(undefined);
  const [folderIdAsNumber, setFolderIdAsNumber] = useState<number | undefined>();
  // `null` when `{folderId}` pending/not present in search params.
  const [folderDetails, setFolderDetails] = useState<FolderDetails['results'] | null>(null);

  useMemo(async () => {
    const localFolderId = urlSearchParams.get('folderId');
    const localFolderIdAsNumber = localFolderId !== null && !isNaN(parseInt(localFolderId)) ? parseInt(localFolderId) : undefined;
    setFolderIdAsNumber(localFolderIdAsNumber);

    // Do not fetch documents for invalid folder id.
    if (!localFolderIdAsNumber) {
      return;
    }

    const { results } = await FoldersApi.getDetails({ folderId: localFolderIdAsNumber });
    setFolderDetails(results);
  }, [urlSearchParams]);

  useEffect(() => {
    FormsApi.listFormsForAgent().then(res => {
      const results = templateMode
        ? res.results.filter(r => r.features.editable)
        : res.results;

      if (!propertyFolder) {
        setForms(results);
      } else {
        const isSignedRsaa = property?.meta?.formStates?.[FormCode.RSAA_SalesAgencyAgreement]?.instances?.some(i => SignedStates.has(i.signing?.state||FormSigningState.None)) || false;
        const newForms: Row[] = [];
        for (const key of Object.keys(FormTypes)) {
          // Do not display uploaded documents in Create Document page
          if (key === FormCode.UploadedDocument) continue;
          // also don't show the special case of uploaded Form 1
          if (key === ExtraFormCode.Form1Upload) continue;

          const form = FormTypes[key];
          const full = results.find(f => f.code === key);
          if ([FormCode.Form1, FormCode.VendorQuestionnaire].includes(form.formFamily) && property?.data?.form1AndSearches?.whoSearches === 'eckermanns' && isSignedRsaa) continue;

          if (full) {
            newForms.push(full);
          } else {
            if (!form.renderOpts?.notYetImplemented && !form.renderOpts?.processNotForm && !form.isVariation && !form.isTermination) {
              newForms.push({
                features: {
                  editable: !form.wizardOpts?.pdfOnly,
                  translation: false,
                  remoteCompletion: false,
                  esigning: !form.wizardOpts?.noSigning,
                  distribution: !form.wizardOpts?.noSigning,
                  canVary: Object.values(FormTypes).some(ft=>ft.isVariation && ft.formFamily===form.formFamily),
                  canManage: [FormCode.OfferToPurchase, FormCode.RSC_ContractOfSale].includes(key),
                  canCreateMultiple: [FormCode.OfferToPurchase, FormCode.RSC_ContractOfSale].includes(key)
                },
                name: form.label,
                fullProductName: PropertyFolderModule.productName,
                code: key,
                module: PropertyFolderModule,
                id: 0,
                stateName: 'SA',
                order: +!form.primary,
                created: !!property?.meta?.formStates?.[form.formFamily]?.instances?.length
              });
            }
          }
        }

        setForms(newForms);
      }
    });

    FormsApi.listCategoriesForAgent().then(res => {
      if (!propertyFolder) {
        setModules(res.results);
      } else {
        setModules([PropertyFolderModule]);
      }
      setHasMultipleProducts(res.hasMultipleProducts);
    });
  }, [propertyFolder]);

  useEffect(() => {
    const f = forms ?? [];
    const st = (searchTerm ?? '').toLowerCase();

    if (searchTerm == '') {
      setFilteredForms(f);
    }

    setFilteredForms((f).filter(form => (
      form.name?.toLowerCase().includes(st)
      || form.fullProductName?.toLowerCase().includes(st)
      || form.module.moduleName?.toLowerCase().includes(st))
    ));
  }, [searchTerm, forms]);

  const onClick = (row: Row) => {
    if (!row.features.editable) {
      window.open(`/legacy/tools/PDF_Download.php?ID=${row.id}`, '_blank');
      return;
    }

    if (!propertyFolder) {
      setSelectedForm({ formId: row.id });
    } else {
      if (row.created && !row.features.canCreateMultiple) {
        const family = FormTypes[row.code]?.formFamily;
        const latestInstance = property?.meta?.formStates?.[family]?.instances?.sort(compareFormInstances)[0];
        latestInstance && navigate(LinkBuilder.documentPath({ id: loaderData.transId, nicetext: headline },{ id: latestInstance.id, nicetext: FormTypes[latestInstance.formCode].label }, isSubscriptionForm(row.code)));
      } else {
        if (row.code === FormCode.OfferToPurchase) return; //agent cannot create offer at this point

        if (FormTypes[row.code]?.formFamily === FormCode.Form1) {
          if (!rsaaExecuted(property?.meta)) {
            setShowWarn({
              continueFn: () => {
                setShowWarn(undefined);
                setSelectedForm({ formCode: row.code as FormCodeUnion, propertyFolderId: loaderData.transId });
              },
              formCode: row.code as FormCodeUnion,
              actionName: 'create',
              title: 'Sales Agency Agreement not fully executed',
              message: 'The Form 1 should not be created until the Sales Agency Agreement has been fully executed'
            });
            return;
          }
        }

        setSelectedForm({ formCode: row.code as FormCodeUnion, propertyFolderId: loaderData.transId });
      }
    }
  };

  const getModule = (row: Row) => {
    return <Chevroned items={row.module.chain.map(m => m.moduleName)} />;
  };

  const handleManage = (code: string) => {
    switch (code) {
      case FormCode.OfferToPurchase:
        navigate(LinkBuilder.propertyPagePath({ id: loaderData.transId, nicetext: headline }, FormTypes[FormCode.OfferManagement].navigateTo));
        break;
      case FormCode.RSC_ContractOfSale:
        navigate(LinkBuilder.propertyPagePath({ id: loaderData.transId, nicetext: headline }, FormTypes[FormCode.ContractManagement].navigateTo));
        break;
    }
  };

  const handleVary = async(code: string) => {
    const formCode = code as FormCodeUnion;
    const newForm = await handleNewForm(loaderData.ydoc, formCode, fileSync);
    if (!newForm) return;
    navigate(LinkBuilder.documentPath({ id: loaderData.transId, nicetext: headline },{ id: newForm.formId, nicetext: FormTypes[formCode].label }, isSubscriptionForm(formCode)));
  };

  const handleView = (instance: FormInstance) => {
    navigate(LinkBuilder.documentPath({ id: loaderData.transId, nicetext: headline },{ id: instance.id, nicetext: FormTypes[instance.formCode].label }, isSubscriptionForm(instance.formCode)));
  };

  const handleInvite = () => {
    navigate(LinkBuilder.propertyPagePath({ id: loaderData.transId, nicetext: headline }, `${FormTypes[FormCode.PurchaserManagement].navigateTo}/add`));
  };

  const columns: TableListColumns<Row>[] = [
    { label: 'Name', rowMajor: 'name', rowMinor: getModule },
    { label: 'Features', rowMajor: generateBadges, headerCellStyle: { width: featuresWidth }, hideIfEmptyInCardView: true },
    {
      label: '',
      rowMajor: row => {
        const family = FormTypes[row.code]?.formFamily;
        const latestInstance = property?.meta?.formStates?.[family]?.instances?.sort(compareFormInstances)[0];
        const createableFormCodes = FormUtil.getCreateableFormCodes(property?.meta?.formStates, row.code);

        return <div className='w-100 d-flex justify-content-end'>
          {row.code === FormCode.OfferToPurchase && <Button className={'ms-2'} onClick={clickNoBubble(handleInvite)} variant='link'>Invite</Button>}
          {row.features.canManage && <Button className={'ms-2'} onClick={clickNoBubble(()=>handleManage(row.code))} variant='link'>Manage</Button>}
          {!row.features.editable && <Button className={'ms-2'} variant='outline-secondary'>View</Button>}
          {(((row.features.editable && !row.created) || row.features.canCreateMultiple) && row.code !== FormCode.OfferToPurchase) && <Button className={'ms-2'} variant='outline-secondary'>Create</Button>}
          {row.features.editable && row.created && !row.features.canCreateMultiple && <Button className={'ms-2'} onClick={clickNoBubble(()=>handleView(latestInstance))} variant='link'>View</Button>}
          {row.features.canVary && row.created && !!createableFormCodes?.length && <Button className={'ms-2'} onClick={clickNoBubble(()=>handleVary(createableFormCodes[0]))} variant='outline-secondary'>Vary</Button>}
        </div>;
      },
      headerCellStyle: { width: '160px' }
    }
  ];

  const breadcrumbs = (() => {
    switch (true) {
      case propertyFolder:
        return useMemo(() => [
          { label: 'Properties', href: '/properties/' },
          { label: headline || 'Property Overview', href: `/properties/${LinkBuilder.seoFriendlySlug(loaderData.transId, headline)}` },
          { label: 'All Documents', href: `/properties/${LinkBuilder.seoFriendlySlug(loaderData.transId, headline)}/documents/` },
          { label: '' }
        ], [headline]);
      case !!(folderIdAsNumber !== undefined && folderDetails):
        return [
          { label: 'Folders', href: '/folders' },
          { label: folderDetails.folderName, href: `/folders/${folderDetails.folderId}/documents` },
          { label: '' }
        ].filter((o) => Predicate.isString(o.label)) as CrumbDefn[];
      default:
        return undefined;
    }
  })();

  const afterTitle = <>
    <SearchBar
      placeholder='Search'
      autocompleteValues={forms?.map(f => f.name)}
      onSearch={term => setSearchTerm(term)}
    />
    {!propertyFolder && <FormCategorySelect
      onChange={fc => setFormCategory(fc)}
      value={formCategory}
      className='flex-basis-0 flex-grow-1'
      style={{ minWidth: '300px', maxWidth: '400px' }}
    />}
  </>;
  return <ContentTitler title={templateMode ? 'Create Document Template' : 'Create Document'} breadcrumbs={breadcrumbs} afterTitle={afterTitle} flex={true} scroll={false}>
    <div className='flex-grow-1 mt-2' style={{ overflow: 'auto', position: 'relative' }}>
      {modules?.map((m, index) => {
        const items = propertyFolder
          ? sortBy(filteredForms, ['order', 'code'])
          : filteredForms.filter(f => f.module.moduleId === m.moduleId);

        return m.hasForms && items.length > 0 && isFormCategoryMatch(formCategory, m)
          ? <div key={m.moduleId} className={clsJn(index > 0 && 'mt-3')} >
            {!propertyFolder && <h3 className='ms-3'><Chevroned items={m.chain.map(c => c.moduleName)} chevronSize={3} />{hasMultipleProducts ? ` (${m.stateName})` : ''}</h3>}
            <TableList
              storageKey='forms-list'
              items={items}
              columns={columns}
              rowActions={[]}
              rowClick={onClick}
              rowClickEnabled={true}
              containerClass={'mw-100 mt-0'}
            />
          </div>
          : <></>;
      })}
    </div>
    {selectedForm && <ErrorBoundary fallbackRender={fallback =>
      <FallbackModal
        {...fallback}
        onClose={() => {
          setSelectedForm(undefined);
        }}
        show={true}
      />
    }>
      <CreateFormModal
        form={selectedForm}
        folderDetails={folderDetails ?? undefined}
        templateMode={templateMode}
        onClose={() => {
          setSelectedForm(undefined);
        }}
        skipConfirmation={propertyFolder}
        returnToCurrentPage={false}
      />
    </ErrorBoundary>}
    {!!showWarn && <FormWarningDialog
      formCode={showWarn.formCode}
      title={showWarn.title}
      message={showWarn.message}
      actionName={showWarn.actionName}
      onCancel={() => setShowWarn(undefined)}
      onContinue={() => showWarn?.continueFn()}
    />}
  </ContentTitler>;
}

function isFormCategoryMatch(formCategory: string, m: ModuleWithChain): boolean {
  if (!formCategory) return true;
  if (formCategory === 'All') return true;
  if (formCategory.startsWith('product.')) {
    const productId = parseInt(formCategory.replace('product.', ''), 10);
    return m.productId === productId;
  }
  return String(m.moduleId) === formCategory;
}
