import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { YManagerContext } from '@property-folders/components/context/YManagerContext';
import { Spinner } from 'react-bootstrap';
import { buildYDoc } from '@property-folders/components/form-gen-util/buildYDoc';
import { v4 } from 'uuid';
import { YManager } from '@property-folders/common/offline/yManager';
import { applyMigrationsV2_1 } from '@property-folders/common/yjs-schema';
import {
  AgencySalesperson,
  FolderType,
  FormCode,
  MaterialisedPropertyData, OtherContactParty,
  PartyType,
  TransactionMetaData
} from '@property-folders/contract';
import { PropertyRootKey } from '@property-folders/contract/yjs-schema/property';
import { useSelector } from 'react-redux';
import { AuthApi } from '@property-folders/common/client-api/auth';
import { AjaxPhp, GetDocumentMetaResponse } from '@property-folders/common/util/ajaxPhp';
import { UserPreferencesRootKey } from '@property-folders/contract/yjs-schema/user-preferences';
import { BelongingEntityMeta, REDUCER_NAME as entityMetaKey } from '@property-folders/common/redux-reducers/entityMeta';
import { uuidv4 } from 'lib0/random';
import { mapPartyType } from '@property-folders/services/subscription-signing-event/mapPartyType';
import { canonicalisers, companyTradingAs } from '@property-folders/common/util/formatting';
import { upperFirst } from 'lodash';

export function getOtherContactsForSubscriptionForm({
  originalOtherContacts,
  signatures,
  signatureMetadata,
  agent
}: {
    originalOtherContacts: OtherContactParty[],
    signatures: string[],
    signatureMetadata: GetDocumentMetaResponse['signatureMetadata'],
    agent: AgencySalesperson
  }
): OtherContactParty[] {
  return signatures?.map((rawSignatureName, partyIndex) => {
    const existingSigningParty = originalOtherContacts.find(o => o.originalType === rawSignatureName);
    const docSigningParty = signatureMetadata.find(sm => sm.position.toLowerCase() === rawSignatureName.toLowerCase());
    const signatureType = rawSignatureName.toLowerCase();
    const category = mapPartyType(rawSignatureName);
    const isAgent = signatureType.startsWith('agent') || signatureType.startsWith('manager') || signatureType.startsWith('propertymanager');
    const signatureDisplayName = docSigningParty?.displayName ?? rawSignatureName;

    const docEmail = canonicalisers.email(docSigningParty?.email ?? '');
    const docMobile = canonicalisers.phone(docSigningParty?.mobile ?? '');
    const defaultLegalName = signatureDisplayName.split('_').map(s => upperFirst(s)).join(' ');

    // priority
    // document details -> existing signing party -> default
    return {
      id: existingSigningParty?.id || uuidv4(),
      fullLegalName: isAgent
        ? agent.name
        : (docSigningParty?.name !== defaultLegalName // document has been modified
          ? (docSigningParty?.name || existingSigningParty?.fullLegalName || defaultLegalName)
          : existingSigningParty?.fullLegalName || defaultLegalName),
      email1: isAgent
        ? agent.email
        : (docEmail.valid
          ? docEmail.canonical as string
          : existingSigningParty?.email1 || undefined),
      phone1: isAgent
        ? agent.phone
        : (docMobile.valid
          ? docMobile.canonical as string
          : existingSigningParty?.phone1 || undefined),
      partyType: PartyType.Individual,
      overridePartyCategory: category,
      originalType: rawSignatureName,
      signatureDisplayName,
      ...(isAgent ? {
        agencySalesPersonId: agent.linkedSalespersonId,
        linkedSalespersonId: agent.linkedSalespersonId
      } : {})
    };
  });
}

export function GenerateSubscriptionFolderSigningSession() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { documentId: documentIdRaw } = useParams();
  const documentId = Number(documentIdRaw);
  const { instance: yManagerInstance } = useContext(YManagerContext);
  const [error, setError] = useState('');
  const getCurrentUserPrefs = useCallback(() => yManagerInstance?.getUserPrefs()?.doc.getMap(UserPreferencesRootKey.Main).toJSON(), [yManagerInstance]);
  const memberEntities = useSelector((state: any) => state?.[entityMetaKey] as BelongingEntityMeta | undefined);
  const { data: sessionInfo } = AuthApi.useGetAgentSessionInfo();

  const navigateToForm = (ydocId: string, propertyFormId: string) => {
    const returnPath = searchParams.get('returnPath');
    const returnPathQuery = returnPath ? `?returnPath=${encodeURIComponent(returnPath)}` : '';
    navigate(`/subscription-folder/${ydocId}/document/${propertyFormId}${returnPathQuery}`, { replace: true });
  };

  const { ydoc, localProvider, docName: ydocId, propertyFormId } = useMemo(() => {
    const newUUID = v4();
    const propertyFormId = v4();
    const result = buildYDoc(newUUID, true);
    YManager.instance().markUserViewing(newUUID, true);
    return { ...result, propertyFormId };
  }, []);

  const onError = (e?: any) => {
    if (e) {
      console.error(e);
    }

    setError('An error occurred generating the signing session.');
  };

  useEffect(() => {
    if (!sessionInfo || !ydoc || !localProvider || !documentId) {
      return;
    }

    setError('');
    const ac = new AbortController();
    AjaxPhp.getDocumentMeta({ documentId, signal: ac.signal }).then(meta => {
      if (!meta) {
        return;
      }

      const { formId, entityId, signatures, name, formName, signingPortal } = meta;
      const documentName = name;
      const formCode = FormCode.UploadedDocument;

      if (signingPortal && signingPortal.propertyId && signingPortal.propertyFormId) {
        navigateToForm(signingPortal.propertyId, signingPortal.propertyFormId);
        return;
      }

      localProvider.whenSynced.then(() => {
        const localEntity = memberEntities && entityId ? memberEntities?.[entityId] : undefined;

        if (!localEntity || !getCurrentUserPrefs || !memberEntities) {
          return;
        }

        applyMigrationsV2_1<TransactionMetaData>({
          doc: ydoc,
          docKey: PropertyRootKey.Meta.toString(),
          typeName: 'Property',
          migrations: [
            {
              name: 'initialise metadata',
              fn: state => {
                const now = new Date();
                state.folderType = FolderType.Document;
                state.createdUtc = now.toISOString();

                // clearly this is not necessary, but typescript insists and I'd rather this
                // than a cast as any or something silly
                if (state.folderType === FolderType.Document) {
                  state.documentId = documentId;
                }

                state.entity = {
                  name: localEntity.name,
                  id: localEntity.entityId
                };

                state.creator = {
                  name: sessionInfo.name,
                  id: sessionInfo.agentId,
                  timestamp: now.getTime()
                };

                if (
                  !state.formStates
                  || !state.formStates[formCode]
                  || !state.formStates[formCode].instances?.find(i => i.id === propertyFormId)
                  || !state.formStates[formCode].instances?.find(i => i.id === propertyFormId)?.signing
                ) {
                  state.formStates = {};
                  state.formStates[formCode] = {
                    instances: [{
                      id: propertyFormId,
                      formCode: formCode as any,
                      modified: Date.now(),
                      created: Date.now(),
                      subscription: {
                        documentId,
                        fileName: formName,
                        formId
                      }
                    }]
                  };

                  state.createdUtc = now.toISOString();
                }
              }
            },
            {
              name: 'set property id',
              docKey: PropertyRootKey.Data.toString(),
              fn: (state: any) => {
                const currentSalesperson = localEntity?.salespeople?.find(s => s.id === sessionInfo.agentId);
                const agent: AgencySalesperson = {
                  id: sessionInfo.agentUuid,
                  phone: (canonicalisers.phone(currentSalesperson?.phone || '').canonical as string) || '',
                  email: (canonicalisers.email(sessionInfo.email || '').canonical as string) || '',
                  name: sessionInfo.name,
                  linkedSalespersonId: sessionInfo.agentId
                };

                (state as MaterialisedPropertyData).id = ydocId;
                (state as MaterialisedPropertyData).headline = documentName;
                (state as MaterialisedPropertyData).agent = [{
                  company: companyTradingAs(localEntity.name, localEntity.tradeName),
                  address: localEntity.address1,
                  phone: localEntity.phone,
                  email: localEntity.email,
                  abn: (canonicalisers.abnacn(localEntity.abn ?? '').canonical as string) ?? '',
                  rla: localEntity.rla ?? '',
                  linkedEntityId: entityId,
                  profileName: localEntity.profileName,
                  salesp: [agent],
                  id: v4()
                }];
                (state as MaterialisedPropertyData).otherContacts = getOtherContactsForSubscriptionForm({
                  signatures,
                  signatureMetadata: meta.signatureMetadata,
                  originalOtherContacts: [],
                  agent
                });
              }
            }
          ]
        });

        AjaxPhp.setDocumentPropertyFolder({
          documentId,
          signal: ac.signal,
          propertyFolderId: ydocId,
          propertyFormId,
          folderType: FolderType.Document
        }).then(() => {
          navigateToForm(ydocId, propertyFormId);
        }).catch(onError);
      });
    }).catch(onError);

    return () => {
      ac.abort();
    };
  }, [localProvider, ydoc, !!sessionInfo, Object.keys(memberEntities ?? {}).length]);

  return <div className={'d-flex flex-row justify-content-center mt-5'}>
    <h2 className={'me-4'}>{error || 'Generating signing session...'}</h2>
    {!error && <Spinner animation={'border'} style={{ width: '2.5rem', height: '2.5rem' }} />}
  </div>;
}
