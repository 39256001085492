import * as Y from 'yjs';
import { EntitySettingsEntity, EntitySettingsRootKey } from '@property-folders/contract/yjs-schema/entity-settings';
import { store } from '../store';
import { markLogoUpdated, updateEntityMetaEntry } from '@property-folders/common/redux-reducers/entityMeta';
import { FileStorage, FileType } from '@property-folders/common/offline/fileStorage';

export function entityObserve(_events: Y.YEvent<any>[], transaction: Y.Transaction) {
  const doc = transaction.doc;
  const entityData = doc.getMap(EntitySettingsRootKey.Main).toJSON() as EntitySettingsEntity | Record<string, never>;
  dispatchEntityData(entityData);
}

export function dispatchEntityData(entityData: (EntitySettingsEntity & {entityUuid?: string}) | Record<string, never>, defaultData?: { entityId: number, entityUuid: string,  name: string }) {
  if (!isEntitySettingsData(entityData)) {
    if (defaultData) {
      store.dispatch(updateEntityMetaEntry({
        id: defaultData.entityId,
        data: {
          entityId: defaultData.entityId,
          entityUuid: defaultData.entityUuid,
          name: defaultData.name,
          salespeople: null
        }
      }));
      return;
    }

    console.error('No entity ID available on entity settings data?', JSON.stringify(entityData), JSON.stringify(defaultData));
    return;
  }

  const entityId = entityData.entityId;

  if (entityData.logoS3Uri) {
    FileStorage.queueDownload(`logo/${entityId}`, FileType.EntityLogo, 'image', { entityLogo: { entityId: entityId, logoUri: entityData.logoS3Uri } }, { entityId } );
  } else {
    FileStorage.delete(`logo/${entityId}`).then(()=>store.dispatch(markLogoUpdated({ id: `${entityId}`, updatedTime: Date.now() })));
  }

  store.dispatch(updateEntityMetaEntry({ id: entityId, data: entityData }));
}

function isEntitySettingsData(value: EntitySettingsEntity | Record<string,never>): value is EntitySettingsEntity  {
  return 'entityId' in value && !!value.entityId;
}
