import { Alert, Button, Form, Modal } from 'react-bootstrap';
import React, { useContext, useEffect, useState } from 'react';
import { AsyncButton } from '@property-folders/components/dragged-components/AsyncButton';
import { YjsDocContext } from '@property-folders/components/context/YjsDocContext';
import { FormCode, TransactionMetaData } from '@property-folders/contract';
import { useImmerYjs } from '@property-folders/components/hooks/useImmerYjs';
import { PropertyFormYjsDal, setAutoServeRecipeints } from '@property-folders/common/yjs-schema/property/form';
import { applyMigrationsV2 } from '@property-folders/common/yjs-schema';
import { PropertyRootKey } from '@property-folders/contract/yjs-schema/property';
import { useEntity } from '@property-folders/components/hooks/useEntity';
import { keyBy, mapValues, startCase } from 'lodash';
import { WorkflowConditionTriggerType } from '@property-folders/contract/yjs-schema/entity-settings';

export function Form1ReplaceModal({ type, onClose, onOk }: { type: 'order' | 'create' | 'upload', onClose: () => void, onOk: () => void }) {
  const { ydoc: yDoc, transactionMetaRootKey } = useContext(YjsDocContext);
  const { bindState: metaBindState } = useImmerYjs<TransactionMetaData>(yDoc, transactionMetaRootKey);
  const { data: meta } = metaBindState<TransactionMetaData>(m => m);
  const recipients = PropertyFormYjsDal.getFormFamilyServeRecipients(FormCode.Form1, meta);
  const localEntity = useEntity(meta?.entity?.id);
  const [reServe, setReserve] = useState<{[key:string]: boolean}>(localEntity?.signingOptions?.autoServeForm1 ? mapValues(keyBy(recipients, r=>r.id), ()=>true) : {});
  const [show, setShow] = useState(true);
  const [showCoolingOffWarning, setShowCoolingOffWarning] = useState(false);

  const handleCancel = () => {
    setShow(false);
    onClose();
  };

  const handleReplace = () => {
    if (!yDoc) return;

    applyMigrationsV2<TransactionMetaData>({
      doc: yDoc,
      docKey: PropertyRootKey.Meta,
      typeName: 'Property',
      migrations: [{
        name: 'set auto re-serve for Form 1',
        fn: draft => {
          const toAutoServe = recipients?.filter(r => !!reServe?.[r.id])?.map(r => r.id)||[];
          setAutoServeRecipeints(draft, FormCode.Form1, toAutoServe);
        }
      }]
    });

    setShow(false);
    onOk();
  };

  useEffect(() => {
    const coolingOffTriggers = [WorkflowConditionTriggerType.CoolingOffBegun, WorkflowConditionTriggerType.CoolingOffWaived, WorkflowConditionTriggerType.CoolingOffNotApplicable];
    const applicableRules = localEntity?.workflowRules?.filter(r => coolingOffTriggers.includes(r.trigger) && r.enabled && r.conditions?.length);
    setShowCoolingOffWarning(!!applicableRules?.length);
  }, [localEntity?.workflowRules]);

  return <Modal show={show} size='lg' onHide={handleCancel}>
    <Modal.Header closeButton>
      <div className='d-flex flex-column'>
        <Modal.Title>Replace Form 1</Modal.Title>
      </div>
    </Modal.Header>
    <Modal.Body>
      <div className={'px-3'}>
        {!!recipients?.length &&
          <div>
            Re-serve the new document to the following recipients automatically when completed:
            {recipients.map(r => <Form.Check
              id={`re-serve-${r.id}`}
              key={`re-serve-${r.id}`}
              label={r.name}
              className={'ms-3 mb-1'}
              checked={reServe?.[r.id]}
              onChange={()=>setReserve(e=> ({ ...e, [r.id]: !e?.[r.id] }))}
            >
            </Form.Check>)}
            {showCoolingOffWarning && <Alert variant='warning' className='mt-3'>
              Greatforms will not send cooling-off custom notification emails for a re-served Form 1.
            </Alert>}
          </div>
        }
      </div>

    </Modal.Body>
    <Modal.Footer>
      <Button variant='outline-secondary' onClick={handleCancel}>Cancel</Button>
      <AsyncButton onClick={handleReplace}>{startCase(type)}</AsyncButton>
    </Modal.Footer>
  </Modal>;
}
