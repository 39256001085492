import { useCallback, useContext, useEffect, useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { v4 } from 'uuid';
import { buildYDoc } from '@property-folders/components/form-gen-util/buildYDoc';
import { YManager } from '@property-folders/common/offline/yManager';
import { applyMigrationsV2_1 } from '@property-folders/common/yjs-schema';
import { FolderType, MaterialisedPropertyData, TransactionMetaData } from '@property-folders/contract';
import { PropertyRootKey } from '@property-folders/contract/yjs-schema/property';
import { canonicalisers, companyTradingAs } from '@property-folders/common/util/formatting';
import { BelongingEntityMeta, REDUCER_NAME as entityMetaKey } from '@property-folders/common/redux-reducers/entityMeta';
import { useSelector } from 'react-redux';
import { AuthApi } from '@property-folders/common/client-api/auth';
import { YManagerContext } from '@property-folders/components/context/YManagerContext';
import { UserPreferencesRootKey } from '@property-folders/contract/yjs-schema/user-preferences';

export function CreatePropertyFolderTemplatePage() {
  const navigate = useNavigate();
  const { formCode } = useParams();
  const { instance: yManagerInstance } = useContext(YManagerContext);
  const getCurrentUserPrefs = useCallback(() => yManagerInstance?.getUserPrefs()?.doc.getMap(UserPreferencesRootKey.Main).toJSON(), [yManagerInstance]);
  const memberEntities = useSelector((state: any) => state?.[entityMetaKey] as BelongingEntityMeta | undefined);
  const { data: sessionInfo } = AuthApi.useGetAgentSessionInfo();

  const { ydoc, localProvider, docName: propertyId, propertyFormId } = useMemo(() => {
    const newUUID = v4();
    const propertyFormId = v4();
    const result = buildYDoc(newUUID, true);
    YManager.instance().markUserViewing(newUUID, true);
    return { ...result, propertyFormId };
  }, []);

  useEffect(() => {
    const entityId = sessionInfo?.entities[0].entityId;
    if (!sessionInfo || !entityId || !formCode) {
      return;
    }

    localProvider.whenSynced.then(() => {
      const localEntity = memberEntities && entityId ? memberEntities?.[entityId] : undefined;

      if (!localEntity || !getCurrentUserPrefs || !memberEntities) {
        return;
      }

      applyMigrationsV2_1<TransactionMetaData>({
        doc: ydoc,
        docKey: PropertyRootKey.Meta.toString(),
        typeName: 'Property',
        migrations: [
          {
            name: 'initialise metadata',
            fn: state => {
              const now = new Date();
              state.folderType = FolderType.PropertyTemplate;
              state.createdUtc = now.toISOString();

              state.entity = {
                name: localEntity.name,
                id: localEntity.entityId
              };

              state.creator = {
                name: sessionInfo.name,
                id: sessionInfo.agentId,
                timestamp: now.getTime()
              };

              if (
                !state.formStates
                || !state.formStates[formCode]
                || !state.formStates[formCode].instances?.find(i => i.id === propertyFormId)
                || !state.formStates[formCode].instances?.find(i => i.id === propertyFormId)?.signing
              ) {
                state.formStates = {};
                state.formStates[formCode] = {
                  instances: [{
                    id: propertyFormId,
                    formCode: formCode as any,
                    modified: Date.now(),
                    created: Date.now()
                  }]
                };

                state.createdUtc = now.toISOString();
              }
            }
          },
          {
            name: 'populate data',
            docKey: PropertyRootKey.Data.toString(),
            fn: (state: any) => {
              const currentSalesperson = localEntity?.salespeople?.find(s => s.id === sessionInfo.agentId);
              const agent = {
                id: sessionInfo.agentUuid,
                phone: (canonicalisers.phone(currentSalesperson?.phone || '').canonical as string) || '',
                email: (canonicalisers.email(sessionInfo.email || '').canonical as string) || '',
                name: sessionInfo.name,
                linkedSalespersonId: sessionInfo.agentId
              };

              (state as MaterialisedPropertyData).id = propertyId;
              (state as MaterialisedPropertyData).headline = 'Template';
              (state as MaterialisedPropertyData).agent = [{
                company: companyTradingAs(localEntity.name, localEntity.tradeName),
                address: localEntity.address1,
                phone: localEntity.phone,
                email: localEntity.email,
                abn: (canonicalisers.abnacn(localEntity.abn ?? '').canonical as string) ?? '',
                rla: localEntity.rla ?? '',
                linkedEntityId: entityId,
                profileName: localEntity.profileName,
                salesp: [agent],
                id: v4()
              }];

              const addressId = 'd77179e1-fe2b-11ef-af5a-02c4bc49087a';
              const titleId = 'e8c53713-fe2b-11ef-af5a-02c4bc49087a';

              (state as MaterialisedPropertyData).saleAddrs = [{
                id: addressId,
                streetAddr: '1 Example Way',
                subStateAndPost: 'Adelaide SA 5000',
                streetAddr_parts: {
                  Postcode: '5000',
                  Latitude: '-34.92648428',
                  LevelNumber: '',
                  Longitude: '138.60737702',
                  LotNumber: '',
                  State: 'SA',
                  StreetName: 'Example Way',
                  StreetNumber: '1',
                  Suburb: 'Adelaide',
                  UnitNumber: '',
                  UnitType: ''
                },
                gnaf: 'GASA_422262803',
                hundred: 'Adelaide',
                irrigationArea: '',
                lga: 'City Of Adelaide',
                suburb: 'Adelaide',
                fromLssa: true
              }];
              (state as MaterialisedPropertyData).saleTitles = [{
                id: titleId,
                isWhole: true,
                isNone: false,
                title: 'CT 1234/123',
                linkedAddresses: [
                  addressId
                ],
                subTitles: [
                  {
                    id: '534dd6a9-b92f-4cdb-9974-998f5a6e317c',
                    portionType: 1,
                    plan: 'Deposited Plan',
                    planid: '1',
                    lot: 'Allotment',
                    lotid: '1',
                    hundreds: [
                      {
                        outOfHundreds: false,
                        irrigationArea: false,
                        name: 'Adelaide'
                      }
                    ],
                    area: 'Adelaide'
                  }
                ],
                fromLssa: true,
                valuations: [
                  '01234567890'
                ],
                descriptionOfLand: [
                  {
                    hundreds: [
                      {
                        outOfHundreds: false,
                        irrigationArea: false,
                        name: 'Adelaide'
                      }
                    ],
                    area: 'Adelaide',
                    plan: {
                      parcelType: 'ALLOTMENT',
                      parcelNumber: '1',
                      planType: 'DEPOSITED PLAN',
                      planNumber: '1'
                    }
                  }
                ],
                legalLandDescription: '*Allotment 1* on *Deposited Plan 1* in the Area named *Adelaide* in the Hundred of *Adelaide*'
              }];
            }
          }
        ]
      });
      navigate(`/templates/${propertyId}/document/${propertyFormId}`);
    });
  }, [sessionInfo]);

  return <></>;
}
