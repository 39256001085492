import { LazyInfiniteTableList } from '@property-folders/components/dragged-components/LazyInfiniteTableList';
import { useLightweightTransaction } from '@property-folders/components/hooks/useTransactionField';
import { EntityBrandFormConfig, EntitySettingsEntity, ImageScaleType, MarketingTemplate, MarketingTemplateItemState, MarketingTemplateSectionType } from '@property-folders/contract/yjs-schema/entity-settings';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { HumanTimestampText } from '@property-folders/components/dragged-components/HumanTimestamp';
import { Button, Container, InputGroup, Row } from 'react-bootstrap';
import { v4 } from 'uuid';
import { useYdocBinder } from '@property-folders/components/hooks/useYdocBinder';
import { WrField } from '@property-folders/components/dragged-components/form/CommonComponentWrappers';
import { CollectionEditor } from '@property-folders/components/dragged-components/form/CollectionEditor';
import { SearchBar } from '@property-folders/components/dragged-components/SearchBar';
import { Icon } from '@property-folders/components/dragged-components/Icon';
import './MarketingTemplates.scss';
import { useNavigate, useParams } from 'react-router-dom';
import { EntityLargeScreenPreviewClickHandler, EntitySmallScreenPreviewButton } from '~/pages/settings/PreviewHandlers';
import { WizardStepPage } from '@property-folders/components/dragged-components/Wizard/WizardStepPage';
import { BreadCrumbs } from '@property-folders/components/dragged-components/BreadCrumbs';
import { SetupPdfLoadStateContext } from '@property-folders/components/context/pdfLoadStateContext';
import { PDFViewer } from '@property-folders/components/dragged-components/PDFViewer/PDFViewer';
import { Pdf } from '@property-folders/common/util/pdf-worker/Pdf';
import { ContentType } from '@property-folders/contract';
import { cloneDeep } from 'lodash';
import { MarketingSection } from '@property-folders/components/dragged-components/MarketingTemplate/MarketingSection';
import { marketingTemplateSection } from '@property-folders/common/util/pdfgen/sections/marketingTemplateSection';
import { SettingsImageUpload } from '~/pages/settings/SettingsImageUpload';
import { EntityFileType, FileStorage } from '@property-folders/common/offline/fileStorage';
import { EntitySettingsContext } from '~/pages/settings/EntitySettingsContext';
import { blobTob64DataUri } from '@property-folders/common/util/dataExtract';
import { reaformsLogo, transparentPixel, reaformsImageDims as reaformsImageDims } from '@property-folders/common/util/pdfgen/logos';
import { fieldsSpacing, insertIf, itemSection } from '@property-folders/common/util/pdfgen';
import * as PdfGen from '@property-folders/common/util/pdfgen';
import { mmToPoints } from '@property-folders/common/util/pdfgen/measurements';
import { useEntitySettingsParams } from '~/pages/settings/config';
import Select from 'react-select';
import { Avatar } from '@property-folders/components/dragged-components/Avatar';
import { useEntities } from '@property-folders/components/hooks/useEntity';
import '../tabs/components/UserTeamSelector.scss';

export function MarketingTemplatesEdit({ onPreviewKey }: { onPreviewKey: (key: string) => void }) {
  const { entityUuid } = useContext(EntitySettingsContext);
  const { mobile, largeScreen } = useEntitySettingsParams();
  const [filter, setFilter] = useState('');
  const navigate = useNavigate();
  const { settingId } = useParams();
  const { value: templates } = useLightweightTransaction<MarketingTemplate[]>({ myPath: 'marketingTemplates' });
  const { value: template } = useLightweightTransaction<MarketingTemplate>({ myPath: `marketingTemplates.[${settingId}]` });
  const { updateDraft } = useYdocBinder<EntitySettingsEntity>({ path: '' });

  const items = useMemo(() => {
    const filterValue = filter.toLowerCase();
    return (templates || [])
      .filter(item => !filterValue || item.name.toLowerCase().indexOf(filterValue) >= 0)
      .sort((a, b) => a.name.localeCompare(b.name));
  }, [templates, filter]);

  const createTemplate = () => {
    if (!updateDraft) return;

    const id = v4();
    updateDraft(draft => {
      if (!Array.isArray(draft.marketingTemplates)) {
        draft.marketingTemplates = [];
      }
      const next = draft.marketingTemplates.length + 1;
      draft.marketingTemplates.push({
        id,
        entityUuid,
        name: `Template ${next}`,
        modified: Date.now(),
        headerImageScale: ImageScaleType.fit,
        sections: []
      });
    });
    navigate(id);
  };

  const deleteTemplate = (id: string) => {
    if (!updateDraft) return;

    updateDraft(draft => {
      if (!draft.marketingTemplates) return;
      const idx = draft?.marketingTemplates?.findIndex(item => item.id === id)??-1;
      if (idx === -1) return;

      draft.marketingTemplates.splice(idx, 1);
    });
  };

  const duplicateTemplate = (id: string) => {
    if (!updateDraft) return;

    updateDraft(draft => {
      if (!draft.marketingTemplates) return;
      const template = draft?.marketingTemplates?.find(item => item.id === id);
      if (!template) return;

      const newId = v4();
      draft.marketingTemplates.push({
        ...cloneDeep(template),
        id: newId,
        entityUuid,
        name: `${template.name} (copy)`,
        modified: Date.now()
      });
      navigate(newId);
    });
  };

  const breadcrumbs = <div className='fs-5 d-flex flex-row align-items-center justify-content-between w-100 fw-normal'>
    <BreadCrumbs segments={[ ...insertIf(largeScreen, { label: 'Marketing Templates', href: '../marketing' }), { label: template?.name||'', href: '' }, {}]}/>
  </div>;

  return <Container id='settingsMarketingTemplates' fluid={true} className='h-100 position-relative d-flex flex-column flex-start g-0 overflow-auto WizardPanel'>
    {settingId ? <div className={'mt-0'}>
      <EntityLargeScreenPreviewClickHandler onPreviewKey={onPreviewKey} previewKey={MarketingPreviewKey.Template}>
        <WizardStepPage
          name='marketingTemplate'
          label={breadcrumbs}
          headerContent={<EntitySmallScreenPreviewButton onPreviewKey={onPreviewKey} previewKey={MarketingPreviewKey.Template}/>}
        >
          <EditTemplate id={settingId}/>
        </WizardStepPage>
      </EntityLargeScreenPreviewClickHandler>
    </div>
      :
      <>
        <div className="d-flex flex-wrap flex-row px-3 pt-3 mb-3 gap-2">
          <div className="d-flex flex-row justify-content-end">
            <Button
              variant="primary"
              size="lg"
              title={'Add Template'}
              onClick={createTemplate}
              style={{ paddingTop: 'calc(0.5rem - 0.5px)', paddingBottom: 'calc(0.5rem - 1px)' }}
            >
              <Icon name="add" variant="outlined" icoClass="fs-4 mb-1 me-2"></Icon>
              {mobile ? '' : 'Add Template'}
            </Button>
          </div>
          <div style={{ flex: '1 1 150px' }}>
            <SearchBar
              placeholder="Search for a template"
              onSearch={term => setFilter(term)}
              className="w-100"
              constrainWidth={false}
            />
          </div>
        </div>

        <LazyInfiniteTableList
          storageKey='MarketingTemplates'
          hasNextPage={false}
          items={items}
          columns={[{
            label: 'Name',
            rowMajor: row => row.name
          }, {
            label: 'Last Modified',
            rowMajor: row => (<HumanTimestampText timestampMs={row.modified||0}/>)
          }]}
          rowClick={row => {
            navigate(row.id);
          }}
          rowActions={[
            {
              label: 'Duplicate',
              action: row => duplicateTemplate(row.id)
            },
            {
              label: 'Delete',
              action: row => deleteTemplate(row.id)
            }
          ]}
          containerClass="w-100"
        />
      </>
    }
  </Container>;
}

function EditTemplate({ id }: { id: string }) {
  const { value: template, fullPath } = useLightweightTransaction<MarketingTemplate>({ parentPath: `marketingTemplates.[${id}]` });
  const { updateDraft: updateTemplate } = useYdocBinder<MarketingTemplate>({ path: `marketingTemplates.[${id}]` });

  const { entityPhpInfo } = useContext(EntitySettingsContext);
  const localEntities = useEntities();
  const localEntity = entityPhpInfo?.entityId ? localEntities?.[entityPhpInfo.entityId] : undefined;
  const salespeople = localEntity?.salespeople?.filter(s => s.isSalesperson);

  const setImage = (fileId: string, contentType: string) => {
    updateTemplate?.(draft => {
      draft.headerImage = {
        id: fileId,
        contentType
      };
    });
  };

  const removeImage = () => {
    updateTemplate?.(draft => {
      delete draft.headerImage;
    });
  };

  return <div className="w-100 h-100">
    <div className={'d-flex flex-wrap col-gap-5 row-gap-3'}>

      <div className={'d-flex flex-column flex-grow-1 flex-wrap gap-4'}>
        <div className={'flex-grow-1'}><WrField.Control name={'name'} parentPath={fullPath} myPath={'name'} label={'Template name'}/></div>
        <div className={'d-flex flex-grow-1 flex-wrap align-items-center mt-1'}>
          <InputGroup className="d-flex">
            <WrField.Control name={'headerText'} containerClassName={'flex-1-1-min'} label="Title Text" parentPath={fullPath} myPath="headerText" minWidth={'150px'}/>
            <InputGroup.Text>
              <WrField.ColourPicker text={<Icon name="title" pack="material-symbols"/>} name={'Header text colour'} parentPath={fullPath} myPath="textColour" defaultValue={'#000000'}/>
            </InputGroup.Text>
            <InputGroup.Text>
              <WrField.ColourPicker text={<Icon name="colors" pack="material-symbols"/>} name={'Header background colour'} parentPath={fullPath} myPath="backgroundColour" defaultValue={'#ffffff'}/>
            </InputGroup.Text>
          </InputGroup>
        </div>
      </div>

      <div className={'d-flex flex-column flex-wrap'} style={{ gap: '0.5rem 1rem' }}>
        <div className={'d-flex gap-4 align-items-end'}>
          <div className={'d-flex flex-column'} style={{ marginTop: '-7px' }}>
            <SettingsImageUpload
              label={'Header Image'}
              afterLabel={<Icon name={'info'} variant={'outlined'} icoClass={'ms-1'} style={{ fontSize: '14px', marginTop: '-3px', cursor: 'default', color: 'rgb(26, 110, 246)' }} title={'Images with an aspect ratio of 13:3 (i.e. 650px x 150px) will work best'}/>}
              labelClass={'fs-6 fw-normal'}
              showPreview={false}
              onUpload={setImage}
              onRemove={removeImage}
              fileType={EntityFileType.MarketingTemplate}
              parentId={id}
              hasFile={!!template?.headerImage}
            />
          </div>
          <div>
            <WrField.Select
              name="headerImageScale"
              options={[{ name: ImageScaleType.none, label: 'None' }, { name: ImageScaleType.fit, label: 'Fit' }, { name: ImageScaleType.stretch, label: 'Stretch' }]}
              parentPath={fullPath}
              myPath="headerImageScale"
              canClear={false}
              tight={true}
              style={{ width: '100px' }}
            />
          </div>
        </div>

        <div className={'d-flex flex-column mt-1'}>
          <div className={'me-2'} style={{ marginTop: '-6px' }}>Section Headers</div>
          <div className={'d-flex'}>
            <InputGroup style={{ height: '51px' }}>
              <InputGroup.Text>
                <WrField.ColourPicker text={<Icon name="title" pack="material-symbols"/>} name={'Section header text colour'} parentPath={fullPath} myPath="sectionTextColour" defaultValue={'#404040'}/>
              </InputGroup.Text>
              <InputGroup.Text>
                <WrField.ColourPicker text={<Icon name="colors" pack="material-symbols"/>} name={'Section header background colour'} parentPath={fullPath} myPath="sectionBackgroundColour" defaultValue={'#da6027'}/>
              </InputGroup.Text>
              <InputGroup.Text>
                <WrField.ColourPicker text={<Icon name="horizontal_rule" pack="material-symbols"/>} name={'Section header line colour'} parentPath={fullPath} myPath="sectionLineColour" defaultValue={'#404040'}/>
              </InputGroup.Text>
            </InputGroup>
          </div>
        </div>
      </div>
    </div>

    <div className={'d-flex flex-wrap mt-2'}>
      <div className={'d-flex flex-column flex-grow-1'}>
        <div>Salespeople</div>
        <Select
          isMulti
          isClearable
          placeholder={'All'}
          options={salespeople}
          getOptionValue={item => item.id?.toString()}
          value={salespeople?.filter(s => template?.users?.includes(s.id))}
          className="user-team-selector"
          formatOptionLabel={data => {
            return <Avatar
              name={data.name}
              entityName={localEntity?.name || ''}
              agentId={data.id}
            />;
          }}
          onChange={newValue => {
            updateTemplate?.(draft => {
              draft.users = newValue?.map(s => s.id);
            });
          }}
          styles={{
            menu: base => ({
              ...base,
              zIndex: 10
            }),
            control: base => ({
              ...base,
              borderRadius: 0,
              backgroundColor: 'rgb(244, 245, 250)'
            })
          }}
          classNames={{
            option: () => 'option',
            multiValueLabel: () => 'label'
          }}
        />
      </div>
    </div>

    <Row className={'mt-4'}>
      <CollectionEditor
        parentPath={fullPath}
        myPath={'sections'}
        autoAddFirst={true}
        autoAddNew={false}
        allowAdd={true}
        overrideAdd={() => ({
          type: MarketingTemplateSectionType.individual,
          defaultState: MarketingTemplateItemState.unchecked
        })}
        allowDragAndDrop={true}
        dragAndDropConfig={{
          handleClass: 'moveDragHandle'
        }}
        itemNoun={'Section'}
        childItemRenderer={MarketingSection}
        hasHeaderRow={false}
        childProps={{
          editMode: true,
          template
        }}
      />
    </Row>
  </div>;
}

export function MarketingPreview({ previewKey, onPreviewClose }: { previewKey: string, onPreviewClose?: () => void }) {
  switch (previewKey) {
    case MarketingPreviewKey.Template:
    default:
      return <MarketingTemplatePreview onPreviewClose={onPreviewClose}/>;
  }
}

export enum MarketingPreviewKey {
  Template = 'template'
}

function MarketingTemplatePreview({ onPreviewClose }: { onPreviewClose?: () => void }) {
  const { settingId } = useParams();
  const { entityPhpInfo } = useContext(EntitySettingsContext);
  const { value: template } = useLightweightTransaction<MarketingTemplate>({ parentPath: `marketingTemplates.[${settingId}]` });
  const [previewUrl, setPreviewUrl] = useState<string>();
  const { value: formBrand } = useLightweightTransaction<EntityBrandFormConfig>({ parentPath: 'brand.form' });

  useEffect(() => {
    (async () => {
      if (!template || !formBrand) return;
      const pdf = new Pdf();
      pdf.prepare(formBrand, {}, true);
      const data = await pdf.generateBlobAsync(await marketingTemplatePdf(template, entityPhpInfo?.logo ? await blobTob64DataUri(entityPhpInfo?.logo) : ''));
      setPreviewUrl(URL.createObjectURL(new Blob([data], { type: ContentType.Pdf })));
    })();

    return () => {
    };
  }, [template, entityPhpInfo]);

  return <div className="w-100 p-3">
    {previewUrl && <div style={{ position: 'absolute', top: '0', left: '0', width: '100%', height: '100%' }}>
      <SetupPdfLoadStateContext>
        <PDFViewer
          bookmark=''
          pdfUrl={previewUrl}
          filename={'preview.pdf'}
          renderTextLayer={false}
          standalonePreview={true}
          useLoadSuccessForCompletion={true}
          activeViews={2}
          toolbarRight={
            <Button variant="secondary" title='Close' onClick={()=>onPreviewClose?.()}>
              <Icon name="close" />
            </Button>
          }
        />
      </SetupPdfLoadStateContext>
    </div>}
  </div>;

}

async function marketingTemplatePdf(marketingTemplate: MarketingTemplate, agencyLogo: string) {
  let headerImageUrl = '';
  if (marketingTemplate.headerImage?.id) {
    const headerImageFile = await FileStorage.read(marketingTemplate.headerImage?.id);
    headerImageUrl = await blobTob64DataUri(headerImageFile?.data);
  }

  const content = itemSection({
    itemNo: 7,
    itemTitleParam: 'Marketing and Other Costs'.toUpperCase(),
    bookmark: 'marketing-template',
    stackContent: [
      { text: 'In accordance with General Conditions 6, 7 and 11, the following Marketing and Other Costs are authorised and payable by the Vendor, regardless of whether the Property is sold by the Agent or not.', margin: [0, 0, 0, fieldsSpacing] },
      ...marketingTemplateSection(marketingTemplate, false)
    ]
  });

  const agencyLogoData = agencyLogo || transparentPixel;
  return {
    pageSize: 'A4',
    pageOrientation: 'portrait',
    pageMargins: [mmToPoints(10), mmToPoints(26), mmToPoints(15), mmToPoints(18)],
    images: {
      marketingHeaderImage: headerImageUrl || transparentPixel,
      agencyLogo: agencyLogoData
    },
    content: [
      {
        stack: [content],
        margin: [14,0,0,0]
      }
    ],
    footer: (currentPage: number, pageCount: number) => PdfGen.standardFooter(currentPage, 'Marketing Template', false, pageCount),
    header: (currentPage: number) => PdfGen.standardHeader(currentPage, title, false, reaformsLogo, brand.agencyContact, { ourProductLogoSvgDims: reaformsImageDims, agencyLogoData })
  };
}
