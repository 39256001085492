import { LazyInfiniteTableList } from '@property-folders/components/dragged-components/LazyInfiniteTableList';
import { useLightweightTransaction } from '@property-folders/components/hooks/useTransactionField';
import { EntitySettingsEntity, operatorOptions, WorkflowActionPartyType, WorkflowCondition, WorkflowConditionJoinType, WorkflowConditionType, WorkflowRule } from '@property-folders/contract/yjs-schema/entity-settings';
import React, { useContext, useMemo, useRef, useState } from 'react';
import { Badge, Button, Container } from 'react-bootstrap';
import { v4 } from 'uuid';
import { useYdocBinder } from '@property-folders/components/hooks/useYdocBinder';
import { SearchBar } from '@property-folders/components/dragged-components/SearchBar';
import { Icon } from '@property-folders/components/dragged-components/Icon';
import { useNavigate, useParams } from 'react-router-dom';
import { EntityLargeScreenPreviewClickHandler, EntitySmallScreenPreviewButton } from '~/pages/settings/PreviewHandlers';
import { WizardStepPage } from '@property-folders/components/dragged-components/Wizard/WizardStepPage';
import { BreadCrumbs } from '@property-folders/components/dragged-components/BreadCrumbs';
import { cloneDeep, orderBy } from 'lodash';
import { EntitySettingsContext } from '~/pages/settings/EntitySettingsContext';
import { insertIf } from '@property-folders/common/util/pdfgen';
import { useEntitySettingsParams } from '~/pages/settings/config';
import { StandardConditions, WorkflowTriggerTemplates } from '../../../../../common/workflow-rules/WorkflowTriggerTemplates';
import './EmailTemplates.scss';
import { emailRecipientOptions } from '@property-folders/contract';
import { HumanTimestampText } from '@property-folders/components/dragged-components/HumanTimestamp';
import { EditEmailTemplate, EmailTemplatePreviewKey } from '~/pages/settings/tabs/EmailTemplates';
import { useGetAdminUsersTeams } from '~/pages/settings/tabs/components/useGetAdminUsersTeams';

export function EmailTemplatesList({ onPreviewKey, onPreviewData }: { onPreviewKey: (key: string) => void, onPreviewData: (data?:any)=>void }) {
  const { mobile, largeScreen } = useEntitySettingsParams();
  const [filter, setFilter] = useState('');
  const navigate = useNavigate();
  const { settingId } = useParams();
  const { value: templates } = useLightweightTransaction<WorkflowRule[]>({ myPath: 'workflowRules' });

  const { value: template } = useLightweightTransaction<WorkflowRule>({ myPath: `workflowRules.[${settingId}]` });
  const { updateDraft } = useYdocBinder<EntitySettingsEntity>({ path: '' });

  const { entityPhpInfo } = useContext(EntitySettingsContext);
  const { users, teams }  = useGetAdminUsersTeams(entityPhpInfo?.entityId);

  const items = useMemo(() => {
    const filterValue = filter.toLowerCase();
    return (templates || [])
      .filter(item => !filterValue || item.name.toLowerCase().indexOf(filterValue) >= 0)
      .sort((a, b) => a.name.localeCompare(b.name));
  }, [templates, filter]);

  const createEmailTemplate = () => {
    if (!updateDraft) return;

    const id = v4();
    updateDraft(draft => {
      if (!Array.isArray(draft.workflowRules)) {
        draft.workflowRules = [];
      }
      const next = draft.workflowRules.length + 1;
      draft.workflowRules.push({
        id,
        enabled: false,
        name: `Email Template ${next}`,
        modified: Date.now()
      });
    });
    navigate(id);
  };

  const deleteTemplate = (id: string) => {
    if (!updateDraft) return;

    updateDraft(draft => {
      if (!draft.workflowRules) return;
      const idx = draft?.workflowRules?.findIndex(item => item.id === id)??-1;
      if (idx === -1) return;

      draft.workflowRules.splice(idx, 1);
    });
  };

  const duplicateTemplate = (id: string) => {
    if (!updateDraft) return;

    updateDraft(draft => {
      if (!draft.workflowRules) return;
      const template = draft?.workflowRules?.find(item => item.id === id);
      if (!template) return;

      const newId = v4();
      draft.workflowRules.push({
        ...cloneDeep(template),
        id: newId,
        name: `${template.name} (copy)`,
        modified: Date.now(),
        enabled: false
      });
      navigate(newId);
    });
  };

  const generateConditionDescription = (rootCondition?: WorkflowCondition) => {
    const generateConditionDescriptionInner = (condition: WorkflowCondition): string => {
      const templateCondition = condition && 'name' in condition ? StandardConditions[condition.name] : undefined;
      if (!condition) {return '';}
      switch (condition.type) {
        case WorkflowConditionType.And:
          return (condition as WorkflowConditionJoinType).conditions?.map(c => generateConditionDescriptionInner(c))?.join(' AND ');
        case WorkflowConditionType.Or:
          return (condition as WorkflowConditionJoinType).conditions?.map(c => generateConditionDescriptionInner(c))?.join(' OR ');
        case WorkflowConditionType.Field:
        case WorkflowConditionType.MappedField:
        case WorkflowConditionType.Document:
          return `${templateCondition?.label} ${operatorOptions[condition.operator]} ${condition.value?.map((v:any) => templateCondition?.options === 'salespeople'
            ? users?.find(sp => sp.id === v)?.name
            : templateCondition?.options?.[v])?.join(', ')}`;
      }
    };
    return rootCondition ? generateConditionDescriptionInner(rootCondition) : '';
  };

  const generateRecipientBadge = (recipient: WorkflowActionPartyType) => {
    switch (recipient.type) {
      case 'role':
        return <Badge key={recipient.id} className={'me-1 recipient-internal'}>{emailRecipientOptions[recipient.id]}</Badge>;
      case 'team':
        return <Badge key={recipient.id} className={'me-1 recipient-external'}>{teams?.find(t => t.id === recipient.id)?.name}</Badge>;
      case 'user':
        return <Badge key={recipient.id} className={'me-1 recipient-external'}>{users?.find(t => t.id === recipient.id)?.name}</Badge>;
      default:
        return <Badge key={recipient.id} className={'me-1 recipient-external'}>{recipient.id}</Badge>;
    }
  };

  const breadcrumbs = <div className='fs-5 d-flex flex-row align-items-center justify-content-between w-100 fw-normal'>
    <BreadCrumbs segments={[ ...insertIf(largeScreen, { label: 'Custom Notification Templates', href: '../notification-templates' }), { label: template?.name||'', href: '' }, {}]}/>
  </div>;

  const headerRef = useRef(null);
  return <Container id='settingsEmailTemplates' fluid={true} className='h-100 position-relative d-flex flex-column flex-start g-0 overflow-auto WizardPanel'>
    {settingId ? <div className={'mt-0'}>
      <EntityLargeScreenPreviewClickHandler onPreviewKey={onPreviewKey} previewKey={EmailTemplatePreviewKey.Email}>
        <WizardStepPage
          name='emailTemplates'
          label={breadcrumbs}
          embedded={true}
          headerContent={<><EntitySmallScreenPreviewButton onPreviewKey={onPreviewKey} previewKey={EmailTemplatePreviewKey.Email}/><div ref={headerRef}></div></>}
        >
          <EditEmailTemplate id={settingId} headerRef={headerRef} onPreviewData={onPreviewData} />
        </WizardStepPage>
      </EntityLargeScreenPreviewClickHandler>
    </div>
      :
      <>
        <div className="d-flex flex-wrap flex-row px-3 pt-3 mb-3 gap-2">
          <div className="d-flex flex-row justify-content-end">
            <Button
              variant="primary"
              size="lg"
              title={'Add Template'}
              onClick={createEmailTemplate}
              style={{ paddingTop: 'calc(0.5rem - 0.5px)', paddingBottom: 'calc(0.5rem - 1px)' }}
            >
              <Icon name="add" variant="outlined" icoClass="fs-4 mb-1 me-2"></Icon>
              {mobile ? '' : 'Add Template'}
            </Button>
          </div>
          <div style={{ flex: '1 1 150px' }}>
            <SearchBar
              placeholder="Search for template"
              onSearch={term => setFilter(term)}
              className="w-100"
              constrainWidth={false}
            />
          </div>
        </div>

        <LazyInfiniteTableList
          storageKey='EmailTemplates'
          hasNextPage={false}
          rowHeight={'unset'}
          items={items?.map(i => ({ ...i, rowClass: !i.enabled ? 'disabled-rule' : '' }))}
          columns={[
            {
              label: 'Name',
              rowMajor: row => row.name,
              sort: {
                key: 'name'
              }
            }, {
              label: 'When',
              rowMajor: row => row.trigger ? WorkflowTriggerTemplates[row.trigger]?.label : ''
            }, {
              label: 'Condition',
              rowMajor: row => generateConditionDescription(row?.conditions?.[0])
            }, {
              label: 'Recipients',
              rowMajor: row => orderBy(row?.actions?.[0]?.to, r => Number.isInteger(r.id) ? 1 : r?.type === 'email')?.map(r => generateRecipientBadge(r) ) || ''
            }, {
              label: 'Modified',
              rowMajor: row => (<HumanTimestampText timestampMs={row.modified||0}/>),
              sort: {
                key: 'modified',
                defaultSort: 'desc'
              }
            }
          ]}
          rowClick={row => {
            navigate(row.id);
          }}
          rowActions={[
            {
              label: 'Duplicate',
              action: row => duplicateTemplate(row.id)
            },
            {
              label: 'Delete',
              action: row => deleteTemplate(row.id)
            }
          ]}
          containerClass="w-100"
        />
      </>
    }
  </Container>;
}

