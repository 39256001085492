import { FormContextSigningOverride } from '@property-folders/components/form-gen-util/yjsStore';
import { SigningConfiguration } from '@property-folders/components/dragged-components/signing/SigningConfiguration';
import { PropertyFormYjsDal } from '@property-folders/common/yjs-schema/property/form';
import {
  PropertyRootKey,
  TransactionMetaData
} from '@property-folders/contract/yjs-schema/property';
import { useReactRouterData } from '@property-folders/components/hooks/useReactRouterHooks';
import { useParams } from 'react-router-dom';
import { useImmerYjs } from '@property-folders/components/hooks/useImmerYjs';
import React, { useEffect, useState } from 'react';
import * as Y from 'yjs';
import { IndexeddbPersistence } from 'y-indexeddb';
import { Awareness } from 'y-protocols/awareness';
import { useEntityLogoForCurrentPropertyFolder } from '@property-folders/components/hooks/useEntityLogo';
import { ShortId } from '@property-folders/common/util/url';

import { SigningFieldsConfiguration } from '@property-folders/components/dragged-components/signing/fieldConfiguration/SigningFieldsConfiguration';

interface RouterData {
  transId: string,
  ydoc: Y.Doc,
  localProvider: IndexeddbPersistence,
  ydocStats: Y.Doc,
  localProviderStats: IndexeddbPersistence,
  awareness: Awareness,
}

export function ConfigureSubscriptionFolderSigning({
  prepareForSigningHandler,
  prepareForCustomisingHandler
}: {
  prepareForSigningHandler: (customised?: boolean) => Promise<void>,
  prepareForCustomisingHandler: () => Promise<void>
}) {
  const { transId, ydoc } = useReactRouterData<RouterData>();
  const { documentIdRaw } = useParams();
  const { bindState: metaBindState } = useImmerYjs<TransactionMetaData>(ydoc, PropertyRootKey.Meta);
  const { data: meta } = metaBindState<TransactionMetaData>(m => m);
  const [formCode, setFormCode] = useState('');
  const formId = ShortId.toUuid(documentIdRaw);
  const formInstance = formCode
    ? PropertyFormYjsDal.getFormInstanceFromState(formCode, formId, meta || {})
    : PropertyFormYjsDal.searchFormInstanceByIdFromState(formId, meta || {});
  const entityLogoUri = useEntityLogoForCurrentPropertyFolder();

  useEffect(() => {
    if (!formInstance?.formCode) return;

    setFormCode(formInstance.formCode);
  }, [formInstance?.formCode]);

  if (!formCode) {
    return <></>;
  }

  return <FormContextSigningOverride>
    {formInstance?.signing?.customiseScreen === 'fields'
      ? <SigningFieldsConfiguration
        propertyId={transId}
        formCode={formCode}
        formId={formId}
        entityLogoLoadedUri={entityLogoUri}
        ydoc={ydoc}
        yMetaRootKey={PropertyRootKey.Meta} // Subscription forms do not yet support sublineages
        yRootKey={PropertyRootKey.Data}
        prepareForSigning={() => Promise.resolve()}
        prepareForCustomising={prepareForCustomisingHandler}
      />
      : <SigningConfiguration
        propertyId={transId}
        formCode={formCode}
        formId={formId}
        ydoc={ydoc}
        yMetaRootKey={PropertyRootKey.Meta} // Subscription forms do not yet support sublineages
        yRootKey={PropertyRootKey.Data}
        entityLogoLoadedUri={entityLogoUri}
        prepareForSigning={prepareForSigningHandler}
        prepareForCustomising={prepareForCustomisingHandler}
        showColours={false}
      />}
  </FormContextSigningOverride>;
}
